import React, {useState, useEffect} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import CircularProgress from "@material-ui/core/CircularProgress";
import api, {auth, url} from "../../services/api";
import DataTable from "react-data-table-component";
import SortIcon from "@material-ui/icons/ArrowDownward";
import Checkbox from "@material-ui/core/Checkbox";
import Button from "@material-ui/core/Button";
import {Link} from "react-router-dom";
import moment from "moment";



const useStyles = makeStyles((theme) => ({
    paper: {
        marginTop: theme.spacing(8),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    avatar: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.primary.main,
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(1),
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    },
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: '#fff',
    },
}));




export default function DocumentosListarPagos() {
    const classes = useStyles();

    const [documentos, setDocumentos] = useState([]);
    const [loading, setLoading] = React.useState(false);
    const [perPage, setPerPage] = useState(10);
    const [currentPage, setCurrentPage] = useState(1);

    const get=async (page=currentPage, size=perPage)=>{
        setLoading(true)
        const res = await api.get(`documentos?page=${page}&per_page=${size}`);
        if(res.data.code===0){
            setDocumentos(res.data.data)
        }
        setLoading(false)
    }

    useEffect(()=>{
        get()

    }, [])

    const columns = [
        {
            name: "Cliente",
            selector: "costumer.OrganizationName",
            sortable: true
        },
        {
            name: "Data de encomenda",
            selector: "created_at",
            sortable: true,
            cell:row=><p>{moment(row.created_at).format("DD-MM-yyyy")}</p>
        },
        {
            name:"",
            cell:row=><Link to={"/documentos/ver/" + row.id}><Button variant={"contained"} color={"primary"}>Detalhes</Button></Link>
        },
        {
            name:"",
            cell:row=><a target={"_blank"} rel="noopener noreferrer" href={url+"pdf/docVenda/"+ row.id+"/"+auth()}><Button variant="contained" color="primary" fullWidth={true}>Obter documento</Button></a>
        }
    ];


    const handlePageChange = page => {
        console.log(page)
        get(page);
        setCurrentPage(page);
    };

    const handlePerRowsChange = async (newPerPage, page) => {
        get(page, newPerPage);
        setPerPage(newPerPage);
    };



    return (
        <Container component="main" maxWidth="md">
            <div className={classes.paper}>
                <DataTable
                    title="Encomendas"
                    columns={columns}
                    data={documentos.data}
                    sortIcon={<SortIcon />}
                    pagination
                    //selectableRows
                    selectableRowsComponent={Checkbox}
                    noDataComponent={"Não foram encontrados documentos pagos."}
                    progressPending={loading}
                    progressComponent={<CircularProgress />}
                    paginationComponentOptions={{
                        rowsPerPageText: 'Linhas por pagina:',
                        rangeSeparatorText: 'de',
                        noRowsPerPage: false,
                        selectAllRowsItem: false,
                        selectAllRowsItemText: 'Todas'
                    }}


                    onChangeRowsPerPage={handlePerRowsChange}
                    onChangePage={handlePageChange}
                    paginationTotalRows={documentos.total}
                    paginationDefaultPage={currentPage}
                    paginationServer
                />
            </div>
        </Container>
    );
}
