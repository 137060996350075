

export function login  (user, token, nome, level){
    localStorage.setItem(TOKEN_KEY, token)
    localStorage.setItem(USER_KEY, user)
    localStorage.setItem(Nome_KEY, nome)
    localStorage.setItem(Level_KEY, level)
    return null
}

export const TOKEN_KEY ="@gestor-Token";
export const USER_KEY ="@gestor-User";
export const Nome_KEY ="@gestor-Nome";
export const Level_KEY ="@gestor-Level";



export const isAuthenticated = () => localStorage.getItem(TOKEN_KEY) !== null && localStorage.getItem(USER_KEY) !== null;
// eslint-disable-next-line
export const isAuthenticatedAndSalesman = () => localStorage.getItem(TOKEN_KEY) !== null && localStorage.getItem(USER_KEY) !== null && (localStorage.getItem(Level_KEY)==1 || localStorage.getItem(Level_KEY)==5 || localStorage.getItem(Level_KEY)==2 || localStorage.getItem(Level_KEY)==4);
// eslint-disable-next-line
export const isAuthenticatedAndWarehouse = () => localStorage.getItem(TOKEN_KEY) !== null && localStorage.getItem(USER_KEY) !== null && (localStorage.getItem(Level_KEY)==1 || localStorage.getItem(Level_KEY)==5 || localStorage.getItem(Level_KEY)==3);
// eslint-disable-next-line
export const isAuthenticatedAndAdmin = () => localStorage.getItem(TOKEN_KEY) !== null && localStorage.getItem(USER_KEY) !== null && (localStorage.getItem(Level_KEY)==1);
// eslint-disable-next-line
export const username = () => localStorage.getItem(USER_KEY);

export const nome = () => localStorage.getItem(Nome_KEY);

export const getSession = ()=>{
    if(isAuthenticated()){
        return {
            status: true,
            data: {
                user: localStorage.getItem(USER_KEY),
                token: localStorage.getItem(TOKEN_KEY)
            }
        }
    }else{
        return {
            status: false,
            data: {
                user: "",
                token: ""
            }
        }
    }
}

export const logout = () => {
    localStorage.clear();
};
