import { atom } from "recoil";

export const EstadoReader = atom({
  key: 'ReaderState',
  default: false,
});

export const InfoReader = atom({
  key: 'ReaderInfo',
  default: null,
});

export const CreateUser=atom({
  key:'CreateUser',
  default: false
})

export const EditUser=atom({
  key:'EditUser',
  default: false
})

export const ReloadComponent=atom({
  key:'ReloadComponent',
  default: true
})


export const IdUser=atom({
  key:'idUser',
  default: 0
})