import React, {useEffect, useState} from 'react';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import Typography from '@material-ui/core/Typography';
import {makeStyles} from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import api from "../../services/api";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import { TextField} from 'unform-material-ui';
import {Form} from '@unform/web';
import DialogActions from "@material-ui/core/DialogActions";
import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";
import Swal from "sweetalert2";
import Grid from "@material-ui/core/Grid";
import CardHeader from "@material-ui/core/CardHeader";
import CreateIcon from '@material-ui/icons/Create';
import IconButton from "@material-ui/core/IconButton";
import EditUserComponent from "../../components/EditUser";
import {useRecoilState} from "recoil";
import {EditUser, IdUser, ReloadComponent} from "../../atoms";
import Divider from "@material-ui/core/Divider";

const useStyles = makeStyles((theme) => ({
    root: {
        minWidth: 345,
    },
    paper: {
        marginTop: theme.spacing(8),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        width:"100%"
    },
    avatar: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.secondary.main,
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(3),
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    },
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: '#fff',
    },
}));

export default function UtilizadorFicha(props) {
    const classes = useStyles();
    const [data, setData] = useState({});
    const [password, setPassword] = useState(false);
    const [open, setOpen] = useState(false);

    const [reload, setReload] = useRecoilState(ReloadComponent);
    const [, setEditUser] = useRecoilState(EditUser);
    const [, setIdUser] = useRecoilState(IdUser);

    useEffect(() => {
        if(true){
            api.get('/user/' + props.match.params.id).then(res => {
                setData(res.data.data[0])
                setIdUser(props.match.params.id)
            })
            setReload(false)
        }
        // eslint-disable-next-line
    }, [props.match.params.id, reload])

    function alterar(data) {
        setPassword(false)
        setOpen(true)
        api.put('/user/password/'+props.match.params.id,data).then(async res=>{
            setOpen(false)
            await Swal.fire({
                title: "Informação",
                text: res.data.message,
                icon: res.data.code === 0 ? "success" : "error",
                timer: 3000
            })
        })

    }

    return (
        <Container component="main" maxWidth="lg">
            <CssBaseline/>
            <EditUserComponent/>
            <div className={classes.paper}>
                <Card className={classes.root} xs={12}>
                    <CardHeader title={data.nome}  action={<IconButton onClick={()=>setEditUser(true)} variant={"outlined"} color={"primary"}><CreateIcon/></IconButton>}/>
                    <CardContent>
                        <center>
                            <Grid container={true} xs={12} spacing={5} style={{width: '30vw'}}>
                                <Grid item xs={6} style={{borderColor:"gray"}}>
                                    <Typography><b>Email: </b><br/><a  rel="noreferrer" target={"_blank"} href={"mailto:"+data.username}>{data.username}</a></Typography>
                                </Grid>
                                <Grid item xs={6} style={{borderColor:"gray"}}>
                                    <Typography><b>Nº Vendedor: </b><br/>{data.SalesmanID!==null?data.SalesmanID:"Não atribuido"}</Typography>
                                </Grid>
                                <Grid item xs={6} style={{borderColor:"gray"}}>
                                    <Typography><b>Permissões: </b><br/>{data.LevelDescription}</Typography>
                                </Grid>
                                <Grid item xs={6} style={{borderColor:"gray"}}>
                                    <Typography><b>Estado da conta: </b><br/>{
                                        // eslint-disable-next-line
                                        data.locked==0?"Ativa":"Desativada"
                                    }</Typography>
                                </Grid>
                                <Grid item xs={12} style={{borderColor:"gray"}}>
                                    <Divider style={{width: '100%'}}/>
                                    <Button style={{marginTop: 20}} variant={"contained"} onClick={()=>setPassword(true)} color={"primary"}>Alterar password</Button>
                                </Grid>
                            </Grid>
                        </center>
                    </CardContent>
                </Card>
            </div>
            {/* ALTERAR PASSWORD */}
            <Dialog open={password} onClose={()=>setPassword(false)} aria-labelledby="form-dialog-title">
                <DialogTitle id="form-dialog-title">Alterar password</DialogTitle>
                <DialogContent>
                    <Form onSubmit={alterar}>
                        <TextField
                            margin="dense"
                            label="Nova password"
                            type="password"
                            name="password_nova"
                            autoComplete="new-password"
                            fullWidth
                        />
                        <TextField
                            margin="dense"
                            label="Nova password confirmação"
                            type="password"
                            name="password_nova_confirm"
                            autoComplete="new-password"
                            fullWidth
                        />
                        <center>
                            <Button style={{marginTop:5}} fullWidth variant={"contained"} type="submit" color={"primary"}>Atualizar password</Button>
                        </center>
                    </Form>
                </DialogContent>
                <DialogActions>
                    <Button onClick={()=>setPassword(false)} color="primary">
                        Cancelar
                    </Button>
                </DialogActions>
            </Dialog>
            <Backdrop className={classes.backdrop} open={open} onClick={()=>{}}>
                <CircularProgress color="inherit" />
            </Backdrop>
        </Container>
    );
}
