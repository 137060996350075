import {
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    FormControlLabel,
    MenuItem, Switch
} from "@material-ui/core";
import Button from "@material-ui/core/Button";
import {useRecoilState} from "recoil";
import {CreateUser, IdUser, ReloadComponent} from "../atoms";
import {Form} from "@unform/web";
import {Select, TextField} from "unform-material-ui";
import Grid from "@material-ui/core/Grid";
import {useEffect, useRef, useState} from "react";
import api from "../services/api";
import {useSnackbar} from "notistack";
import {EditUser} from "../atoms"

export default function EditUserComponent (props){
    const [editUser, setEditUser] = useRecoilState(EditUser);
    const [data, setData] = useState({});
    const [, setReload] = useRecoilState(ReloadComponent);
    const formRef = useRef(null);
    const { enqueueSnackbar } = useSnackbar();
    const [idUser, setIdUser] = useRecoilState(IdUser);
    const [status, setStatus] = useState(false)

    useEffect(() => {
            api.get('/user/' + idUser).then(res => {
                setData(res.data.data[0]);
                if(formRef.current !== null) {
                    setStatus(res.data.data[0].locked==1)
                    formRef.current.setData({
                        name: data.nome,
                        email: data.username,
                        salesman: data.SalesmanID,
                        level: data.level
                    });
                }
            })
    }, [formRef, editUser, idUser])

    function changeStatus(){
        api.put('/user/disable/'+data.id).then(res=>{
            if(res.data.code===0){
                setStatus(res.data.status)
                setReload(true)
            }
        })
    }

    function alterar(data) {
        api.put('/user/'+idUser,data).then(async res=>{
            if(res.data.code===0){
                enqueueSnackbar(res.data.message, { variant:"success", autoHideDuration: 5000 });
                setReload(true)
                setEditUser(false)
            }else{
                enqueueSnackbar(res.data.message, { variant:"error", autoHideDuration: 5000});
               }
        })

    }

    return(
        <Dialog open={editUser} onClose={()=>setEditUser(false)} aria-labelledby="form-dialog-title">
            <DialogTitle id="form-dialog-title">Edição de utilizador</DialogTitle>
            <DialogContent>
                <Form ref={formRef} onSubmit={alterar}>
                    <Grid spacing={2} container={true}>
                        <Grid item xs={6}>
                            <TextField fullWidth={true} margin={"dense"} label={"Nome"} name={"name"}/>
                        </Grid>
                        <Grid item xs={6}>
                            <TextField type={"email"} fullWidth={true} margin={"dense"} label={"Email"} name={"email"}/>
                        </Grid>
                        <Grid item xs={6}>
                            <TextField type={"number"} fullWidth={true} margin={"dense"} label={"Numero de vendedor no Sage"} name={"salesman"}/>
                        </Grid>
                        <Grid item xs={6}>
                            <Select style={{width: '100%', height: '100%', marginTop: '8.4px'}} name={"level"} fullWidth={true} margin={"dense"} label={"Permissões"} variant={"standard"}>
                                <MenuItem value={1}>Administrador</MenuItem>
                                <MenuItem value={2}>Vendedor</MenuItem>
                                <MenuItem value={3}>Gestor de Armazem</MenuItem>
                                <MenuItem value={4}>Vendedor/Gestor de Armazem</MenuItem>
                                <MenuItem value={5}>Administrativa</MenuItem>
                            </Select>
                        </Grid>
                        <Grid item xs={6}>
                            <FormControlLabel
                                control={
                                    <Switch
                                        checked={status}
                                        onChange={changeStatus}
                                        name="checkedB"
                                        color="primary"
                                    />
                                }
                                label="Desativar Utilizador"
                            />
                        </Grid>
                    </Grid>
                </Form>
            </DialogContent>
            <DialogActions>
                <Button onClick={()=>setEditUser(false)} color="primary">
                    Cancelar
                </Button>
                <Button onClick={()=>formRef.current.submitForm()} color="primary">
                    Confirmar
                </Button>
            </DialogActions>
        </Dialog>
    )
}