import React, {useState, useEffect} from 'react';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import api from "../../services/api";
import Collapse from "@material-ui/core/Collapse";
import Alert from "@material-ui/lab/Alert";
import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";
import Select from "react-select"
import TextField from "@material-ui/core/TextField";



const useStyles = makeStyles((theme) => ({
    paper: {
        marginTop: theme.spacing(8),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    avatar: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.secondary.main,
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(3),
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    },
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: '#fff',
    },
}));

export default function DevolverTaras(props) {
    const classes = useStyles();
    const [clientes, setClientes] = useState([]);
    const [cliente, setCliente] = useState([]);
    const [open, setOpen] = useState(false);
    const [alert, setAlert] = useState({open:false, message:"", type:"info"});
    const [taras, setTaras] = useState([]);
    const [artigo, setArtigo] = useState([]);
    const [quantidade, setQuantidade] = useState("");
    const [label, setLabel] = useState(null);

    useEffect(()=>{
        api.get('/cliente').then(res=>{
            if(res.data.code===0){
                setClientes(res.data.data);
            }
        })
    }, [props])

    useEffect(()=>{
        if(cliente.length!==0){
            api.get('/cliente/'+cliente.cliente.id).then(res=>{
                if(res.data.code===0){
                    setTaras(res.data.data.contaCorrenteTaras)
                }
            })
        }
    }, [cliente])

    async function entregar(){
        setOpen(true);
        if(cliente.length!==0 && artigo.length!==0 && quantidade!==""){
            const res = await api.post('/taras', {
                cliente:cliente.cliente.id,
                artigo:artigo.id,
                quantidade:quantidade
            })
            if(res.data.code===0){
                setAlert({
                    open: true,
                    message: res.data.message,
                    type: 'success'
                })
                setLabel(null)
                api.get('/cliente/'+cliente.cliente.id).then(res=>{
                    if(res.data.code===0){
                        setTaras(res.data.data.contaCorrenteTaras)
                    }
                })
            }else{
                setAlert({
                    open: true,
                    message: res.data.message,
                    type: 'error'
                })
            }
        }
        setOpen(false);
    }

    return (
        <Container component="main" maxWidth="xs">
            <CssBaseline />
            <div className={classes.paper}>
                <Typography component="h1" variant="h5">
                    Devolver taras
                </Typography>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <Select
                                placeholder={"Escolher Cliente"}
                                options={clientes}
                                getOptionLabel={(option)=> {return option.cliente.codigoCliente + "  /  " + option.cliente.nome}}
                                getOptionValue ={(option)=>option.cliente.id}
                                onChange={(data)=> {
                                    setCliente(data);
                                    setLabel(null)
                                }}
                                option={cliente}
                            />
                        </Grid>
                        <Grid item xs={12} sm={12}>
                            <Select
                                placeholder={"Definir Tara"}
                                options={taras}
                                getOptionLabel={(option)=> {return option.descricao + " / Quantidade: "+option.quantidade}}
                                getOptionValue ={(option)=>option.id}
                                onChange={(data)=> {
                                    setArtigo(data)
                                    setLabel()
                                }}
                                option={artigo}
                                isDisabled={cliente.length===0}
                                value={label}
                            />
                        </Grid>
                        <Grid item xs={12} sm={12}>
                            <TextField
                                name="quantidade"
                                required
                                type="number"
                                fullWidth
                                label="Quantidade:"
                                value={quantidade}
                                disabled={artigo.length===0}
                                onChange={e =>{
                                    if (e.target.value<=parseInt(artigo.quantidade)){
                                        setQuantidade(parseInt(e.target.value).toFixed(0))
                                    }
                                }}

                            />
                        </Grid>
                        <Grid item xs={12} sm={12}>
                        <Button
                            type="submit"
                            fullWidth
                            disabled={artigo.length===0}
                            variant="contained"
                            color="primary"
                            className={classes.submit}
                            onClick={()=>setQuantidade(artigo.quantidade)}
                        >
                            Devolver todas
                        </Button>
                        </Grid>
                    </Grid>
                    <Collapse in={alert.open} >
                        <Alert severity={alert.type}>
                            {alert.message}
                        </Alert>
                    </Collapse>
                    <Button
                        type="submit"
                        fullWidth
                        variant="contained"
                        color="primary"
                        className={classes.submit}
                        onClick={entregar}
                    >
                        Entregar taras
                    </Button>

            </div>
            <Backdrop className={classes.backdrop} open={open} onClick={()=>{}}>
                <CircularProgress color="inherit" />
            </Backdrop>
        </Container>
    );
}
