import React, {useEffect, useState} from 'react';
import CssBaseline from "@material-ui/core/CssBaseline";
import Container from "@material-ui/core/Container";
import {makeStyles} from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import Paper from "@material-ui/core/Paper";
import CardContent from "@material-ui/core/CardContent";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import api, {auth, url} from '../../services/api'
import Select from "react-select";
import Typography from "@material-ui/core/Typography";
import TableContainer from "@material-ui/core/TableContainer";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogActions from "@material-ui/core/DialogActions";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Switch from "@material-ui/core/Switch";
import DeleteIcon from '@material-ui/icons/Delete';
import ViewWeekSharpIcon from '@material-ui/icons/ViewWeekSharp';import TableBody from "@material-ui/core/TableBody";
import {useHistory} from "react-router-dom";
import Swal from "sweetalert2";
import {atom, useRecoilState} from "recoil";
import CodeReader from "../../components/CodeReader";
import {EstadoReader, InfoReader} from "../../atoms";
import {detectWebcam} from "../../services/functions";
import {Alert} from "@material-ui/lab";


const useStyles = makeStyles((theme) => ({
    root:{
        minWidth: "75vw",
    },
    paper: {
        marginTop: theme.spacing(8),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    avatar: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.secondary.main,
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(3),
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    },
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: '#fff',
    },
    table: {
        //minWidth: "35vw",
    },
    table2: {
        maxWidth: "35vw",
    },
    select: {
        minWidth: "35vw",

    }
}));

export default function ReceberEncomenda(props) {
    const classes = useStyles();
    const [fornecedores, setFornecedores] = useState([]);
    const [artigos, setArtigos] = useState([]);
    const [tara, setTara] = useState({value:1, label:"Vendida"});
    const [linhasArtigo, setLinhasArtigo] = useState([]);
    const [artigo, setArtigo] = useState([]);
    const [fornecedor, setFornecedor] = useState({});
    const [pagamento, setPagamento] = useState(false);
    const [fornecedorDefenido, defineFornecedor] = useState(false);
    const [edit, setEdit] = useState(false);
    const history = useHistory();
    const [infoArtigo, setInfoArtigo] = useState({});
    const [artigoReader, setArtigoReader] = useRecoilState(InfoReader);
    const [openCodeBarReader,setOpenCodeBarReader]=useRecoilState(EstadoReader)
    const [webCam, setWebCam]=useState(false)
    const [find, setFind]=useState(null);



    useEffect(()=>{
        detectWebcam((hasWebCam)=>setWebCam(hasWebCam))
        api.get('/provider/list').then(res=>{
            if(res.data.code===0){
                setFornecedores(res.data.data)
            }
        })
        api.get('/item/list').then(res=>{
            if(res.data.code===0){
                setArtigos(res.data.data)
            }
        })
    },[props])

    useEffect(()=>{
        if(artigoReader!==null){
            let found=false
            artigos.map(row=>{
                if(row.BarCode === artigoReader){
                    setInfoArtigo(row)
                    setArtigoReader(null)
                    setFind(null)
                    found=true
                    return 0
                }
            });
            if(!found){
                setFind("Nenhum artigo encontrado")
            }
        }

    }, [artigoReader])

    /*    useEffect(()=>{
            if(cliente.cliente.id!==0){
                console.log(infoArtigo)
                const temp = infoArtigo;
                temp.preco=infoArtigo.precos[cliente.cliente.linhaPreco-1]
                setInfoArtigo(temp);
            }
        }, [cliente, infoArtigo])*/

    function defenirLinhas() {
        const temp = linhasArtigo;
        infoArtigo.tipo=tara.value;
        let tempArtigo = infoArtigo;
        tempArtigo.quantidade = Number(infoArtigo.quantidade).toFixed(parseInt(infoArtigo.unidade.MaximumDecimals));
        temp.push(tempArtigo);


        setInfoArtigo({quantidade:""})
        setArtigo({})
        setLinhasArtigo(temp)
    }

    function defineContaCorrente(data) {
        setFornecedor(data)
        defineFornecedor(true)
        if(infoArtigo!==undefined) {
            const tmp = infoArtigo;
            tmp.preco = getCurrentPrice(data, infoArtigo)
            setInfoArtigo(tmp)

        }
    }

    function defineArtigo(data) {
        if(fornecedor.OrganizationName===""){
            data.preco=0;
            if(data.defaultPrice===null){
                data.preco="";
            }else{
                //data.preco=data.defaultPrice.TaxIncludedPrice.toString();
            }
        }else{
            //data.preco=getCurrentPrice(cliente, data)
        }

        const calc = ()=>{
            if(fornecedor.OrganizationName===""){
                // return 0;

                if(data.defaultPrice===null){
                    return "";
                }else{
                    return data.defaultPrice.TaxIncludedPrice.toString();
                }
            }else{
                return getCurrentPrice(fornecedor, data)
            }
        }

        setInfoArtigo({...data, preco:calc()})
        //setInfoArtigo(data);
        setTara({value:1, label:"Vendida"});
    }

    function getCurrentPrice(customer, item){
        if(item.prices !== undefined){
            if(typeof item!=="undefined"){
                if(item.Description!==""){
                    if(customer.OrganizationName!=="" && customer.OrganizationName!==undefined){
                        let price = item.prices.filter(pr=>customer.PriceLineID==pr.price_line.id)
                        if(item.defaultPrice!==null){
                            return price.length!==0?price[0].TaxIncludedPrice:item.defaultPrice.TaxIncludedPrice;
                        }else{
                            return  0
                        }
                    }else{
                        if(item.defaultPrice!==null){
                            return item.defaultPrice.TaxIncludedPrice;
                        }else{
                            return  0
                        }
                    }
                }else{
                    return 0;
                }
            }
        }else{
            return 0
        }

    }

    function definePreco(e) {
        if(!isNaN(Number(e.target.value))){
            if(Number(e.target.value)>=0) {
                setInfoArtigo({
                    ...infoArtigo,
                    preco:e.target.value
                })
            }
        }
    }

    function defineQuantidade(e) {
        if(!isNaN(Number(e.target.value))){
            if(Number(e.target.value)>=0) {
                setInfoArtigo({
                    ...infoArtigo,
                    quantidade:e.target.value
                })
            }
        }
    }

    function alteraTara(data) {
        if(data.value!==1){
            setInfoArtigo({
                preco: 0.00,
                id: infoArtigo.id,
                itemCode: infoArtigo.itemCode,
                descricao: infoArtigo.descricao,
                unidade: infoArtigo.unidade,
                estado: infoArtigo.estado,
                idFamilia: infoArtigo.idFamilia,
                familia:infoArtigo.familia,
                precos: infoArtigo.precos,
                quantidade:infoArtigo.quantidade,
                tara:infoArtigo.tara,
                Description:infoArtigo.Description,
                MaximumDecimals: infoArtigo.MaximumDecimals,
                UnitOfMeasureID: infoArtigo.UnitOfMeasureID,
            })
        }
        setTara(data)
    }

    async function guardarDoc(pago) {
        const data={};
        data.fornecedor=fornecedor.id;
        data.pago=pago
        data.linhas=linhasArtigo;
        const res =await api.post('/warehouse', data);
        setPagamento(false)
        if(res.data.code===0){
            await Swal.fire({
                title: "Informação",
                text: res.data.message,
                icon:"success",
                showDenyButton: true,
                confirmButtonText:"Obter documento",
                denyButtonText:"Fechar",
            }).then(r=>{
                if(r.isConfirmed){
                    window.open(url+"pdf/docArmazem/"+res.data.data+"/"+auth(), '_blank')
                    history.push("/armazem/ver/"+res.data.data)

                }else{
                    history.push("/armazem/ver/"+res.data.data)
                }
            })
        }else{
            await Swal.fire({
                title: "Informação",
                text: res.data.message,
                icon: "error",
                timer: 3000
            })
        }

    }

    function editarLinha(idx) {
        // eslint-disable-next-line array-callback-return
        console.log(idx)
        const newTmp=linhasArtigo.filter((value, index) => {
            if(index===idx){
                setInfoArtigo(value);
            }else{
                return value;
            }
        })

        setLinhasArtigo(newTmp);
    }

    function removerLinha(idx) {
        // eslint-disable-next-line array-callback-return
        const newTmp=linhasArtigo.filter((value, index) => {
            if (index !== idx) {
                return value;
            }
        })

        setLinhasArtigo(newTmp);
    }

    function removeZerosArtigo() {
        if(infoArtigo.preco===0 || infoArtigo.preco==="0.00"){
            setInfoArtigo(prevState => {prevState.preco=""; return prevState})
        }
    }

    return(
        <Container component="main" maxWidth="xs">
            <CssBaseline />
            <CodeReader/>
            <div className={classes.paper}>
                <Typography component="h1" variant="h5">
                    Criar registo em armazém <br/>
                </Typography>
                <Card className={classes.root} variant="outlined">
                    <CardContent>
                        <Grid container spacing={2}>
                            <Grid item xs={12} sm={6}>
                                <Select
                                    onChange={defineContaCorrente}
                                    className={classes.select}
                                    name="fornecedores"
                                    placeholder="Fornecedores"
                                    options={fornecedores}
                                    option={fornecedor}
                                    getOptionLabel={(option)=> option.OrganizationName}
                                    getOptionValue ={(option)=>option.id}
                                />
                            </Grid>
                            <Grid item xs={webCam?6:4} sm={webCam?4:6}>
                                <Select
                                    onChange={defineArtigo}
                                    name="artigos"
                                    option={artigo}
                                    placeholder="Artigos"
                                    options={artigos}
                                    getOptionLabel={(option)=> {return option.ItemID + "  /  " + option.Description}}
                                    getOptionValue ={(option)=>option.id}
                                />
                            </Grid>
                            {webCam?(
                                <Grid item xs={4} sm={2}>
                                    <center>
                                        <Button style={{minWidth: '90%'}} type="button" color="primary" variant="contained" onClick={()=>setOpenCodeBarReader(true)}><ViewWeekSharpIcon/> </Button>
                                    </center>
                                </Grid>):null
                            }
                            {find!==null?(
                                <Grid item xs={12} sm={12}>
                                    <Alert severity="error">{find}</Alert>
                                </Grid>
                            ):null}
                            <Grid item xs={12} sm={12}>
                                <TableContainer component={Paper} style={{marginBottom: 10}}>
                                    <Table className={classes.table} aria-label="Descricao do artigo">
                                        <TableHead>
                                            <TableRow>
                                                <TableCell>Codigo artigo</TableCell>
                                                <TableCell>Descrição</TableCell>
                                                <TableCell>Quantidade</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            <TableRow >
                                                <TableCell>{infoArtigo.ItemID}</TableCell>
                                                <TableCell>{infoArtigo.Description}</TableCell>
                                                <TableCell><TextField name="quantidade" onKeyPress={(e) => {
                                                    if(e.key==='Enter'){
                                                        if(!(infoArtigo.ItemID === undefined || parseFloat(infoArtigo.quantidade) <= 0 || infoArtigo.quantidade===undefined)){
                                                            defenirLinhas()
                                                        }
                                                    }
                                                }} onChange={defineQuantidade} type="number"  value={infoArtigo.quantidade}/></TableCell>
                                            </TableRow>
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                                {infoArtigo.tara==1?(
                                    <Select style={{marginTop: 20, marginLeft: 20}} value={tara} option={{value:1, label:"Vendida"}} onChange={alteraTara} options={[{value:1, label:"Vendida"},{value:2, label:"Emprestada"},{value:3, label:"Oferta"} ]} variant="outlined" name="tara" placeholder="Tara"/>):null}
                                <Button onClick={defenirLinhas} disabled={infoArtigo.ItemID === undefined || parseFloat(infoArtigo.quantidade) <= 0 || infoArtigo.quantidade===undefined} fullWidth style={{marginTop: 20}} variant="contained" color="primary">Adicionar artigo</Button>
                            </Grid>
                            <Grid item xs={12}>
                                <TableContainer component={Paper}>
                                    <Table className={classes.table} aria-label="Artigos do documento">
                                        <TableHead>
                                            <TableRow>
                                                {edit===false?(<TableCell>Codigo artigo</TableCell>):(null)}
                                                <TableCell>Descrição</TableCell>
                                                {edit===false?(<TableCell>Quantidade</TableCell>):(<TableCell colSpan={2}><center>Ações</center></TableCell>)}
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {linhasArtigo.map((row, index) =>
                                                <TableRow key={index}>
                                                    {edit===false?(<TableCell>{row.ItemID}</TableCell>):null}
                                                    <TableCell>{row.Description}</TableCell>
                                                    {edit===false?(<TableCell>{row.quantidade}</TableCell>):(<TableCell><center><Button onClick={()=>editarLinha(index)} variant="contained" color="primary">Editar linha</Button></center></TableCell>)}
                                                </TableRow>
                                            )}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                                <Button onClick={()=>setPagamento(true)} disabled={linhasArtigo.length<= 0 || !fornecedorDefenido===true} fullWidth style={{marginTop: 20}} variant="contained" color="primary">Guardar</Button>
                                <FormControlLabel
                                    control={
                                        <Switch
                                            checked={edit}
                                            onChange={()=>setEdit(!edit)}
                                            name="checkedB"
                                            color="primary"
                                        />
                                    }
                                    label="Editar registo"
                                />
                            </Grid>
                        </Grid>
                    </CardContent>
                </Card>
            </div>
            <Dialog
                open={pagamento}
                onClose={()=>setPagamento(false)}
                aria-labelledby="responsive-dialog-title"
            >
                <DialogTitle id="responsive-dialog-title">{"Guardar documento?"}</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Deseja guardar o registo?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button autoFocus onClick={()=>setPagamento(false)} color="primary">
                        Cancelar
                    </Button>
                    <Button onClick={()=>guardarDoc(true)} color="primary" autoFocus>
                        Guardar
                    </Button>
                </DialogActions>
            </Dialog>
        </Container>
    )
}
