import React from 'react';
import { RecoilRoot } from 'recoil';
import Routes from "./Routes";
import CssBaseline from "@material-ui/core/CssBaseline";
import {ThemeProvider}  from "@material-ui/core/styles";
import theme from "./theme";
import { SnackbarProvider } from 'notistack';


export default ()=> {
  return (
      <SnackbarProvider maxSnack={5}>
          <ThemeProvider theme={theme}>
              <RecoilRoot>
                  <CssBaseline />
                  <Routes />
              </RecoilRoot>
          </ThemeProvider>
      </SnackbarProvider>
  );
}
