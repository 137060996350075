import React, {useState, useEffect} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import CircularProgress from "@material-ui/core/CircularProgress";
import api from "../../services/api";
import DataTable from "react-data-table-component";
import SortIcon from "@material-ui/icons/ArrowDownward";
import Checkbox from "@material-ui/core/Checkbox";
import Button from "@material-ui/core/Button";
import {Link} from "react-router-dom";

const useStyles = makeStyles((theme) => ({
    paper: {
        marginTop: theme.spacing(8),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    avatar: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.primary.main,
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(1),
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    },
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: '#fff',
    },
}));


export default function FornecedoresListar() {
    const classes = useStyles();

    const [fornecedores, setFornecedores] = useState([]);
    const [loading, setLoading] = React.useState(false);
    const [q, setQ] = useState("");
    const [totalRows, setTotalRows] = useState(0);
    const [perPage, setPerPage] = useState(10);
    const [currentPage, setCurrentPage] = useState(1);
    const [sort, setSort] = useState({column:'SupplierID', order:'asc'});


    function obter(page=currentPage, size=perPage, query=q){
        setLoading(true)
        api.get(`/provider?page=${page}&per_page=${size}&q=${query}&order=${sort.order}&collum=${sort.column}`).then(res=>{
            if(res.data.code===0){
                setFornecedores(res.data.data.list)
                setTotalRows(res.data.data.total)
            }
            setLoading(false)
        })
    }

    const handlePageChange = page => {
        console.log(page)
        obter(page);
        setCurrentPage(page);
    };

    function handleTextChange(e){
        setQ(e.target.value)
    }

    const handlePerRowsChange = async (newPerPage, page) => {
        obter(page, newPerPage);
        setPerPage(newPerPage);
    };

    const handleSort = async (column, order)=>{
        setSort({column:column.selector, order})
    }


    useEffect(()=>{
        obter()
    }, [sort])

    const columns = [
        {
            name: "ID",
            selector: "SupplierID",
            sortable: true,
            width:'10%'
        },
        {
            name: "Nome",
            selector: "OrganizationName",
            sortable: true,
            width:'60%'
        },
        {
            name:"",
            cell:row=><Link to={"/fornecedores/" + row.id}><Button variant={"contained"} color={"primary"}>Ficha</Button></Link>
        }
    ];



    return (
        <Container component="main" maxWidth="md">
            <div className={classes.paper}>
                <DataTable
                    title="Fornecedores"
                    columns={columns}
                    data={fornecedores}
                    sortIcon={<SortIcon />}
                    pagination
                    onChangeRowsPerPage={handlePerRowsChange}
                    onChangePage={handlePageChange}
                    paginationTotalRows={totalRows}
                    paginationDefaultPage={currentPage}
                    paginationServer
                    onSort={handleSort}
                    //selectableRows
                    selectableRowsComponent={Checkbox}
                    contextMessage={{
                        singular: 'artigo',
                        plural: 'artigos',
                        message: 'selecionada\\s' }}
                    noDataComponent={"Não foi encontrados fornecedores"}
                    progressPending={loading}
                    progressComponent={<CircularProgress />}
                    paginationComponentOptions={{
                        rowsPerPageText: 'Linhas por pagina:',
                        rangeSeparatorText: 'de',
                        noRowsPerPage: false,
                        selectAllRowsItem: false,
                        selectAllRowsItemText: 'Todas'
                    }}
                />
            </div>
        </Container>
    );
}
