import React, {useEffect, useRef, useState} from "react"
import CssBaseline from "@material-ui/core/CssBaseline";
import Container from "@material-ui/core/Container";
import Typography from "@material-ui/core/Typography";
import Card from "@material-ui/core/Card";
import {makeStyles} from "@material-ui/core/styles";
import CardContent from "@material-ui/core/CardContent";
import MomentUtils from '@date-io/moment';
import moment from "moment";
import {DatePicker, MuiPickersUtilsProvider,} from '@material-ui/pickers';
import {
    Button,
    Dialog, DialogActions, DialogContent, DialogTitle, FormControlLabel, MenuItem,
    Paper, Switch,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow, useMediaQuery, useTheme
} from "@material-ui/core";
import api from "../../services/api";
import Grid from "@material-ui/core/Grid";
import {Form} from "@unform/web";
import {Select, TextField} from "unform-material-ui";
import Swal from "sweetalert2";
import {default as RSelect} from "@appgeist/react-select-material-ui"
import PersonAddIcon from '@material-ui/icons/PersonAdd';
import {isAuthenticatedAndAdmin} from "../../services/auth";
import NewCustomerMask from "../../components/NewCustomerMask";

const useStyles = makeStyles((theme) => ({
    root: {
        minWidth: "75vw",
    },
    paper: {
        marginTop: theme.spacing(8),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    avatar: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.secondary.main,
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(3),
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    },
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: '#fff',
    },
    table: {
        //minWidth: "35vw",
    },
    table2: {
        maxWidth: "35vw",
    },
    select: {
        minWidth: "35vw",

    },
    margem: {
        '& > *': {
            margin: theme.spacing(1),
        },
    },
}));

export default function RegistoDiario(props) {

    const [selectedDate, setSelectedDate] = useState(new Date());
    const [dataNovo, setDataNovo] = useState(new Date());
    const [reg, setReg] = useState([]);
    const [novoRegisto, setNovoRegisto] = useState(false);
    const [EditarRegisto, setEditarRegisto] = useState(false);
    const [tmp, setTmp] = useState({});
    const [sendAdmin, setSendAdmin] = useState(false);
    const [newCustomer, setNewCustomer] = useState(false);
    const [fromMask, setFromMask] = useState(false);
    const formRefNovoRegisto = useRef(null);
    const formRefEditar = useRef(null);
    const [edit, setEdit] = useState(0);
    const [clientes, setClientes]=useState([])
    const [users, setUsers]=useState([])
    const [clienteDefind, setClienteDefined]=useState({})
    const [userInfo, setUserInfo]=useState({})
    const [idCliente, setIdCliente]=useState({})
    const theme = useTheme();

    const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

    let saving = false

    useEffect(()=>{
        if(props.match.params.cliente!==undefined){
            //setNovoRegisto(true)
            setClienteDefined(clientes.filter(row=> {
                if(row.id == props.match.params.cliente) {
                    return row
                }
            }))
            //setIdCliente(props.match.params.cliente)
        }
    }, [props.match.params.cliente])

    useEffect(()=>{
        api.get('/cliente/list').then((res)=>{
            if(res.data.code==0){
                setClientes(res.data.data)
            }
        })
        if(isAuthenticatedAndAdmin()){
            api.get('/user').then((res)=>{
                if(res.data.code==0){
                    setUsers(res.data.data)
                }
            })
        }

    }, [props])

    const handleDateChange = (date) => {
        setSelectedDate(date);
    };

    const handleDateChangeNovo = (date) => {
        setDataNovo(date);
    };

    const fecharNovo = ()=>{
        setTmp(formRefNovoRegisto.current.getData())
        setNovoRegisto(false)
    }

    const abrirNovo = ()=>{
        setNovoRegisto(true)
        setTmp({
            cliente: props.match.params.cliente
        })
        //formRefNovoRegisto.current.setFieldValue('cliente', 'John Doe');
    }

    const fecharEditar = ()=>{
        setTmp(formRefNovoRegisto.current.getData())
        setEditarRegisto(false)
    }

    const abrirEdiar = (idx)=>{
        setEdit(idx)
        setEditarRegisto(true)
    }

    const criarRegisto=(data)=>{
        if(saving===false){
            if(!newCustomer){
                setTmp({})
                data.data=moment(dataNovo).format("DD/MM/YYYY");
                if(data.data!==undefined && (clienteDefind.id!==undefined || fromMask===true)){
                    data.cliente=clienteDefind.id;
                    api.post('/registo', data).then(async r => {
                        if(r.data.code===0){
                            fecharNovo()
                            getData()
                            Swal.fire({
                                icon: 'success',
                                title: r.data.message,
                                timer: 1000
                            })
                        }else{
                            fecharNovo()
                            await Swal.fire({
                                icon: 'error',
                                title: 'Oops...',
                                text: r.data.message,
                                timer: 2500

                            })
                            abrirNovo()
                        }
                    })
                }else{
                    setTmp(formRefNovoRegisto.current.getData())
                    fecharNovo()
                    Swal.fire({
                        icon: 'error',
                        title: 'Oops...',
                        text: 'Não foi preenchido o cliente',
                        timer: 2500
                    }).then(()=> {
                        setNovoRegisto(true)
                    })
                }
            }
        }
    }

    const GuardarRegisto=(data)=>{
        if(clienteDefind.id!==undefined) {
            data.cliente=reg[edit].cliente.id
        }else{
            data.cliente=clienteDefind.id
        }
        api.put('/registo/'+reg[edit].id, data).then(async r => {
            if(r.data.code===0){
                getData()
                fecharEditar()
                Swal.fire({
                    icon: 'success',
                    title: r.data.message,
                    timer: 1000
                })
            }else{
                fecharEditar()
                await Swal.fire({
                    icon: 'error',
                    title: 'Oops...',
                    text: r.data.message,
                    timer: 2500
                })
                abrirEdiar(edit)
            }
        })
    }

    function send(){
        Swal.fire({
            icon: 'info',
            title: "A enviar",
        })
        api.post('/registo/send', {
            "data": moment(selectedDate).format("DD/MM/YYYY")
        }).then(r=>{
            Swal.close()
            if(r.data.code===0){
                Swal.fire({
                    icon: 'success',
                    title: r.data.message,
                    timer: 1000
                })
            }else{
                Swal.fire({
                    icon: 'error',
                    title: 'Oops...',
                    text: r.data.message,
                    timer: 2500

                })
            }
        })
    }

    useEffect(() => {
        getData()
    }, [selectedDate])

    const getData=()=>{
        api.post('registo/get', {
            "data": moment(selectedDate).format("DD/MM/YYYY")
        }).then(res => {
            if (res.status === 200) {
                if (res.data.code === 0) {
                    setReg(res.data.data)
                }
            }
        })
    }

    function apagar(){
        fecharEditar()
        Swal.fire({
            title: 'Deseja apagar o registo?',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#d33',
            confirmButtonText: 'Apagar',
            cancelButtonText:"Cacelar"
        }).then((result) => {
            api.delete('registo/'+reg[edit].id).then(async(r)=>{
                if(r.data.code===0){
                    getData()
                    fecharEditar()
                    Swal.fire({
                        icon: 'success',
                        title: r.data.message,
                        timer: 1000
                    })
                }else{
                    fecharEditar()
                    await Swal.fire({
                        icon: 'error',
                        title: 'Oops...',
                        text: r.data.message,
                        timer: 2500

                    })
                    abrirEdiar(edit)
                }
                }
            )
        })
    }

    function sendRelatoryAdmin(){
        setSendAdmin(false)
        api.post('/registo/sendAdmin', {
            id:userInfo.id,
            data:moment(selectedDate).format("DD/MM/YYYY")
        }).then(async(r)=>{
            if(r.data.code===0){
                Swal.fire({
                    icon: 'success',
                    title: r.data.message,
                    timer: 1000
                })
            }else{
                await Swal.fire({
                    icon: 'error',
                    title: 'Oops...',
                    text: r.data.message,
                    timer: 2500
                })
                //abrirEdiar(edit)
            }
        }
        )
    }

    const classes = useStyles();
    return (
        <MuiPickersUtilsProvider utils={MomentUtils}>
            <Container component="main" maxWidth="lg">
                <CssBaseline/>
                <div className={classes.paper}>
                    <Typography component="h1" variant="h5">
                        Registo de visitas <br/>
                    </Typography>
                    <Card className={classes.root} variant="outlined">
                        <CardContent>
                            <DatePicker
                                variant="inline"
                                format="DD/MM/YYYY"
                                margin="normal"
                                id="date-picker-inline"
                                label="Data"
                                value={selectedDate}
                                onChange={handleDateChange}
                                KeyboardButtonProps={{
                                    'aria-label': 'Definir data',
                                }}
                            />
                            <TableContainer component={Paper}>
                                <Table className={classes.table} size="small" aria-label="a dense table">
                                    <TableHead>
                                        <TableRow>
                                            <TableCell>Cliente</TableCell>
                                            {/*<TableCell align={'center'}>Valor da venda</TableCell>*/}
                                            <TableCell align={'center'}>Observações</TableCell>
                                            <TableCell align={'center'}>Editar</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                    {reg.map((row, idx) => (
                                        <TableRow key={idx}>
                                            <TableCell>{row.cliente.OrganizationName}</TableCell>
                                            {/*<TableCell align={'center'}>{row.valorVendido!==null?parseFloat(row.valorVenda).toFixed(2)+"€":"-"}</TableCell>*/}
                                            <TableCell align={'center'}>{row.observacoes!==null?row.observacoes.split('\n').map((item, key) => {
                                                if(item.indexOf("Nome") >= 0){
                                                    return <React.Fragment key={key}>{item.replace('Nome:', 'Cliete novo, nome: ')}<br/></React.Fragment>
                                                }
                                            }):"-"}</TableCell>
                                            <TableCell align={'center'}><Button onClick={()=>abrirEdiar(idx)} variant={"contained"} color={"secondary"}>Editar</Button></TableCell>
                                        </TableRow>
                                    ))}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                            <br/>
                            <div className={classes.margem}>
                                <Button variant="contained" onClick={send} color="default">
                                    Enviar
                                </Button>
                                <Button variant="contained" onClick={abrirNovo} color="primary">
                                    Novo
                                </Button>

                                {isAuthenticatedAndAdmin()?(
                                    <Button variant="contained" onClick={()=>setSendAdmin(true)} color="primary">
                                        Envio por vendedor
                                    </Button>
                                ):null}
                            </div>
                        </CardContent>
                    </Card>
                </div>
            </Container>

            <Form ref={formRefNovoRegisto} onSubmit={criarRegisto} initialData={tmp}>
                <Dialog fullScreen={true} maxWidth={"xl"} open={novoRegisto} onClose={fecharNovo} aria-labelledby="Novo registo">
                    <Grid container>
                        <Grid item xs={10}>
                            <DialogTitle >Novo registo</DialogTitle>
                        </Grid>
                        <Grid item xs={1}>
                            <PersonAddIcon onClick={()=>setNewCustomer(!newCustomer)}  style={{marginTop:"5px", marginLeft:'20px'}}/>
                        </Grid>
                        <Grid item xs={1}>
                            <FormControlLabel control={<Switch checked={newCustomer} onChange={()=>setNewCustomer(!newCustomer)} color={"primary"} />} label={""} />
                        </Grid>
                    </Grid>

                    <DialogContent>
                        {!newCustomer?(
                                <Container component="main">
                                <div className={classes.paper}>
                                    <Grid  container spacing={2}>
                                        <Grid item xs={12} sm={6}>
                                            <DatePicker
                                                style={{width:'100%', marginTop:'-1px'}}
                                                inputVariant="outlined"
                                                format="DD/MM/YYYY"
                                                fullwidth={true}
                                                margin="normal"
                                                id="date-picker-inline"
                                                label="Data"
                                                value={dataNovo}
                                                onChange={handleDateChangeNovo}
                                                KeyboardButtonProps={{
                                                    'aria-label': 'Definir data',
                                                }}
                                            />
                                        </Grid>
                                        <Grid item xs={12} sm={6}>
                                            <RSelect
                                                options={clientes}
                                                getOptionLabel={option=>option.OrganizationName}
                                                getOptionValue={option=>option.id}
                                                placeholder={"Clientes"}
                                                isClearable
                                                menuPortalTarget={document.getElementsByClassName("MuiGrid-root MuiGrid-item MuiGrid-grid-xs-12 MuiGrid-grid-sm-6")[0]}
                                                menuPosition={'fixed'}
                                                isSearchable
                                                defaultValue={idCliente!==undefined?clientes.filter(row=>{
                                                    if(row.id==props.match.params.cliente) {
                                                        return row
                                                    }
                                                }):null}
                                                onChange={option=>setClienteDefined(option)}
                                            />
                                        </Grid>
                                        <Grid item xs={12} sm={6}>
                                            <TextField
                                                name="observacoes"
                                                label="Observações"
                                                type="text"
                                                fullWidth
                                                variant={"outlined"}
                                                multiline={true}
                                            />
                                        </Grid>
                                        <Grid item xs={12} sm={6}>
                                            <Select variant={"outlined"} style={{width:'100%'}} name="local" label="Local" required>
                                                <MenuItem value="telefone">Telefone</MenuItem>
                                                <MenuItem value="presencial">Presencial</MenuItem>
                                                <MenuItem value="email">Email</MenuItem>
                                            </Select>
                                        </Grid>
                                    </Grid>
                                </div>
                                    </Container>
                        ):<NewCustomerMask setFromMask={setFromMask} setTmp={setTmp} newCustomer={setNewCustomer}/>}

                    </DialogContent>
                    <DialogActions>
                        <Button onClick={fecharNovo} color="primary">
                            Cancelar
                        </Button>
                        <Button onClick={()=>formRefNovoRegisto.current.submitForm()} color="primary">
                            Guardar
                        </Button>
                    </DialogActions>
                </Dialog>
            </Form>

            <Form ref={formRefEditar}  onSubmit={GuardarRegisto} initialData={reg[edit]}>
                <Dialog fullScreen={true} maxWidth={"xl"} open={EditarRegisto} onClose={fecharEditar} aria-labelledby="Novo registo">
                    <DialogTitle >Editar registo</DialogTitle>
                    <DialogContent>
                        <Container component="main">
                            <div className={classes.paper}>
                        <Grid container spacing={2}>
                            <Grid item xs={6} sm={6}>
                                <TextField
                                    name="observacoes"
                                    label="Observações"
                                    type="text"
                                    variant={"outlined"}
                                    fullWidth
                                    multiline
                                />
                            </Grid>
                            <Grid item xs={6} sm={6}>
                                <Grid container xs={12} sm={12}>
                                    <Grid item xs={6} sm={6}>
                                        <Select variant={"outlined"} name="local" label="Local" required>
                                            <MenuItem value="telefone">Telefone</MenuItem>
                                            <MenuItem value="presencial">Presencial</MenuItem>
                                            <MenuItem value="email">Email</MenuItem>
                                        </Select>
                                    </Grid>
                                    <Grid item xs={6} sm={6}>
                                        <RSelect
                                            options={clientes}
                                            getOptionLabel={option=>option.OrganizationName}
                                            getOptionValue={option=>option.id}
                                            placeholder={"Clientes"}
                                            defaultValue={reg[edit]!==undefined?reg[edit].cliente:null}
                                            onChange={option=>setClienteDefined(option)}
                                            menuPortalTarget={document.getElementsByClassName("MuiDialogActions-root MuiDialogActions-spacing")[0]}
                                            menuPosition={'fixed'}

                                        />
                                    </Grid>
                                </Grid>

                            </Grid>
                            <Grid item xs={6} sm={6}>
                                <Button onClick={apagar} variant={"contained"} color={"secondary"}>Apagar registo</Button>
                            </Grid>
                        </Grid>
                            </div>
                        </Container>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={fecharEditar} color="primary">
                            Cancelar
                        </Button>
                        <Button onClick={()=>formRefEditar.current.submitForm()} color="primary">
                            Guardar
                        </Button>
                    </DialogActions>
                </Dialog>
            </Form>

            <Dialog open={sendAdmin} onClose={()=>setSendAdmin(false)} aria-labelledby="Novo registo">
                <DialogTitle >Envio por administrador</DialogTitle>
                <DialogContent>
                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={12}>
                            <Grid item xs={12} sm={6}>
                                <DatePicker
                                    format="DD/MM/YYYY"
                                    margin="normal"
                                    id="date-picker-inline"
                                    label="Data"
                                    value={dataNovo}
                                    onChange={handleDateChangeNovo}
                                    KeyboardButtonProps={{
                                        'aria-label': 'Definir data',
                                    }}
                                />
                            </Grid>
                            <RSelect
                                options={users}
                                getOptionLabel={option=>option.nome}
                                getOptionValue={option=>option.id}
                                placeholder={"Vendedores"}
                                onChange={option=>setUserInfo(option)}
                                menuPortalTarget={document.getElementsByClassName("MuiDialogActions-root MuiDialogActions-spacing")[0]}
                                menuPosition={'fixed'}
                            />
                        </Grid>

                        <Grid item xs={12} sm={12}>
                            <Button onClick={sendRelatoryAdmin} variant={"contained"} color={"primary"}>Enviar registo</Button>
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button onClick={fecharEditar} color="primary">
                        Cancelar
                    </Button>
                    <Button onClick={()=>formRefEditar.current.submitForm()} color="primary">
                        Guardar
                    </Button>
                </DialogActions>
            </Dialog>
        </MuiPickersUtilsProvider>
    )
}