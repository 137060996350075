import React from 'react';
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import Collapse from "@material-ui/core/Collapse";
import Divider from "@material-ui/core/Divider";
import {makeStyles} from "@material-ui/core/styles";
import {Link} from "react-router-dom";
import {isAuthenticatedAndAdmin, isAuthenticatedAndSalesman, isAuthenticatedAndWarehouse} from "../services/auth";


const useStyles = makeStyles((theme) => ({
    nested: {
        paddingLeft: theme.spacing(4),
        //color:"rgba(255,255,255,0.8)"
    },
}));

export default function MenuList() {
    const classes = useStyles();

    const [familias, setFamilias] = React.useState(false);
    const [artigos, setArtigos] = React.useState(false);
    const [clientes, setClientes] = React.useState(false);
    const [documentos, setDocumentos] = React.useState(false);
    const [sistema, setSistema] = React.useState(false);
    const [armazem, setArmazem] = React.useState(false);
    const [fornecedor, setFornecedor] = React.useState(false);

    const handleClickFamilias=()=>{
        setFamilias(!familias);
    }

    const handleClickArtigos=()=>{
        setArtigos(!artigos);
    }

    const handleClickClientes=()=>{
        setClientes(!clientes);
    }

    const handleClickArmazem=()=>{
        setArmazem(!armazem);
    }

    const handleClickFornecedor=()=>{
        setFornecedor(!fornecedor);
    }

    const handleClickDocumentos=()=>{
        setDocumentos(!documentos);
    }

    const handleClickSistema=()=>{
        setSistema(!sistema);
    }

    return(
        <>
            <List >
                <Link to={"/dashboard"}>
                    <ListItem button >
                        <ListItemText primary="Dashboard" style={{fontFamily: '"Algerian Regular", Helvetica, Arial, sans-serif'}}/>
                    </ListItem>
                </Link>
                {
                    isAuthenticatedAndSalesman()?(
                        <>
                            {/*<Divider />*/}
                            <List>
                                <ListItem button onClick={handleClickDocumentos}  >
                                    <ListItemText primary="Encomendas" />
                                </ListItem>
                                <Collapse in={documentos} timeout="auto" unmountOnExit >
                                    <List component="div" disablePadding>
                                        <Link to={"/documentos"}>
                                            <ListItem button className={classes.nested} >
                                                <ListItemText primary="Criar encomenda" />
                                            </ListItem>
                                        </Link>
                                    </List>
                                    <List component="div" disablePadding>
                                        <Link to={"/documentos/teclado"}>
                                            <ListItem button className={classes.nested}>
                                                <ListItemText primary="Criar encomenda teclado" />
                                            </ListItem>
                                        </Link>
                                    </List>
                                    <List component="div" disablePadding>
                                        <Link to={"/documentos/criarlista"}>
                                            <ListItem button className={classes.nested}>
                                                <ListItemText primary="Criar encomenda lista" />
                                            </ListItem>
                                        </Link>
                                    </List>
                                    <List component="div" disablePadding>
                                        <Link to={"/documentos/criarHistorico"}>
                                            <ListItem button className={classes.nested}>
                                                <ListItemText primary="Criar encomenda Histórico" />
                                            </ListItem>
                                        </Link>
                                    </List>
                                    <List component="div" disablePadding>
                                        <Link to={"/documentos/lista/"}>
                                            <ListItem button className={classes.nested}>
                                                <ListItemText primary="Listar encomendas" />
                                            </ListItem>
                                        </Link>
                                    </List>
                                </Collapse>
                            </List>
                        </>
                    ):null
                }
                {
                    isAuthenticatedAndWarehouse()?(
                        <>
                            {/*<Divider />*/}
                            <ListItem button onClick={handleClickArmazem}  >
                                <ListItemText primary="Armazém" />
                            </ListItem>
                            <Collapse in={armazem} timeout="auto" unmountOnExit>
                                <List component="div" disablePadding>
                                    <Link to={"/armazem/receberEncomenda"}>
                                        <ListItem button className={classes.nested}>
                                            <ListItemText primary="Receber encomenda" />
                                        </ListItem>
                                    </Link>
                                </List>
                                <List component="div" disablePadding>
                                    <Link to={"/armazem"}>
                                        <ListItem button className={classes.nested}>
                                            <ListItemText primary="Entradas de armazém" />
                                        </ListItem>
                                    </Link>
                                </List>
                            </Collapse>
                        </>
                    ):null
                }
                {
                    isAuthenticatedAndWarehouse()?(
                        <>
                            {/*<Divider />*/}
                            <ListItem button onClick={handleClickFornecedor}  >
                                <ListItemText primary="Fornecedores" />
                            </ListItem>
                            <Collapse in={fornecedor} timeout="auto" unmountOnExit>
                                <List component="div" disablePadding>
                                    <Link to={"/fornecedores"}>
                                        <ListItem button className={classes.nested}>
                                            <ListItemText primary="Listar fornecedores" />
                                        </ListItem>
                                    </Link>
                                </List>
                            </Collapse>
                        </>
                    ):null
                }
                {
                    isAuthenticatedAndSalesman()?(
                        <>
                            {/*<Divider />*/}
                            <ListItem button onClick={handleClickClientes}  >
                                <ListItemText primary="Clientes" />
                            </ListItem>
                            <Collapse in={clientes} timeout="auto" unmountOnExit>
                                <List component="div" disablePadding>
                                    <Link to={"/clientes"}>
                                        <ListItem button className={classes.nested}>
                                            <ListItemText primary="Listar clientes" />
                                        </ListItem>
                                    </Link>
                                </List>
                            </Collapse>
                        </>
                    ):null
                }
                {
                    isAuthenticatedAndSalesman()?(
                        <>
                            {/*<Divider />*/}
                            <Link to={"/registos"}>
                                <ListItem button  >
                                    <ListItemText primary="Registo de Visita" />
                                </ListItem>
                            </Link>

                        </>
                    ):null
                }
                {/*<Divider />*/}
                <ListItem button onClick={handleClickFamilias}  >
                    <ListItemText primary="Familias" />
                </ListItem>
                <Collapse in={familias} timeout="auto" unmountOnExit>
                    {/*<List component="div" disablePadding>
                        <Link to={"/familias/criar"}>
                            <ListItem button className={classes.nested}>
                                <ListItemText primary="Criar familia" />
                            </ListItem>
                        </Link>
                    </List>*/}
                    <List component="div" disablePadding>
                        <Link to={"/familias"}>
                            <ListItem button className={classes.nested}>
                                <ListItemText primary="Listar familias" />
                            </ListItem>
                        </Link>
                    </List>
                </Collapse>
                {/*<Divider />*/}
                <List className={classes.main}>
                    <ListItem button onClick={handleClickArtigos}  >
                        <ListItemText primary="Artigos" />
                    </ListItem>
                    <Collapse in={artigos} timeout="auto" unmountOnExit>
                        {/*<List component="div" disablePadding>
                        <Link to={"/artigos/criar"}>
                            <ListItem button className={classes.nested}>
                                <ListItemText primary="Criar artigos" />
                            </ListItem>
                        </Link>
                    </List>*/}
                        <List component="div" disablePadding>
                            <Link to={"/artigos"}>
                                <ListItem button className={classes.nested}>
                                    <ListItemText primary="Listar artigos" />
                                </ListItem>
                            </Link>
                        </List>
                    </Collapse>
                </List>
                <Divider style={{}} />
                {
                    isAuthenticatedAndAdmin()?(
                        <>
                            <Link to={"/utilizadores"} className={classes.main}>
                                <ListItem button  >
                                    <ListItemText primary="Utilizadores" />
                                </ListItem>
                            </Link>
                            <List>
                                <ListItem button onClick={handleClickSistema}  >
                                    <ListItemText primary="Sistema" />
                                </ListItem>
                                <Collapse in={sistema} timeout="auto" unmountOnExit >
                                    <List component="div" disablePadding>
                                        <Link to={"/systemCostumer"}>
                                            <ListItem button className={classes.nested} >
                                                <ListItemText primary="Associar artigos ao cliente" />
                                            </ListItem>
                                        </Link>
                                    </List>
                                </Collapse>
                            </List>
                        </>
                    ):null
                }
            </List>


        </>
    )
}
