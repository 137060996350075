import React, {useState} from 'react';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import {TextField} from 'unform-material-ui';
import {Form} from '@unform/web';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";
import {Accordion, AccordionDetails, AccordionSummary} from "@material-ui/core";


const useStyles = makeStyles((theme) => ({
    paper: {
        marginTop: theme.spacing(8),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    avatar: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.secondary.main,
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(3),
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    },
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: '#fff',
    },
}));

export default function NewCustomerMask({setTmp=null, newCustomer, setFromMask, setValue=null}) {
    const classes = useStyles();
    const [open, setOpen] = useState(false);

    async function criarCliente(data){
        let texto = "";
        texto += "Dados de cliente:\n" +
                 "Nome: "+data.nome+"\n" +
                 "Nif: "+data.nif+"\n"+
                 "Telefone: "+data.telefone+"\n"+
                 "Responsavel: "+data.responsavel+"\n"+
                 "\nDados morada sede: \n\n"+
                 data.sede_morada+"\n"+
                 "codigo postal:"+data.sede_codigoPostal+"\n"+
                 "Localidade:"+data.sede_localidade+"\n"+
                 "Distrito:"+data.sede_distrito+"\n"+
                 "\nDados morada loja: \n\n"+
                 data.loja_morada+"\n"+
                 "codigo postal:"+data.loja_codigoPostal+"\n"+
                 "Localidade:"+data.loja_localidade+"\n"+
                 "Distrito:"+data.loja_distrito+"\n"
        if(setTmp!==null)
            setTmp({observacoes: texto})/*.current.setFieldValue('observacoes', texto)*/
        else
            setValue(texto)
        newCustomer(false)
        setFromMask(true)
        console.log(texto)
    }

    return (
        <Container component="main">
            <CssBaseline />
            <div className={classes.paper}>
                <Form className={classes.form} onSubmit={criarCliente} noValidate>
                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                variant="outlined"
                                required
                                fullWidth
                                label="Nome do cliente"
                                name="nome"
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                variant="outlined"
                                fullWidth
                                label="Nif do cliente"
                                name="nif"
                            />
                        </Grid>
                        <Grid item xs={12} sm={12}>
                            <Accordion style={{width:'100%'}} >
                                <AccordionSummary style={{width:'100%'}} >
                                    <Typography>Morada da sede</Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <Grid container spacing={2}>
                                        <Grid item xs={12}>
                                            <TextField
                                                variant="outlined"
                                                fullWidth
                                                label="Rua"
                                                name="sede_morada"
                                            />
                                        </Grid>
                                        <Grid item xs={12} sm={6}>
                                                <TextField
                                                    variant="outlined"
                                                    fullWidth
                                                    label="Codigo postal"
                                                    name="sede_codigoPostal"
                                                />
                                            </Grid>
                                            <Grid item xs={12} sm={6}>
                                                <TextField
                                                    variant="outlined"
                                                    fullWidth
                                                    label="Localidade"
                                                    name="sede_localidade"
                                                />
                                            </Grid>
                                            <Grid item xs={12} sm={6}>
                                                <TextField
                                                    variant="outlined"
                                                    fullWidth
                                                    label="Distrito"
                                                    name="sede_distrito"
                                                />
                                            </Grid>
                                    </Grid>
                                </AccordionDetails>
                            </Accordion>
                        </Grid>
                        <Grid item xs={12} sm={12}>
                            <Accordion style={{width:'100%'}} >
                                <AccordionSummary style={{width:'100%'}} >
                                    <Typography>Morada da loja</Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <Grid container spacing={2}>
                                        <Grid item xs={12}>
                                            <TextField
                                                variant="outlined"
                                                fullWidth
                                                label="Rua"
                                                name="loja_morada"
                                            />
                                        </Grid>
                                        <Grid item xs={12} sm={6}>
                                                <TextField
                                                    variant="outlined"
                                                    fullWidth
                                                    label="Codigo postal"
                                                    name="loja_codigoPostal"
                                                />
                                            </Grid>
                                            <Grid item xs={12} sm={6}>
                                                <TextField
                                                    variant="outlined"
                                                    fullWidth
                                                    label="Localidade"
                                                    name="loja_localidade"
                                                />
                                            </Grid>
                                            <Grid item xs={12} sm={6}>
                                                <TextField
                                                    variant="outlined"
                                                    fullWidth
                                                    label="Distrito"
                                                    name="loja_distrito"
                                                />
                                            </Grid>
                                    </Grid>
                                </AccordionDetails>
                            </Accordion>
                        </Grid>
                        <Grid item xs={12} sm={12}>
                            <Accordion style={{width:'100%'}} >
                                <AccordionSummary style={{width:'100%'}} >
                                    <Typography>Contactos</Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <Grid container spacing={2}>
                                        <Grid item xs={12} sm={12}>
                                                <TextField
                                                    variant="outlined"
                                                    fullWidth
                                                    label="Email"
                                                    name="email"
                                                />
                                        </Grid>
                                        <Grid item xs={12} sm={12}>
                                            <TextField
                                                variant="outlined"
                                                fullWidth
                                                label="Nº telefone"
                                                name="telefone"
                                            />
                                        </Grid>
                                        <Grid item xs={12} sm={12}>
                                            <TextField
                                                variant="outlined"
                                                fullWidth
                                                label="Pessoa responsável"
                                                name="responsavel"
                                            />
                                        </Grid>
                                    </Grid>
                                </AccordionDetails>
                            </Accordion>
                        </Grid>
                    </Grid>
                    <Button
                        type="submit"
                        fullWidth
                        variant="contained"
                        color="primary"
                        className={classes.submit}
                    >
                        Fechar
                    </Button>
                </Form>
            </div>
            <Backdrop className={classes.backdrop} open={open} onClick={()=>{}}>
                <CircularProgress color="inherit" />
            </Backdrop>
        </Container>
    );
}
