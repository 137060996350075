import React, {useEffect, useState} from 'react';
import CssBaseline from "@material-ui/core/CssBaseline";
import Container from "@material-ui/core/Container";
import {makeStyles} from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import Paper from "@material-ui/core/Paper";
import CardContent from "@material-ui/core/CardContent";
import Grid from "@material-ui/core/Grid";
import api, {auth, url} from '../../services/api'
import Typography from "@material-ui/core/Typography";
import TableContainer from "@material-ui/core/TableContainer";
import Table from "@material-ui/core/Table";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import dayjs from 'dayjs'
import Button from "@material-ui/core/Button";
import {Link} from "react-router-dom";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import TextField from "@material-ui/core/TextField";
import DialogActions from "@material-ui/core/DialogActions";
import TableHead from "@material-ui/core/TableHead";
import TableBody from "@material-ui/core/TableBody";
import Swal from "sweetalert2";
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";

const useStyles = makeStyles((theme) => ({
    root:{
        minWidth: "75vw",
    },
    paper: {
        marginTop: theme.spacing(8),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    avatar: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.secondary.main,
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(3),
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    },
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: '#fff',
    },
    table: {
        //minWidth: "35vw",
    },
    table2: {
        maxWidth: "35vw",
    },
    select: {
        minWidth: "35vw",

    },
}));

export default function ArmazemDetalhes(props){
    const [open, setOpen] = useState(false);
    const classes = useStyles();
    const [doc, setDoc] = useState({
        fornecedor: {
        OrganizationName: ""
        },
        items:[],
    });
    const [ultimo, setUltimo] = useState(0);
    const [anterior, setAnterior] = useState(false);

    // eslint-disable-next-line
    useEffect(()=>{
        setOpen(true)
        getInfo()
        // eslint-disable-next-line
    },[props.match.params.id]);

    // eslint-disable-next-line react-hooks/exhaustive-deps
    function getInfo() {
        api.get('warehouse/'+props.match.params.id).then(res=>{
            if(res.data.code===0){
                setDoc(res.data.data)
                setOpen(false)
            }
        })

        api.get('warehouse/ultimo').then(res=>{
            if(res.data.code===0){
                setUltimo(res.data.data)
                console.log(res.data.data);
            }
        })
        if(parseInt(props.match.params.id)!==1){
            api.get('warehouse/'+(parseInt(props.match.params.id)-1)).then(res=>{
                if(res.data.code===-10){
                    setAnterior(true)
                }else{
                    setAnterior(false)
                }
            })
        }else{
            setAnterior(true)
        }

    }


    return(
        <Container component="main" maxWidth="xs">
            <CssBaseline />
            <Backdrop className={classes.backdrop} open={open} onClick={()=>{}}>
                <CircularProgress color="inherit" />
            </Backdrop>
            <div className={classes.paper}>
                <Grid container spacing={3} style={{marginBottom:10}}>
                    <Grid item xs={3} sm={3}>
                        <Link to={`/armazem/ver/`+(parseInt(props.match.params.id)-1)} onClick={(e)=>anterior===true?e.preventDefault():null}>
                            <Button
                                disabled={anterior}
                                variant="outlined"
                                color="primary"
                                className={classes.button}
                                startIcon={<ArrowBackIosIcon />}
                            />
                        </Link>
                    </Grid>
                    <Grid item xs={6} sm={6}>
                        <Typography component="h1" variant="h5">
                            Entrada. Nº {props.match.params.id}<br/>
                        </Typography>
                    </Grid>
                    <Grid item xs={3} sm={3}>
                        <Link to={`/armazem/ver/`+(parseInt(props.match.params.id)+1)} onClick={(e)=>parseInt(props.match.params.id)+1===parseInt(ultimo+1)?e.preventDefault():null}>
                            <Button
                                disabled={parseInt(props.match.params.id)+1===ultimo+1}
                                variant="contained"
                                color="primary"
                                className={classes.button}
                                endIcon={<ArrowForwardIosIcon />}
                            />
                        </Link>
                    </Grid>
                </Grid>
                <Card className={classes.root} variant="outlined">
                    <CardContent>
                        <Grid container spacing={2}>
                            <Grid item xs={12} sm={6}>
                                <Typography variant="h5" component="p">
                                    Fornecedor: {doc.fornecedor.OrganizationName}
                                </Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <TableContainer component={Paper}>
                                    <Table className={classes.table} aria-label="Artigos do registo">
                                        <TableHead>
                                            <TableRow>
                                               <TableCell>Codigo artigo</TableCell>
                                                <TableCell>Descrição</TableCell>
                                                <TableCell>Quantidade</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {doc.items.map((row, index) =>
                                                <TableRow key={index}>
                                                    <TableCell>{row.info_artigos.ItemID}</TableCell>
                                                    <TableCell>{row.info_artigos.Description}</TableCell>
                                                    <TableCell>{Number(row.quantidade).toFixed(row.info_artigos.unidade.MaximumDecimals)}</TableCell>
                                                </TableRow>
                                            )}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </Grid>
                            <Grid item xs={12} sm={12}>
                                <Link to={"/armazem/editar/"+props.match.params.id}><Button variant="outlined" color="primary" fullWidth={true}>Editar</Button></Link>
                            </Grid>
                            <Grid item xs={12} sm={12}>
                                <a target={"_blank"} rel="noopener noreferrer" href={url+"pdf/docArmazem/"+props.match.params.id+"/"+auth()}><Button variant="contained" color="primary" fullWidth={true}>Obter registo</Button></a>
                            </Grid>
                        </Grid>
                    </CardContent>
                </Card>
            </div>
        </Container>
    )
}
