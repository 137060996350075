import React, {useEffect} from 'react';
import clsx from 'clsx';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import CssBaseline from '@material-ui/core/CssBaseline';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import Button from "@material-ui/core/Button";
import MenuItem from '@material-ui/core/MenuItem';
import {logout, nome} from "../services/auth";
import {useHistory} from "react-router-dom";
import MenuList from "./MenuList"
import Menu from "@material-ui/core/Menu"
import bk from "../images/bk.png"
import Swal from "sweetalert2";
const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
    },
    appBar: {
        transition: theme.transitions.create(['margin', 'width'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
    },
    appBarShift: {
        width: `calc(100% - ${drawerWidth}px)`,
        marginLeft: drawerWidth,
        transition: theme.transitions.create(['margin', 'width'], {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    menuButton: {
        marginRight: theme.spacing(2),
        //color: "rgb(183, 121, 69)"
    },
    hide: {
        display: 'none',
    },
    drawer: {
        width: drawerWidth,
        flexShrink: 0,
    },
    drawerPaper: {
        width: drawerWidth,
        //backgroundColor:"rgb(17,17,17)",
        height: '100vh',
        //color:"#fff"

    },
    drawerHeader: {
        display: 'flex',
        alignItems: 'center',
        padding: theme.spacing(0, 1),
        // necessary for content to be below app bar
        ...theme.mixins.toolbar,
        justifyContent: 'flex-end',
    },
    content: {
        flexGrow: 1,
        padding: theme.spacing(3),
        transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        marginLeft: -drawerWidth,
    },
    contentShift: {
        transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen,
        }),
        marginLeft: 0,
    },
    title: {
        flexGrow: 1,
        //color:"rgb(183, 121, 69)"
    },
    nested: {
        paddingLeft: theme.spacing(4),
    },
}));

export default function Template(props) {

    const classes = useStyles();
    const theme = useTheme();
    const history = useHistory();

    const [openMenu, setOpenMenu] = React.useState(false);
    const [anchorEl, setAnchorEl] = React.useState(null);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };


    const handleDrawerOpen = () => {
        setOpenMenu(true);
    };

    const handleDrawerClose = () => {
        setOpenMenu(false);
    };


    return (
        <div className={classes.root}>
            <CssBaseline />
            <AppBar

                color={"primary"}
                position="fixed"
                className={clsx(classes.appBar, {
                    [classes.appBarShift]: openMenu,
                })}
            >
                <Toolbar>
                    <IconButton
                        color="inherit"
                        aria-label="open drawer"
                        onClick={handleDrawerOpen}
                        edge="start"
                        className={clsx(classes.menuButton, openMenu && classes.hide)}
                    >
                        <MenuIcon />
                    </IconButton>
                    <Typography className={classes.title}  variant="h6" noWrap>
                        Moorei
                    </Typography>
                    <Button variant="contained" color="primary" ria-haspopup="true" onClick={handleClick}>
                        {nome()}
                    </Button>
                    <Menu
                        id="simple-menu"
                        anchorEl={anchorEl}
                        keepMounted
                        open={Boolean(anchorEl)}
                        onClose={handleClose}
                    >
                        <MenuItem onClick={()=>{
                            logout()
                            handleClose()
                            history.push("/")

                        }}>Terminar sessão</MenuItem>
                    </Menu>
                </Toolbar>
            </AppBar>
            <Drawer
                className={classes.drawer}
                variant="persistent"
                anchor="left"
                open={openMenu}
                classes={{
                    paper: classes.drawerPaper,
                }}
            >
                <div className={classes.drawerHeader}>
                    <IconButton onClick={handleDrawerClose}>
                        {theme.direction === 'ltr' ? <ChevronLeftIcon /> : <ChevronRightIcon />}
                    </IconButton>
                </div>
                <MenuList/>
            </Drawer>
            <main
                className={clsx(classes.content, {
                    [classes.contentShift]: openMenu,
                })}

            >
                <div className={classes.drawerHeader}/>

                <div style={{position: 'relative', zIndex: 1,}}>
                    {props.children}
                    {(props.children.props.location.pathname!=="/dashboard" && props.children.props.location.pathname!=="/documentos" && props.children.props.location.pathname!=="/documentos/teclado" && props.children.props.location.pathname!=="/documentos/lista")?(
                        <center >
                            <Button onClick={() => history.goBack()} type="button" color="default">Voltar</Button>
                        </center>
                    ):null}
                    {(props.children.props.location.pathname==="/documentos" || props.children.props.location.pathname==="/documentos/teclado"|| props.children.props.location.pathname==="/documentos/lista")?(
                        <center >
                            <Button onClick={() => {
                                Swal.fire({
                                    title: 'Deseja sair da encomenda?',
                                    showDenyButton: true,
                                    confirmButtonText: `Sim`,
                                    denyButtonText: `Não`,
                                }).then((result) => {
                                    if (result.isConfirmed) {
                                        history.goBack()
                                    }
                                })
                                }
                            } type="button" color="default">Sair</Button>
                        </center>
                    ):null}
                </div>
            </main>
        </div>
    );
}

