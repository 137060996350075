import React, {useEffect, useState} from 'react';
import CssBaseline from "@material-ui/core/CssBaseline";
import Container from "@material-ui/core/Container";
import {makeStyles} from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import Paper from "@material-ui/core/Paper";
import CardContent from "@material-ui/core/CardContent";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import api from '../../services/api'
import Select from "react-select";
import Typography from "@material-ui/core/Typography";
import TableContainer from "@material-ui/core/TableContainer";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogActions from "@material-ui/core/DialogActions";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Switch from "@material-ui/core/Switch";
import DeleteIcon from '@material-ui/icons/Delete';
import TableBody from "@material-ui/core/TableBody";
import Swal from "sweetalert2";
import {useHistory} from "react-router-dom";
import TextareaAutosize from "@material-ui/core/TextareaAutosize";
import NoteIcon from '@material-ui/icons/Note';
import {useMediaQuery, useTheme} from "@material-ui/core";



const useStyles = makeStyles((theme) => ({
    root:{
        minWidth: "75vw",
    },
    paper: {
        marginTop: theme.spacing(8),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    avatar: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.secondary.main,
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(3),
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    },
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: '#fff',
    },
    table: {
        //minWidth: "35vw",
    },
    table2: {
        maxWidth: "35vw",
    },
    select: {
        minWidth: "35vw",
    }
}));

export default function DocumentosEditar(props){
    const classes = useStyles();
    const [artigos, setArtigos] = useState([]);
    const [tara, setTara] = useState({value:1, label:"Vendida"});
    const [infoArtigo, setInfoArtigo] = useState({});
    const [artigo, setArtigo] = useState([]);
    const [totalDoc, setTotalDoc] = useState(0.00);
    const [pagamento, setPagamento] = useState(false);
    const [edit, setEdit] = useState(false);
    const history = useHistory();
    const [documento, setDocumento] = useState({});
    const [observacoes, setObs]=useState(false);
    const [obsData,setObsData]=useState("");
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
    const [clientes, setClientes] = useState([]);

    useEffect(()=>{
        api.get('/documentos/'+props.match.params.id).then(res=>{
            if(res.data.code===0){
                setDocumento(res.data.data);
                setTotalDoc(parseFloat(res.data.data.docValue))
                setObsData(res.data.data.Obs)
            }
        })
        api.get('/item/list').then(res=>{
            if(res.data.code===0){
                setArtigos(res.data.data)
            }
        })

        api.get('/cliente/list').then(res=>{
            if(res.data.code===0){
                setClientes(res.data.data)
            }
        })
    },[props.match.params.id]);

    function defenirLinhas() {
        const temp = documento;
        let total=0.00;
        temp.detalhes.push({
            item_info:infoArtigo,
            preco:infoArtigo.preco,
            quantidade: Number(infoArtigo.quantidade).toFixed(parseInt(infoArtigo.MaximumDecimals)),
            obs:infoArtigo.obs,
            desconto:infoArtigo.desconto
        })

        for (let row of temp.detalhes) {
            if(!isNaN(parseFloat(row.preco))) {
                total += calculatePrice(row.quantidade, row.preco, row.desconto)
            }else{
                total+=0
            }
        }

        setTotalDoc(total);
        setInfoArtigo({obs:"",preco: Number().toFixed(2),precos:[0,0,0],quantidade:Number().toFixed(parseInt('0'))})
        setArtigo({})

        setDocumento(temp)
    }

    function calculatePrice(quantidade, preco, desconto=0){
        const precoFinal = parseFloat(quantidade)*parseFloat(preco);
        return precoFinal - (precoFinal*(parseFloat(desconto)/100))
    }

    function getCurrentPrice(item){
        if(item.prices.length !== 0){
            if(typeof item!=="undefined"){
                if(item.Description!==""){
                    if(documento.costumer.OrganizationName!=="" && documento.costumer.OrganizationName!==undefined){
                        let price = item.prices.filter(pr=>documento.costumer.PriceLineID==pr.price_line.id)
                        return price.length!==0?price[0].TaxIncludedPrice:item.defaultPrice.TaxIncludedPrice;
                    }else{
                        return item.defaultPrice.TaxIncludedPrice;
                    }
                }else{
                    return 0;
                }
            }
        }else{
            return 0
        }
    }

    function defineArtigo(data) {
        data.preco=getCurrentPrice(data)
        setArtigo(data)
        data.quantidade = 0;
        data.desconto = 0;
        setInfoArtigo(data);
        setTara({value:1, label:"Vendida"});
    }

    function definePreco(e) {
        if(e.target.value>=0) {
            setInfoArtigo({
                ...infoArtigo,
                preco: e.target.value
            })
        }
    }

    function defineDesconto(e) {
        if(!isNaN(Number(e.target.value))){
            if(Number(e.target.value)>=0 && Number(e.target.value)<=100) {
                setInfoArtigo({
                    ...infoArtigo,
                    desconto:e.target.value
                })
            }
        }
    }

    function defineQuantidade(e) {
        if(e.target.value>=0){
            setInfoArtigo({
                ...infoArtigo,
                quantidade: e.target.value
            })
        }
    }

    function alteraTara(data) {
        if(data.value!==1){
            setInfoArtigo({preco: 0.00})

        }
        setTara(data)
    }

    function guardarDoc() {
    documento.Obs = obsData
        api.put('/documentos/'+props.match.params.id, documento).then(async res=>{
            setPagamento(false)
            await Swal.fire({
                title: "Informação",
                text: res.data.message,
                icon: res.data.code === 0 ? "success" : "error",
                timer: 3000
            })
            if(res.data.code === 0) {
                history.push("/documentos/ver/"+props.match.params.id)
            }
        })
    }

    function editarLinha(idx) {
        // eslint-disable-next-line array-callback-return
        const newTmp=documento.detalhes.filter((value, index) => {
            if(index===idx){
                setInfoArtigo({
                    ...value.item_info,
                    preco: value.preco,
                    quantidade: value.quantidade
                })
            }else{
                return value;
            }
        })
        let total=0.00;
        for(let row of newTmp){
            total += calculatePrice(row.quantidade, row.preco, row.desconto)
        }
        setTotalDoc(total)
        let temp = documento
        temp.detalhes = newTmp
        setDocumento(temp);
    }

    function defineContaCorrente(data) {
        setDocumento({
            ...documento,
            customer:data
        })
    }

    function removerLinha(idx) {
        // eslint-disable-next-line array-callback-return
        const newTmp=documento.detalhes.filter((value, index) => {
            if (index !== idx) {
                return value;
            }
        })
        let total=0.00;
        for(let row of newTmp){
            total += calculatePrice(row.quantidade, row.preco, row.desconto)
        }
        setTotalDoc(total)
        let temp = documento
        temp.detalhes = newTmp
        setDocumento(temp);

    }

    return(
        <Container component="main" maxWidth="xs">
            <CssBaseline />
            <div className={classes.paper}>
                <Typography component="h1" variant="h5">
                    Editar encomenda <br/>
                </Typography>
                <Card className={classes.root} variant="outlined">
                    <CardContent>
                        <Grid container spacing={2}>
                            <Grid item xs={12} sm={6}>
                                <Select
                                    onChange={defineArtigo}
                                    name="artigos"
                                    option={artigo}
                                    placeholder="Artigos"
                                    options={artigos}
                                    getOptionLabel={(option)=> {return option.ItemID + "  /  " + option.Description}}
                                    getOptionValue ={(option)=>option.id}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                {documento.costumer!==undefined?(
                                    <Select
                                        onChange={defineContaCorrente}
                                        name="clientes"
                                        placeholder="Clientes"
                                        options={clientes}
                                        defaultValue={documento.costumer}
                                        getOptionLabel={(option)=> option.OrganizationName}
                                        getOptionValue ={(option)=>option.id}
                                    />
                                ):null}

                            </Grid>
                            <Grid item xs={12} sm={12}>
                                 <br/>

                                <TableContainer component={Paper} style={{marginBottom: 10, marginTop: 20}}>
                                    <Table className={classes.table} aria-label="Descricao do artigo">
                                        <TableHead>
                                            <TableRow>
                                                <TableCell>Código artigo</TableCell>
                                                <TableCell>Descrição</TableCell>
                                                <TableCell>Quantidade</TableCell>
                                                <TableCell>Preço</TableCell>
                                                <TableCell>Desconto</TableCell>
                                                <TableCell>Unidade</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            <TableRow >
                                                <TableCell>{infoArtigo.ItemID}</TableCell>
                                                <TableCell>{infoArtigo.Description}</TableCell>
                                                <TableCell>
                                                    <TextField name="quantidade" onChange={defineQuantidade} type="number"  value={infoArtigo.quantidade} onKeyPress={(e) => {
                                                        if(e.key==='Enter'){
                                                            if(!(infoArtigo.ItemID === undefined || parseFloat(infoArtigo.quantidade) <= 0 || infoArtigo.quantidade===undefined)){
                                                                defenirLinhas()
                                                            }
                                                        }
                                                    }}
                                                    /></TableCell>
                                                <TableCell><TextField name="preco" disabled={tara.value!==1} onChange={definePreco} type="number" value={infoArtigo.preco || ""}/></TableCell>
                                                <TableCell><TextField name="desconto" onChange={defineDesconto} type="number" value={infoArtigo.desconto}/></TableCell>
                                                <TableCell>{infoArtigo.unidade!==undefined?infoArtigo.unidade.UnitOfMeasureID:""}</TableCell>
                                            </TableRow>
                                        </TableBody>
                                    </Table>
                                    <TextField
                                        label={"Observações..."}
                                        style={{minHeight: '50px',width:'100%'}}
                                        multiline
                                        variant={"outlined"}
                                        maxRows={4}
                                        value={infoArtigo.obs}
                                        onChange={(e)=>{
                                            let artigo = infoArtigo
                                            console.log(e.target.value)
                                            artigo.obs=e.target.value
                                            console.warn(artigo.obs)
                                            setInfoArtigo(artigo)
                                        }}
                                    />
                                </TableContainer>
{/*                                {infoArtigo.tara==1?(
                                    <Select style={{marginTop: 20, marginLeft: 20}} value={tara} option={{value:1, label:"Vendida"}} onChange={alteraTara} options={[{value:1, label:"Vendida"},{value:2, label:"Emprestada"},{value:3, label:"Oferta"} ]} variant="outlined" name="tara" placeholder="Tara"/>):null}*/}
                                <Button onClick={defenirLinhas} disabled={infoArtigo.artigo === "" || parseFloat(infoArtigo.quantidade) <= 0} fullWidth style={{marginTop: 20}} variant="contained" color="primary">Adicionar artigo</Button>
                            </Grid>
                            <Grid item xs={12}>
                                <TableContainer component={Paper}>
                                    <Table className={classes.table} aria-label="Artigos do documento">
                                        <TableHead>
                                            <TableRow>
                                                <TableCell>Codigo artigo</TableCell>
                                                <TableCell>Descrição</TableCell>
                                                {edit===false?(<TableCell>Quantidade</TableCell>):(<TableCell colSpan={3}><center>Ações</center></TableCell>)}
                                                {edit===false?(<TableCell>Preço</TableCell>):(null)}
                                                {edit===false?<TableCell >Desconto</TableCell>:(null)}
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>{documento.detalhes!==undefined?
                                            documento.detalhes.map((row, index) =>
                                                <>
                                                    <TableRow key={index}>
                                                        <TableCell>{row.item_info.ItemID}</TableCell>
                                                        <TableCell>{row.item_info.Description}</TableCell>
                                                        {edit===false?<TableCell>{row.quantidade}</TableCell>:null}
                                                        <TableCell>{isNaN(parseFloat(row.preco))?"0.00":parseFloat(row.preco).toFixed(2)}€/{row.item_info.unidade.UnitOfMeasureID}</TableCell>
                                                        {edit===false?(<TableCell>{row.desconto}%</TableCell>):(<TableCell><Button onClick={()=>editarLinha(index)} variant="outlined" color="primary">Editar linha</Button></TableCell>)}
                                                        {edit===false?null:(<TableCell><Button variant="contained" color="secondary" className={classes.button} startIcon={<DeleteIcon />} onClick={() =>removerLinha(index)}>Remover linha</Button></TableCell>)}
                                                    </TableRow>
                                                    {
                                                        row.obs!==""?(
                                                            <TableRow>
                                                                <TableCell/>
                                                                <TableCell>{row.obs}</TableCell>
                                                            </TableRow>
                                                        ):null
                                                    }
                                                </>
                                            ):null}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                                <Button onClick={()=>setPagamento(true)} disabled={documento.linhas !==undefined  } fullWidth style={{marginTop: 20}} variant="contained" color="primary">Guardar encomenda</Button>
                                <FormControlLabel
                                    control={
                                        <Switch
                                            checked={edit}
                                            onChange={()=>setEdit(!edit)}
                                            name="checkedB"
                                            color="primary"
                                        />
                                    }
                                    label="Editar documento"
                                />
                                <Button onClick={()=>setObs(true)} type="button" color="primary" variant="outlined"><NoteIcon/> </Button>
                            </Grid>
                        </Grid>
                    </CardContent>
                </Card>
            </div>
            <Dialog
                open={pagamento}
                onClose={()=>setPagamento(false)}
                aria-labelledby="responsive-dialog-title"
            >
                <DialogTitle id="responsive-dialog-title">{"Guardar documento?"}</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Deseja guardar as alterações?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button  onClick={()=>setPagamento(false)} color="primary">
                        Cancelar ação
                    </Button>
                    <Button onClick={()=>guardarDoc()} color="primary" autoFocus>
                        Guardar alterações
                    </Button>
                </DialogActions>
            </Dialog>
            <Dialog
                open={observacoes}
                onClose={()=>setObs(false)}
                aria-labelledby="responsive-dialog-title"
            >
                <DialogTitle id="responsive-dialog-title">{"Observações"}</DialogTitle>
                <DialogContent>
                    <Grid container spacing={2} style={{height:'400px',minWidth:'500px'}}>
                        <Grid item xs={12} sm={12}>
                            <TextareaAutosize style={{minHeight: '100px',width:'100%'}} aria-label="minimum height" rowsMin={3} placeholder="Observações..." name="observacoes" value={obsData} onChange={(e)=>setObsData(e.target.value)} />
                        </Grid>
                    </Grid>
                </DialogContent>
            </Dialog>
        </Container>
    )
}
