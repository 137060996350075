import React, {useState, useEffect, useRef} from 'react';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import {Select, TextField} from 'unform-material-ui';
import {Form} from '@unform/web';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import api from "../../services/api";
import Collapse from "@material-ui/core/Collapse";
import Alert from "@material-ui/lab/Alert";
import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from '@material-ui/core/CircularProgress';
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import MenuItem from "@material-ui/core/MenuItem";
import TransferList from "../../components/TransferList";



const useStyles = makeStyles((theme) => ({
    paper: {
        //marginTop: theme.spacing(8),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    avatar: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.secondary.main,
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(3),
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    },
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: '#fff',
    },
}));

export default function ClienteSetItems(props) {
    const classes = useStyles();
    const [infoCustomer, setInfoCustomer] = useState({
        items:null,
        id:0
    })
    const formRef= useRef(null)

    useEffect(() =>{
        api.get('/cliente/'+props.match.params.id).then(res=> {
            setInfoCustomer(res.data.data)
        })
    }, [props.match.params.id])



    return (
        <Container component="main" maxWidth="md" style={{alignItems:'center'}}>
            <CssBaseline />
            <div className={classes.paper}>
                <Typography component="h3" variant="p">
                    Cliente: {infoCustomer.OrganizationName}
                </Typography>
                <center>
                    {infoCustomer.items!==null?<TransferList artigosCliente={infoCustomer.items} customerID={infoCustomer.id}/>:null}
                </center>
            </div>

        </Container>
    );
}
