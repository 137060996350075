import React, {useEffect, useState} from 'react';
import CssBaseline from "@material-ui/core/CssBaseline";
import Container from "@material-ui/core/Container";
import {makeStyles} from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Grid from "@material-ui/core/Grid";
import api, {auth, url} from '../../services/api'
import Select from "react-select";
import Typography from "@material-ui/core/Typography";
import TableContainer from "@material-ui/core/TableContainer";
import Table from "@material-ui/core/Table";
import Divider from "@material-ui/core/Divider";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogActions from "@material-ui/core/DialogActions";
import DeleteIcon from '@material-ui/icons/Delete';
import TableBody from "@material-ui/core/TableBody";
import {useHistory} from "react-router-dom"
import Swal from "sweetalert2";
import CardHeader from "@material-ui/core/CardHeader";
import GridList from "@material-ui/core/GridList";
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import {
    Backdrop,
    Badge,
    Box,
    CircularProgress,
    Collapse, FormControlLabel,
    IconButton,
    ListItem,
    ListItemText,
    ListSubheader, MenuItem, Select as MuiSelect, Switch,
    useTheme
} from "@material-ui/core";
import EuroIcon from '@material-ui/icons/Euro';
import PersonIcon from '@material-ui/icons/Person';
import {useRecoilState} from "recoil";
import {EstadoReader, InfoReader} from "../../atoms";
import {detectWebcam} from "../../services/functions";
import SpeedDialIcon from '@material-ui/lab/SpeedDialIcon';
import CodeReader from "../../components/CodeReader";
import {Alert, SpeedDial, SpeedDialAction} from "@material-ui/lab";
import TextareaAutosize from "@material-ui/core/TextareaAutosize";
import NoteIcon from '@material-ui/icons/Note';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import FlatList from 'flatlist-react';
import List from "@material-ui/core/List";
import moment from "moment";
import {History} from "@material-ui/icons";
import CloseIcon from "@material-ui/icons/Close";
import TextField from "@material-ui/core/TextField";
import SearchIcon from '@material-ui/icons/Search';
import PersonAddIcon from "@material-ui/icons/PersonAdd";
import NewCustomerMask from "../../components/NewCustomerMask";

const useStyles = makeStyles((theme) => ({
    root:{
      minWidth: "75vw",
    },
    paper: {
        marginTop: theme.spacing(1),
        marginLeft: theme.spacing(0),
        marginRight: theme.spacing(0),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    avatar: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.secondary.main,
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(3),
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    },
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: '#fff',
    },
    table: {
        //minWidth: "35vw",
    },
    table2: {
        maxWidth: "35vw",
    },
    select: {
        minWidth: "20vw",

    },
    speedDial: {
        position: 'fixed',
        bottom: theme.spacing(2),
        right: theme.spacing(2),
    },
    closeBtn:{
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[500],
    }
}));

export default function DocumentosCriarHistorico(props){
    const classes = useStyles();
    const [clientes, setClientes] = useState([]);
    const [artigos, setArtigos] = useState([]);
    const [tara, setTara] = useState({value:1, label:"Vendida"});
    const [contaCorrente, setContaCorrente] = useState(0);
    const [linhasArtigo, setLinhasArtigo] = useState([]);
    const [familias, setFamilias] = useState([]);
    const [artigo, setArtigo] = useState([]);
    const [idFamilia, setIdFamilia] = useState("");
    const [parent, setParent] = useState({isFather: false, familyID: 0, Description: ""});
    const [cliente, setCliente] = useState({
        OrganizationName:"",
        cc: 0,
        price_line_details:{ShortDescription:"",
        FederalTaxID: ""}
    });
    const [listaArtigos, setListaArtigos] = useState([]);
    const [totalDoc, setTotalDoc] = useState(0.00);
    const [pagamento, setPagamento] = useState(false);
    const [defenirQtd, setDefenirQtd] = useState(false);
    const [defenirPreco, setDefenirPreco] = useState(false);
    const [defenirDesconto, setDefenirDesconto] = useState(false);
    const [back, setBack] = useState(true);
    const [quantidade, setQuantidade] = useState("");
    const [clienteDefenido, setDefineCliente] = useState(false);
    const [indicarCliente, setIndicarCliente] = useState(true);
    const [edit, setEdit] = useState(9999);
    const [preco, setPreco] = useState(0);
    const [desconto, setDesconto] = useState(0);
    const history = useHistory();
    const [infoArtigo, setInfoArtigo] = useState({});
    const [artigoReader, setArtigoReader] = useRecoilState(InfoReader);
    const [webCam, setWebCam]=useState(false)
    const [openCodeBarReader,setOpenCodeBarReader]=useRecoilState(EstadoReader)
    const [find, setFind]=useState(null);
    const [observacoes, setObs]=useState(false);
    const [obsData,setObsData]=useState("");
    const [agrupamento,setAgrupamento]=useState(false);
    const [loading,setLoading]=useState(false);

    const [showLastOrders,setShowLastOrder]=useState(false);
    const [itemSearch,setItemSearch]=useState(false);
    const [newCustomer,setNewCustomer]=useState(false);
    const [lastOrders,setLastOrders]=useState([]);
    const [acoes, setAcoes] = useState(false)
    const [local, setLocal] = useState("presencial");

    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
    var send = false;

    function calculatePrice(quantidade, preco, desconto=0){
        const precoFinal = parseFloat(quantidade)*parseFloat(preco);
        return precoFinal - (precoFinal*(parseFloat(desconto)/100))
    }

/*    useEffect(()=>{
        if(back){
            setParent({isFather: false, familyID: 0, Description: ""})
            setBack(false)
            setIdFamilia(0)
            detectWebcam((hasWebCam)=>setWebCam(hasWebCam))


            api.get('/familia/parent/55').then(res=>{
                if(res.data.code===0){
                    setFamilias(res.data.data)
                }
            })
        }
    },[back]);*/

    useEffect(()=>{
        setLoading(true)
        api.get('/cliente/list').then(res=>{
            if(res.data.code===0){
                setClientes(res.data.data)
                setLoading(false)
            }
        })
        api.get('/item/list').then(res=>{
            if(res.data.code===0){
                setListaArtigos(res.data.data)
            }
        })
    },[])

    useEffect(()=>{
        if(artigoReader!==null) {
            api.get('/item/barcode/' + artigoReader).then(res => {
                if (res.data.data !== null) {
                    defineArtigo(res.data.data)
                }else{
                    setFind("Nenhum artigo encontrado")
                }
            })
        }
        //setInfoArtigo(row)
        return 0
    }, [artigoReader])



    useEffect(()=>{
        if(idFamilia!==""){
            if(parent.isFather!==true){
                api.get('/item/familia/'+idFamilia).then(res=>{
                    if(res.data.code===0){
                        setArtigos(res.data.data)
                    }
                })
            }else{
                api.get('/familia/parent/'+parent.familyID).then(res=>{
                    if(res.data.code===0){
                        setFamilias(res.data.data)
                    }
                })
            }
        }
    }, [idFamilia])

    useEffect(()=>{
        if(cliente.id!==undefined) {
            api.get('/documentos/historico/sales/' + cliente.id).then(res => {
                setLastOrders(res.data)
            })
        }
    }, [cliente])

    function setQtd(value, qtd){
            if(value!=="."){
                if(value!=="apagar"){
                    setQuantidade(qtd + value);
                }else{
                    setQuantidade(qtd.slice(0, -1));
                }
            }else {
                if (infoArtigo.unidade.MaximumDecimals != 0) {
                    if (qtd.indexOf('.') === -1) {
                        if (qtd !== "") {
                            setQuantidade(qtd + value);
                        } else {
                            setQuantidade(0 + value)
                        }
                    }
                }
            }
        if(qtd.indexOf('.') !== -1){
            if(value!=="apagar") {
                const slug = (qtd + value).split('.').pop().length;
                if(parseInt(infoArtigo.MaximumDecimals)<parseInt(slug)){
                    setQuantidade(qtd);
                }
            }
        }

    }

    function setPrice(value, preco){
        if(value!=="apagar"){
            if(preco!==undefined && preco!==""){
                if(value!=='.'){
                    setPreco(preco+value)
                }else{
                    if(preco.toString().indexOf('.') === -1){
                        setPreco(preco+value)
                    }
                }
            }else{
                if(value!=='.'){
                    setPreco(value)
                }else{
                    setPreco(0+".")
                }
            }
        }else{
            setPreco(preco.toString().slice(0, -1))
        }
        if(typeof preco === "string"){
            if(preco.toString().indexOf('.') !== -1){
                if(value!=="apagar") {
                    const slug = (preco + value).split('.').pop().length;
                    if(2<parseInt(slug)){
                        setPreco(preco);
                    }
                }
            }
        }

    }

    function setDiscount(value, desconto){
        if(value!=="apagar"){
            if(desconto!==undefined && desconto!=="" && parseFloat(desconto+value)<=100){
                if(value!=='.'){
                    setDesconto(desconto+value)
                }else{
                    if(desconto.toString().indexOf('.') === -1){
                        setDesconto(desconto+value)
                    }
                }
            }else{
                if(value!=='.'){
                    setDesconto(value)
                }else{
                    setDesconto(0+".")
                }
            }
        }else{
            setDesconto(desconto.toString().slice(0, -1))
        }
        if(typeof desconto === "string"){
            if(desconto.toString().indexOf('.') !== -1){
                if(value!=="apagar") {
                    const slug = (desconto + value).split('.').pop().length;
                    if(2<parseInt(slug)){
                        setDesconto(desconto);
                    }
                }
            }
        }

    }

/*    useEffect(()=>{
        if(cliente.id!==0){
            console.log(infoArtigo)
            const temp = infoArtigo;
            temp.preco=infoArtigo.precos[cliente.linhaPreco-1]
            setInfoArtigo(temp);
        }
    }, [cliente, infoArtigo])*/

    function defenirLinhas(quant) {
        let temp = linhasArtigo;
        let total=0.00;
        infoArtigo.tipo=tara.value;
        if(Number(infoArtigo.preco)===0){
            setPreco("");
        }else{
            setPreco(infoArtigo.preco);
        }
        setDesconto(0)
        let tmpInfo = infoArtigo;
        tmpInfo.quantidade=Number(quant).toFixed(parseInt(tmpInfo.unidade.MaximumDecimals))
        //temp.push(tmpInfo)

        if(tmpInfo.preco==0){
            tmpInfo.preco="";
            //alterarPreco(temp.length-1);
            const newTmp=temp.filter((value, index) => {
                if(index===temp.length -1){
                    if(Number(value.preco)==0){
                        value.preco=""
                    }
                    setInfoArtigo(value);
                    const listaTara=[{value:1, label:"Vendida"},{value:2, label:"Emprestada"},{value:3, label:"Oferta"} ];
                    if(value.tipo!==undefined){
                        setTara(listaTara[value.tipo-1])
                    }else{
                        setTara(listaTara[0])
                    }

                }else{
                    return value;
                }
            })
            let total=0.00;
            for(let row of newTmp){
                total+=calculatePrice(row.quantidade, row.preco, row.desconto)
            }
            setTotalDoc(total)
            //setLinhasArtigo(newTmp);
            setDefenirPreco(true)
            temp=newTmp;
        }else{
            setInfoArtigo({

            })
        }

        for (let row of temp) {
            if(!isNaN(parseFloat(row.preco))) {
                total+=calculatePrice(row.quantidade, row.preco, row.desconto)
            }else{
                total+=0
            }
        }

        setTotalDoc(total);
        setLinhasArtigo([...temp,{
            BarCode:tmpInfo.BarCode,
            Description:tmpInfo.Description,
            Discontinued:tmpInfo.Discontinued,
            DtAlt:tmpInfo.DtAlt,
            FamilyID:tmpInfo.FamilyID,
            HrAlt:tmpInfo.HrAlt,
            ItemID:tmpInfo.ItemID,
            ShortDescription:tmpInfo.ShortDescription,
            UnitID:tmpInfo.UnitID,
            created_at:tmpInfo.created_at,
            defaultPrice:tmpInfo.defaultPrice,
            family:tmpInfo.family,
            id:tmpInfo.id,
            preco:tmpInfo.preco,
            prices:tmpInfo.prices,
            quantidade:tmpInfo.quantidade,
            tipo:tmpInfo.tipo,
            unidade:tmpInfo.unidade,
            updated_at:tmpInfo.updated_at,
        }])
    }

    function defineContaCorrente(data) {
        setIndicarCliente(false)
        setContaCorrente(data.cc)
        setCliente(data)
        setDefineCliente(true)
        if(infoArtigo!==undefined) {
            const tmp = infoArtigo;
            tmp.preco = getCurrentPrice(data, infoArtigo)
            setInfoArtigo(tmp)

        }
    }

    function getCurrentPrice(customer, item){
        if(typeof item!=="undefined"){
            if(item.ShortDescription!==undefined){
                if(item.prices.length!==0){
                    if(customer.OrganizationName!==""){
                        let price = item.prices.filter(pr=>customer.PriceLineID==pr.price_line.id)
                        if(price.length!==0){
                            return price[0].UnitPrice;
                        }else{
                            if(item.defaultPrice!==null){
                                console.log(item.defaultPrice)
                                return item.defaultPrice.UnitPrice
                            }else{
                                return  0
                            }

                        }
                    }else{
                        if(item.defaultPrice!==null){
                            console.log(item.defaultPrice)
                            return item.defaultPrice.UnitPrice
                        }else{
                            return  0
                        }
                    }
                }else{
                    return  0
                }

            }else{
                return 0;
            }
        }else{
            return 0
        }


    }

    function defineArtigo(data) {
        setItemSearch(false)
        setQuantidade("");
        setArtigo(data);
        setDefenirQtd(true);
        data.quantidade = "";
        if(cliente.OrganizationName===""){
            if(data.defaultPrice===null){
                data.preco=0;
            }else{
                data.preco=data.defaultPrice.UnitPrice;


            }
        }else{
            data.preco=getCurrentPrice(cliente, data)
        }
        setInfoArtigo(data);
        setTara({value:1, label:"Vendida"});
    }

    function definePreco(value) {
        if(!isNaN(Number(value))){
            if(Number(value)>=0) {
                const temp = linhasArtigo;
                let total=0.00;
                let infoTmp = infoArtigo;
                infoTmp.preco=value
                temp.tipo=tara.value;
                temp.push(infoTmp);
                for (let row of temp) {
                    if(!isNaN(parseFloat(row.preco))) {

                        total+=calculatePrice(row.quantidade, row.preco, row.desconto)
                    }else{
                        total+=0
                    }
                }
                setTotalDoc(total);
                setInfoArtigo();
                setArtigo({});
                setLinhasArtigo(temp);
                setDefenirPreco(false);
            }
        }
    }

    function defineDesconto(value) {
        if(!isNaN(Number(value))){
            if(Number(value)>=0 && Number(value)<=100) {
                const temp = linhasArtigo;
                let total=0.00;
                let infoTmp = infoArtigo;
                infoTmp.desconto=value
                temp.tipo=tara.value;
                temp.push(infoTmp);
                for (let row of temp) {
                    if(!isNaN(parseFloat(row.preco))) {

                        total+=calculatePrice(row.quantidade, row.preco, row.desconto)
                    }else{
                        total+=0
                    }
                }
                setTotalDoc(total);
                setDesconto(0)
                setInfoArtigo();
                setArtigo({});
                setLinhasArtigo(temp);
                setDefenirDesconto(false);
            }
        }
    }

    function defineQuantidade(value) {
        if(value>=0){
            let tmp = infoArtigo;
            tmp.quantidade=value
            setInfoArtigo(tmp)
            defenirLinhas(value);
            setDefenirQtd(false)
        }
    }

    function alteraTara(data) {
        if(data.value!==1){
            let tmp = infoArtigo;
            tmp.preco=0
            setInfoArtigo(tmp)
        }
        setTara(data)
    }

    async function guardarDoc() {
        if(!send){
            send = true
            const data={};
            data.cliente=cliente.id;
            data.linhas=linhasArtigo;
            data.Obs = obsData
            data.local=local

            const res =await api.post('/documentos', data);
            setPagamento(false)
            if(res.data.code===0){
                await Swal.fire({
                    title: "Informação",
                    text: res.data.message,
                    icon:"success",
                    showDenyButton: true,
                    confirmButtonText:"Obter documento",
                    denyButtonText:"Fechar",
                }).then(r=>{
                    if(r.isConfirmed){
                        send=false
                        window.open(url+"pdf/docVenda/"+res.data.data+"/"+auth(), '_blank')
                        history.push("/documentos/ver/"+res.data.data)

                    }else{
                        history.push("/documentos/ver/"+res.data.data)
                    }
                })
            }else{
                await Swal.fire({
                    title: "Informação",
                    text: res.data.message,
                    icon: "error",
                    timer: 3000
                })
            }
        }


    }

    function alterarPreco(idx) {
        // eslint-disable-next-line array-callback-return
        const newTmp=linhasArtigo.filter((value, index) => {
            if(index===idx){
                if(Number(value.preco)==0){
                    value.preco=""
                }
                setPreco(parseFloat(value.preco).toFixed(2))
                setInfoArtigo(value);
            }else{
                return value;
            }
        })
        let total=0.00;
        for(let row of newTmp){
            total+=calculatePrice(row.quantidade, row.preco, row.desconto)
        }
        setTotalDoc(total)
        setLinhasArtigo(newTmp);
        setDefenirPreco(true)
    }

    function alterarQuantidade(idx) {
        // eslint-disable-next-line array-callback-return
        const newTmp=linhasArtigo.filter((value, index) => {
            if(index===idx){

                if(Number(value.preco)==0){
                    value.preco=""
                }
                setQuantidade(value.quantidade)
                setInfoArtigo(value);
            }else{
                return value;
            }
        })
        let total=0.00;
        for(let row of newTmp){
            total+=calculatePrice(row.quantidade, row.preco, row.desconto)
        }
        setTotalDoc(total)
        setLinhasArtigo(newTmp);
        setDefenirQtd(true)
    }

    function alterarDesconto(idx) {
        // eslint-disable-next-line array-callback-return
        const newTmp=linhasArtigo.filter((value, index) => {
            if(index===idx){

                if(Number(value.preco)==0){
                    value.preco=""
                }
                setDesconto(value.desconto)
                setInfoArtigo(value);
            }else{
                return value;
            }
        })
        let total=0.00;
        for(let row of newTmp){
            total+=calculatePrice(row.quantidade, row.preco, row.desconto)
        }
        setTotalDoc(total)
        setLinhasArtigo(newTmp);

        setDefenirDesconto(true)
    }

    function removerLinha(idx) {
        // eslint-disable-next-line array-callback-return
        const newTmp=linhasArtigo.filter((value, index) => {
            if (index !== idx) {
                return value;
            }
        })
        let total=0.00;
        for(let row of newTmp){
            total+=calculatePrice(row.quantidade, row.preco, row.desconto)
        }
        setTotalDoc(total)
        setDesconto(0)
        setLinhasArtigo(newTmp);
    }

/*    function alterarObs(obs){
        setObsData(obs)
        setObs(false)
    }*/

    function alterarObsLinha(idx, txt){
        const tmp = linhasArtigo;
        tmp[idx]['obs']=txt;
        setLinhasArtigo(tmp)
    }

    return(
        <Container component="main" maxWidth="xl" style={{padding:0}}>
            <CssBaseline />
            <Backdrop open={acoes} onClick={()=>setAcoes(false)} style={{zIndex:'90'}} />
            <div className={classes.paper}>
                <CodeReader/>
                <Grid container spacing={1}>
                    {find!==null?(
                        <Grid item xs={12} sm={12}>
                            <Alert severity="error">{find}</Alert>
                        </Grid>
                    ):null}
                    <Grid item sm={6} xs={12}>
                        <Grid container spacing={1}>
                            <Grid  item sm={12} xl={12}>
                                <Card  variant="outlined">
                                    <CardHeader
                                        title={"Artigos"}
                                        /*action={webCam?<Button style={{minWidth: '90%'}} type="button" color="primary" variant="contained" onClick={()=>setOpenCodeBarReader(true)}><ViewWeekSharpIcon/> </Button>:null}*/
                                    />
                                    <CardContent style={{maxHeight:'80vh', overflowX:'auto'}}>
                                            <List component="nav">
                                                <FlatList
                                                    sortBy={"item_info.Description"}
                                                    //renderOnScroll
                                                    paginationLoadingIndicator={<div style={{width:'100%', textAlign:'center'}}><CircularProgress /></div>}
                                                    loadingIndicatorPosition='center'
                                                    renderWhenEmpty={() =>
                                                        <ListItem
                                                        >
                                                            <ListItemText primary={"Este cliente não tem artigos associados."}/>
                                                        </ListItem>}
                                                    list={lastOrders}
                                                    renderItem={item=>(
                                                        <>
                                                            <ListItem
                                                                button
                                                                onClick={()=> {
                                                                    setShowLastOrder(false)
                                                                    defineArtigo(item.item_info)
                                                                }}
                                                            >
                                                                <ListItemText primary={item.item_info.ShortDescription}/>
                                                                <Badge badgeContent={parseFloat(getCurrentPrice(cliente, item.item_info)).toFixed(2)+"€"} color="primary">
                                                                          </Badge>
                                                            </ListItem>
                                                            <Divider/>
                                                        </>
                                                    )}
                                                />
                                            </List>
                                    </CardContent>
                                </Card>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item sm={6} xs={12} style={{width: '36vw'}}>
                        <Card  style={{height: '88vh'}}  variant="outlined">

                            <CardHeader
                                title={"Encomenda"}
                                subheader={clienteDefenido?cliente.OrganizationName:null}
                                /*action={<>
                                    {clienteDefenido ?
                                        <Button style={{minWidth: '90%'}} onClick={() => setShowLastOrder(true)}
                                                type="button" color="primary" variant="outlined"><History/>
                                        </Button> : null}
                                </>}*/
                            />
                            <CardContent style={{paddingTop:-10}}>
                                {/*<Typography variant="subtitle2">Cliente: {cliente.OrganizationName}</Typography>
                                <Typography variant="subtitle2">Morada: {cliente.AddressLine1}</Typography>
                                <Typography variant="subtitle2">Tabela: {cliente.price_line_details.ShortDescription}</Typography>*/}
                                <TableContainer style={{height: '70vh', overflowY: 'auto', position: "sticky"}}>
                                    <Table size="small"   stickyHeader className={classes.table} style={{maxHeight: '70vh', overflowY: 'visible', position: "sticky"}} aria-label="Artigos do documento">
                                        <TableHead>
                                            <TableRow>
                                                <TableCell padding={"none"}/>
                                                <TableCell padding={"checkbox"}/>
                                                <TableCell padding={"none"}>Descrição</TableCell>
                                                <TableCell padding={"none"}>Quant.</TableCell>
                                                <TableCell padding={"none"}>Preço</TableCell>
                                                <TableCell padding={"none"}>Desc.</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {linhasArtigo.map((row, index) =>
                                                <React.Fragment key={index}>
                                                    <TableRow>
                                                        <TableCell padding={"none"} ><IconButton aria-label="expand row" size="small" onClick={() => {edit===9999?setEdit(index):setEdit(9999)}}>
                                                            {edit===index ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                                                        </IconButton></TableCell>
                                                        <TableCell padding={"none"} >{index+1}</TableCell>
                                                        <TableCell padding={"none"} >{row.ShortDescription}</TableCell>
                                                        <TableCell padding={"none"} ><Button variant={"text"} onClick={()=>alterarQuantidade(index)}>{row.quantidade}</Button></TableCell>
                                                        <TableCell padding={"none"} ><Button variant={"text"} onClick={()=>alterarPreco(index)}>{isNaN(parseFloat(row.preco))?"0.00":parseFloat(row.preco).toFixed(2)}€/{row.unidade.UnitOfMeasureID}</Button></TableCell>
                                                        <TableCell padding={"none"} ><Button variant={"text"} onClick={()=>alterarDesconto(index)}>{!isNaN(parseFloat(row.desconto))?parseFloat(row.desconto).toFixed(2):0}%</Button></TableCell>
                                                    </TableRow>
                                                    <TableRow>
                                                        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }}  colSpan={5}>
                                                            <Collapse in={edit===index} timeout="auto" unmountOnExit>
                                                                <Box margin={0}>
                                                                    <Typography variant="h6" gutterBottom component="div">
                                                                        Menu
                                                                    </Typography>
                                                                    <Table>
                                                                        <TableBody>
                                                                            <TableRow>
                                                                                <TableCell style={{width:'80%'}} colSpan={2}>
                                                                                    <TextField
                                                                                        label={"Observações..."}
                                                                                        style={{width:'90%'}}
                                                                                        multiline
                                                                                        variant={"outlined"}
                                                                                        maxRows={4}
                                                                                        value={row.obs}
                                                                                        onChange={(e)=>{
                                                                                            alterarObsLinha(index, e.target.value)
                                                                                        }}
                                                                                    />
                                                                                </TableCell>
                                                                            </TableRow>
                                                                            <TableRow>
                                                                                <TableCell><Button style={{width: '100%'}} variant="contained" color="secondary" onClick={() =>removerLinha(index)}>Remover linha</Button></TableCell>
                                                                                <TableCell><Button style={{width: '100%'}} variant="outlined" color="primary" onClick={()=>alterarPreco(index)}>Alterar preco</Button></TableCell>
                                                                            </TableRow>
                                                                            <TableRow>
                                                                                <TableCell><Button style={{width: '100%'}} variant="outlined" color="primary" onClick={()=>alterarQuantidade(index)}>Alterar Quantidade</Button></TableCell>
                                                                                <TableCell><Button style={{width: '100%'}} variant="outlined" color="primary" onClick={()=>alterarDesconto(index)}>Alterar Desconto</Button></TableCell>
                                                                            </TableRow>
                                                                        </TableBody>
                                                                    </Table>
                                                                </Box>
                                                            </Collapse>
                                                        </TableCell>
                                                    </TableRow>
                                                </React.Fragment>
                                            )}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </CardContent>
                        </Card>
                        {/*<Card variant="outlined" style={{marginTop: 0, width:'100%'}}>
                            <CardContent>
                                <TableContainer >
                                    <Typography variant="h6" gutterBottom component="div">
                                        <b>TOTAL: {totalDoc.toFixed(2)}€</b>
                                    </Typography>

                                    <Table className={classes.table} style={{scrollbarColor:"#000000", width:'90%'}}>
                                        <TableBody>
                                            <TableCell style={{width:'15%'}}><Button onClick={()=>setPagamento(true)}  disabled={linhasArtigo.length<= 0 || !clienteDefenido===true} variant={"outlined"} color={"primary"} startIcon={<EuroIcon/>}>Fechar venda</Button></TableCell>
                                            <TableCell style={{width:'15%'}}><Button onClick={()=>setIndicarCliente(true)} variant={"outlined"} startIcon={<PersonIcon/>}>Indicar Cliente</Button></TableCell>
                                            <TableCell style={{width:'15%'}}><Button onClick={()=>
                                                Swal.fire({
                                                    title: 'Deseja cancelar a venda?',
                                                    showDenyButton: true,
                                                    confirmButtonText: `Sim`,
                                                    denyButtonText: `Não`,
                                                }).then((result) => {
                                                    if (result.isConfirmed) {
                                                        window.location.reload()
                                                    }
                                                })
                                            } color={"secondary"} variant={"outlined"} startIcon={<DeleteIcon/>}>Cancelar venda</Button></TableCell>
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </CardContent>
                        </Card>*/}
                    </Grid>
                </Grid>
            </div>



            <Dialog
                open={pagamento}
                onClose={()=>setPagamento(false)}
                
            >
                <DialogTitle >{"Guardar documento?"}</DialogTitle>
                <DialogContent >
                    <MuiSelect fullWidth={true} onChange={e=>setLocal(e.target.value)} value={local} variant={"outlined"} name="localEncomenda" label="Local" required>
                        <MenuItem value="telefone">Telefone</MenuItem>
                        <MenuItem selected={true} value="presencial">Presencial</MenuItem>
                        <MenuItem value="email">Email</MenuItem>
                    </MuiSelect>
                </DialogContent>
                <DialogActions>
                    <Button autoFocus onClick={()=>setPagamento(false)} color="primary">
                        Cancelar
                    </Button>
                    <Button onClick={()=>guardarDoc()} color="primary" autoFocus>
                        Guardar
                    </Button>
                </DialogActions>
            </Dialog>
            {typeof infoArtigo!=="undefined"?
                <Dialog
                    open={defenirQtd}
                    onClose={()=>defineQuantidade(quantidade)}
                    
                >
                        <DialogTitle >
                            Quantidade do artigo: {infoArtigo.ShortDescription}
                            <IconButton className={classes.closeBtn} aria-label="close" onClick={()=>defineQuantidade(quantidade)} >
                                <CloseIcon />
                            </IconButton>
                        </DialogTitle>
                    <DialogContent>
                        <Grid container spacing={2}>
                            <Grid item xs={12} style={{width:'100%', height:'75px', fontSize:'29px'}}>{quantidade}</Grid>
                            <Grid item xs={4}><Button onClick={()=>setQtd("1", quantidade)} variant={"contained"} color={"primary"} style={{width:'100%', height:'50px', fontSize:'25px'}}><b>1</b></Button></Grid>
                            <Grid item xs={4}><Button onClick={()=>setQtd("2", quantidade)} variant={"contained"} color={"primary"} style={{width:'100%', height:'50px', fontSize:'25px'}}><b>2</b></Button></Grid>
                            <Grid item xs={4}><Button onClick={()=>setQtd("3", quantidade)} variant={"contained"} color={"primary"} style={{width:'100%', height:'50px', fontSize:'25px'}}><b>3</b></Button></Grid>
                            <Grid item xs={4}><Button onClick={()=>setQtd("4", quantidade)} variant={"contained"} color={"primary"} style={{width:'100%', height:'50px', fontSize:'25px'}}><b>4</b></Button></Grid>
                            <Grid item xs={4}><Button onClick={()=>setQtd("5", quantidade)} variant={"contained"} color={"primary"} style={{width:'100%', height:'50px', fontSize:'25px'}}><b>5</b></Button></Grid>
                            <Grid item xs={4}><Button onClick={()=>setQtd("6", quantidade)} variant={"contained"} color={"primary"} style={{width:'100%', height:'50px', fontSize:'25px'}}><b>6</b></Button></Grid>
                            <Grid item xs={4}><Button onClick={()=>setQtd("7", quantidade)} variant={"contained"} color={"primary"} style={{width:'100%', height:'50px', fontSize:'25px'}}><b>7</b></Button></Grid>
                            <Grid item xs={4}><Button onClick={()=>setQtd("8", quantidade)} variant={"contained"} color={"primary"} style={{width:'100%', height:'50px', fontSize:'25px'}}><b>8</b></Button></Grid>
                            <Grid item xs={4}><Button onClick={()=>setQtd("9", quantidade)} variant={"contained"} color={"primary"} style={{width:'100%', height:'50px', fontSize:'25px'}}><b>9</b></Button></Grid>
                            <Grid item xs={4}><Button onClick={()=>setQtd("apagar", quantidade)} variant={"contained"} color={"secondary"} style={{width:'100%', height:'50px', fontSize:'25px'}}><b>Apagar</b></Button></Grid>
                            <Grid item xs={4}><Button onClick={()=>setQtd("0", quantidade)} variant={"contained"} color={"primary"} style={{width:'100%', height:'50px', fontSize:'25px'}}><b>0</b></Button></Grid>
                            <Grid item xs={4}><Button onClick={()=>setQtd(".", quantidade)} disabled={infoArtigo.MaximumDecimals=="0"} variant={"outlined"} color={"primary"} style={{width:'100%', height:'50px', fontSize:'25px'}}><b>.</b></Button></Grid>
                            <Grid item xs={12}><Button disabled={quantidade==="" || Number(quantidade)===0} color={"primary"} onClick={()=>defineQuantidade(quantidade)} variant={"contained"} style={{width:'100%', height:'50px', fontSize:'25px'}}><b>Adicionar artigo</b></Button></Grid>
                        </Grid>
                    </DialogContent>
                </Dialog>:null}

            <Dialog
                open={defenirPreco}
                onClose={()=>definePreco(preco)}

            >
                <DialogTitle >
                    {"Alterar preço unitario"}
                    <IconButton className={classes.closeBtn} aria-label="close" onClick={()=>definePreco(preco)} >
                        <CloseIcon />
                    </IconButton>
                </DialogTitle>
                <DialogContent>
                    <Grid container spacing={2}>
                        <Grid item xs={12} style={{width:'100%', height:'75px', fontSize:'29px'}}>{preco}</Grid>
                        <Grid item xs={4}><Button onClick={()=>setPrice("1", preco)} variant={"contained"} color={"primary"} style={{width:'100%', height:'50px', fontSize:'25px'}}><b>1</b></Button></Grid>
                        <Grid item xs={4}><Button onClick={()=>setPrice("2", preco)} variant={"contained"} color={"primary"} style={{width:'100%', height:'50px', fontSize:'25px'}}><b>2</b></Button></Grid>
                        <Grid item xs={4}><Button onClick={()=>setPrice("3", preco)} variant={"contained"} color={"primary"} style={{width:'100%', height:'50px', fontSize:'25px'}}><b>3</b></Button></Grid>
                        <Grid item xs={4}><Button onClick={()=>setPrice("4", preco)} variant={"contained"} color={"primary"} style={{width:'100%', height:'50px', fontSize:'25px'}}><b>4</b></Button></Grid>
                        <Grid item xs={4}><Button onClick={()=>setPrice("5", preco)} variant={"contained"} color={"primary"} style={{width:'100%', height:'50px', fontSize:'25px'}}><b>5</b></Button></Grid>
                        <Grid item xs={4}><Button onClick={()=>setPrice("6", preco)} variant={"contained"} color={"primary"} style={{width:'100%', height:'50px', fontSize:'25px'}}><b>6</b></Button></Grid>
                        <Grid item xs={4}><Button onClick={()=>setPrice("7", preco)} variant={"contained"} color={"primary"} style={{width:'100%', height:'50px', fontSize:'25px'}}><b>7</b></Button></Grid>
                        <Grid item xs={4}><Button onClick={()=>setPrice("8", preco)} variant={"contained"} color={"primary"} style={{width:'100%', height:'50px', fontSize:'25px'}}><b>8</b></Button></Grid>
                        <Grid item xs={4}><Button onClick={()=>setPrice("9", preco)} variant={"contained"} color={"primary"} style={{width:'100%', height:'50px', fontSize:'25px'}}><b>9</b></Button></Grid>
                        <Grid item xs={4}><Button onClick={()=>setPrice("apagar", preco)} variant={"contained"} color={"secondary"} style={{width:'100%', height:'50px', fontSize:'25px'}}><b>Apagar</b></Button></Grid>
                        <Grid item xs={4}><Button onClick={()=>setPrice("0", preco)} variant={"contained"} color={"primary"} style={{width:'100%', height:'50px', fontSize:'25px'}}><b>0</b></Button></Grid>
                        <Grid item xs={4}><Button onClick={()=>setPrice(".", preco)} variant={"outlined"} color={"primary"} style={{width:'100%', height:'50px', fontSize:'25px'}}><b>.</b></Button></Grid>
                        <Grid item xs={12}><Button disabled={preco==="" || Number(preco)===0} color={"primary"} onClick={()=>definePreco(preco)} variant={"contained"} style={{width:'100%', height:'50px', fontSize:'25px'}}><b>Atualizar</b></Button></Grid>
                    </Grid>
                </DialogContent>
            </Dialog>

            <Dialog
                open={defenirDesconto}
                onClose={()=>defineDesconto(desconto)}

            >
                <DialogTitle >
                    {"Alterar Desconto"}
                    <IconButton className={classes.closeBtn} aria-label="close" onClick={()=>defineDesconto(desconto)} >
                        <CloseIcon />
                    </IconButton>
                </DialogTitle>
                <DialogContent>
                    <Grid container spacing={2}>
                        <Grid item xs={12} style={{width:'100%', height:'75px', fontSize:'29px'}}>{desconto}</Grid>
                        <Grid item xs={4}><Button onClick={()=>setDiscount("1", desconto)} variant={"contained"} color={"primary"} style={{width:'100%', height:'50px', fontSize:'25px'}}><b>1</b></Button></Grid>
                        <Grid item xs={4}><Button onClick={()=>setDiscount("2", desconto)} variant={"contained"} color={"primary"} style={{width:'100%', height:'50px', fontSize:'25px'}}><b>2</b></Button></Grid>
                        <Grid item xs={4}><Button onClick={()=>setDiscount("3", desconto)} variant={"contained"} color={"primary"} style={{width:'100%', height:'50px', fontSize:'25px'}}><b>3</b></Button></Grid>
                        <Grid item xs={4}><Button onClick={()=>setDiscount("4", desconto)} variant={"contained"} color={"primary"} style={{width:'100%', height:'50px', fontSize:'25px'}}><b>4</b></Button></Grid>
                        <Grid item xs={4}><Button onClick={()=>setDiscount("5", desconto)} variant={"contained"} color={"primary"} style={{width:'100%', height:'50px', fontSize:'25px'}}><b>5</b></Button></Grid>
                        <Grid item xs={4}><Button onClick={()=>setDiscount("6", desconto)} variant={"contained"} color={"primary"} style={{width:'100%', height:'50px', fontSize:'25px'}}><b>6</b></Button></Grid>
                        <Grid item xs={4}><Button onClick={()=>setDiscount("7", desconto)} variant={"contained"} color={"primary"} style={{width:'100%', height:'50px', fontSize:'25px'}}><b>7</b></Button></Grid>
                        <Grid item xs={4}><Button onClick={()=>setDiscount("8", desconto)} variant={"contained"} color={"primary"} style={{width:'100%', height:'50px', fontSize:'25px'}}><b>8</b></Button></Grid>
                        <Grid item xs={4}><Button onClick={()=>setDiscount("9", desconto)} variant={"contained"} color={"primary"} style={{width:'100%', height:'50px', fontSize:'25px'}}><b>9</b></Button></Grid>
                        <Grid item xs={4}><Button onClick={()=>setDiscount("apagar", desconto)} variant={"contained"} color={"secondary"} style={{width:'100%', height:'50px', fontSize:'25px'}}><b>Apagar</b></Button></Grid>
                        <Grid item xs={4}><Button onClick={()=>setDiscount("0", desconto)} variant={"contained"} color={"primary"} style={{width:'100%', height:'50px', fontSize:'25px'}}><b>0</b></Button></Grid>
                        <Grid item xs={4}><Button onClick={()=>setDiscount(".", desconto)} variant={"outlined"} color={"primary"} style={{width:'100%', height:'50px', fontSize:'25px'}}><b>.</b></Button></Grid>
                        <Grid item xs={12}><Button color={"primary"} onClick={()=>defineDesconto(desconto)} variant={"contained"} style={{width:'100%', height:'50px', fontSize:'25px'}}><b>Atualizar</b></Button></Grid>
                    </Grid>
                </DialogContent>
            </Dialog>

            <Dialog
                open={indicarCliente}
                onClose={()=>setIndicarCliente(false)}

            >
                <DialogTitle >{"Indicar cliente"}</DialogTitle>
                <DialogContent style={{minWidth: '500px'}}>
                    <Grid container spacing={2} style={{height:'450px'}}>
                        <Grid item xs={12} sm={12}>
                            {loading?(
                                <center style={{width: '100%'}}>
                                    <CircularProgress />
                                </center>
                            ):(
                                <>
                                    <FormControlLabel
                                        control={
                                            <Switch
                                                checked={agrupamento}
                                                onChange={()=>setAgrupamento(!agrupamento)}
                                                color="primary"
                                                name="checkedB"

                                                inputProps={{ 'aria-label': 'primary checkbox' }}
                                            />
                                        }
                                        label="Listar por estabelecimento"
                                    />
                                    <Select
                                        onChange={defineContaCorrente}
                                        className={classes.select}
                                        name="clientes"
                                        placeholder="Clientes"
                                        options={clientes}
                                        option={cliente}
                                        getOptionLabel={(option)=> {
                                            return agrupamento? (option.CustomerGroupDescription!==""?option.CustomerGroupDescription:option.OrganizationName):option.OrganizationName
                                        }}
                                        getOptionValue ={(option)=>option.id}
                                        defaultMenuIsOpen
                                    />
                                </>
                            )}


                        </Grid>
                    </Grid>
                </DialogContent>
            </Dialog>
            <Dialog
                open={observacoes}
                onClose={()=>setObs(false)}

                fullScreen={fullScreen}
            >
                <DialogTitle >{"Observações"}</DialogTitle>
                <DialogContent>
                    <Grid container spacing={2} style={{height:'400px',minWidth:'500px'}}>
                        <Grid item xs={12} sm={12}>
                            <TextareaAutosize style={{minHeight: '100px',width:'100%'}} aria-label="minimum height" rowsMin={3} placeholder="Observações..." name="observacoes" value={obsData} onChange={(e)=>setObsData(e.target.value)} />
                        </Grid>
                        <Grid item xs={12}>
                            <Button variant={"contained"} color={"primary"} onClick={()=>setObs(false)}>Fechar</Button>
                        </Grid>
                    </Grid>
                </DialogContent>
            </Dialog>

            {/*<Dialog
                open={showLastOrders}
                onClose={()=>setShowLastOrder(false)}

                fullScreen={fullScreen}
            >
                <DialogTitle >
                    {"Ultimas encomendas"}
                    <IconButton style={{float: 'right'}} aria-label="close" onClick={()=>setShowLastOrder(false)}>
                        <CloseIcon />
                    </IconButton>
                </DialogTitle>
                <DialogContent>
                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={12}>
                            <List component="nav">
                                <FlatList
                                    //renderOnScroll
                                    paginationLoadingIndicator={<div style={{width:'100%', textAlign:'center'}}><CircularProgress /></div>}
                                    loadingIndicatorPosition='center'

                                    hasMoreItems={lastOrders.next_page_url!==null}
                                    renderWhenEmpty={() =>
                                        <ListItem
                                    >
                                        <ListItemText primary={"Este cliente não efetuou encomendas previamente"}/>
                                    </ListItem>}
                                    loadMoreItems={loadMore}
                                    list={lastOrders.data}
                                    renderItem={item=>(
                                        <ListItem>
                                            <List
                                                style={{width: '100%'}}
                                                subheader={
                                                    <ListSubheader style={{backgroundColor: "#fff"}} component="div" id="nested-list-subheader">
                                                        Encomenda dia {moment(item.created_at).format('DD/MM/YYYY')}
                                                    </ListSubheader>
                                                }
                                            >
                                                {item.detalhes.map(row => (
                                                    <ListItem
                                                        button
                                                        onClick={()=> {
                                                            setShowLastOrder(false)
                                                            defineArtigo(row.item_info)
                                                        }}
                                                    >
                                                        <ListItemText primary={row.item_info.ShortDescription}/>
                                                        <Badge badgeContent={row.quantidade} color="primary">
                                                        </Badge>
                                                    </ListItem>
                                                ))}
                                            </List>
                                        </ListItem>
                                    )}
                                />
                            </List>
                        </Grid>
                        <Grid item xs={12}>
                            <Button variant={"contained"} color={"primary"} onClick={()=>setShowLastOrder(false)}>Fechar</Button>
                        </Grid>
                    </Grid>
                </DialogContent>
            </Dialog>*/}

            <Dialog
                open={itemSearch}
                onClose={()=>setItemSearch(false)}

                fullScreen={fullScreen}
            >
                <DialogTitle >
                    {"Pesquisa de Artigos"}
                    <IconButton style={{float: 'right'}} aria-label="close" onClick={()=>setItemSearch(false)}>
                        <CloseIcon />
                    </IconButton>
                </DialogTitle>
                <DialogContent style={{minWidth: '25vw'}}>
                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={12} style={{height:'450px'}}>
                            <Select
                                onChange={defineArtigo}
                                className={classes.select}
                                name="Artigos"
                                placeholder="Artigo"
                                options={listaArtigos}
                                //option={cliente}
                                getOptionLabel={(option)=> {return option.ItemID + "  /  " + option.Description}}
                                getOptionValue ={(option)=>option.id}
                                defaultMenuIsOpen
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <Button variant={"contained"} color={"primary"} onClick={()=>setItemSearch(false)}>Fechar</Button>
                        </Grid>
                    </Grid>
                </DialogContent>
            </Dialog>
            <Dialog
                open={newCustomer}
                onClose={()=>setNewCustomer(false)}

                fullScreen={fullScreen}
            >
                <DialogTitle >
                    {"Cliente novo"}
                    <IconButton style={{float: 'right'}} aria-label="close" onClick={()=>newCustomer(false)}>
                        <CloseIcon />
                    </IconButton>
                </DialogTitle>
                <DialogContent style={{minWidth: '25vw'}}>
                    <NewCustomerMask newCustomer={setNewCustomer} setFromMask={(value)=>true} setTmp={null} setValue={setObsData}/>
                </DialogContent>
            </Dialog>

            <SpeedDial
                icon={<SpeedDialIcon />}
                open={acoes}
                onOpen={()=>setAcoes(true)}
                onClose={()=>setAcoes(false)}
                ariaLabel={"Menu de Acoes"}
                onClick={()=>setAcoes(!acoes)}
                direction={"up"}
                className={classes.speedDial}
            >
                <SpeedDialAction
                    icon={<PersonAddIcon/>}
                    //tooltipTitle={"Fechar Venda"}

                    color={"secondary"}
                    onClick={()=> {
                        setNewCustomer(true);
                        setAcoes(false)
                    }}
                />
                <SpeedDialAction
                    icon={<SearchIcon/>}
                    //tooltipTitle={"Fechar Venda"}

                    color={"secondary"}
                    onClick={()=> {
                        setItemSearch(true);
                        setAcoes(false)
                    }}
                />
                <SpeedDialAction
                    icon={<NoteIcon/>}
                    //tooltipTitle={"Fechar Venda"}

                    color={"secondary"}
                    onClick={()=> {
                        setObs(true);
                        setAcoes(false)
                    }}
                />
                <SpeedDialAction
                    icon={<EuroIcon/>}
                    //tooltipTitle={"Fechar Venda"}

                    color={"secondary"}
                    onClick={()=> {
                        setPagamento(true)
                        setAcoes(false)
                    }}
                />
                <SpeedDialAction
                    icon={<PersonIcon/>}
                    //tooltipTitle={"Indicar Cliente"}

                    onClick={()=> {
                        setIndicarCliente(true)
                        setAcoes(false)
                    }}
                />
                <SpeedDialAction
                    icon={<DeleteIcon/>}
                    //tooltipTitle={"Cancelar Venda"}

                    onClick={()=>
                        Swal.fire({
                            title: 'Deseja cancelar a venda?',
                            showDenyButton: true,
                            confirmButtonText: `Sim`,
                            denyButtonText: `Não`,
                        }).then((result) => {
                            if (result.isConfirmed) {
                                window.location.reload()
                            }
                        })
                    }
                />
            </SpeedDial>

        </Container>
    )
}
