import {Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, MenuItem} from "@material-ui/core";
import Button from "@material-ui/core/Button";
import {useRecoilState} from "recoil";
import {CreateUser, ReloadComponent} from "../atoms";
import {Form} from "@unform/web";
import {Select, TextField} from "unform-material-ui";
import Grid from "@material-ui/core/Grid";
import {useRef} from "react";
import api from "../services/api";
import {useSnackbar} from "notistack";

export default function NewUser (){
    const [createUser, setCreateUser] = useRecoilState(CreateUser);
    const [, setReload] = useRecoilState(ReloadComponent);
    const formRef = useRef(null);
    const { enqueueSnackbar } = useSnackbar();

    function create(data){
        if(data.password_confirm===data.password){
            api.post('/user', data).then((res)=>{
                if(res.data.code===0){
                    enqueueSnackbar(res.data.message, { variant:"success", autoHideDuration: 5000 });
                    setCreateUser(false)
                    setReload(true)
                }else{
                    enqueueSnackbar(res.data.message, { variant:"error", autoHideDuration: 5000});
                }
            })
        }else{
            enqueueSnackbar("As passwords não coincidem", { variant:"error" , autoHideDuration: 5000});
        }

    }

    return(
        <Dialog open={createUser} onClose={()=>setCreateUser(false)} aria-labelledby="form-dialog-title">
            <DialogTitle id="form-dialog-title">Criação de utilizador</DialogTitle>
            <DialogContent>
                <Form ref={formRef} onSubmit={create}>
                    <Grid spacing={2} container={true}>
                        <Grid item xs={6}>
                            <TextField fullWidth={true} margin={"dense"} label={"Nome"} name={"name"}/>
                        </Grid>
                        <Grid item xs={6}>
                            <TextField type={"email"} fullWidth={true} margin={"dense"} label={"Email"} name={"email"}/>
                        </Grid>
                        <Grid item xs={6}>
                            <TextField type={"number"} fullWidth={true} margin={"dense"} label={"Numero de vendedor no Sage"} name={"salesman"}/>
                        </Grid>
                        <Grid item xs={6}>
                            <Select style={{width: '100%', height: '100%', marginTop: '8.4px'}} name={"level"} fullWidth={true} margin={"dense"} label={"Permissões"} variant={"standard"}>
                                <MenuItem value={1}>Administrador</MenuItem>
                                <MenuItem value={2}>Vendedor</MenuItem>
                                <MenuItem value={3}>Gestor de Armazem</MenuItem>
                                <MenuItem value={4}>Vendedor/Gestor de Armazem</MenuItem>
                                <MenuItem value={5}>Administrativa</MenuItem>
                            </Select>
                        </Grid>
                        <Grid item xs={12}>
                            <TextField fullWidth={true} type={"password"} margin={"dense"} label={"Password"} name={"password"}/>
                        </Grid>
                        <Grid item xs={12}>
                            <TextField fullWidth={true} type={"password"} margin={"dense"} label={"Confirmacao de password"} name={"password_confirm"}/>
                        </Grid>
                    </Grid>
                </Form>
            </DialogContent>
            <DialogActions>
                <Button onClick={()=>setCreateUser(false)} color="primary">
                    Cancelar
                </Button>
                <Button onClick={()=>formRef.current.submitForm()} color="primary">
                    Confirmar
                </Button>
            </DialogActions>
        </Dialog>
    )
}