import React, {useEffect, useState} from 'react';
import CssBaseline from "@material-ui/core/CssBaseline";
import Container from "@material-ui/core/Container";
import {makeStyles} from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import Paper from "@material-ui/core/Paper";
import CardContent from "@material-ui/core/CardContent";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import api from '../../services/api'
import Select from "react-select";
import Typography from "@material-ui/core/Typography";
import TableContainer from "@material-ui/core/TableContainer";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogActions from "@material-ui/core/DialogActions";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Switch from "@material-ui/core/Switch";
import DeleteIcon from '@material-ui/icons/Delete';
import TableBody from "@material-ui/core/TableBody";
import Swal from "sweetalert2";
import {useHistory} from "react-router-dom"
import {useRecoilState} from "recoil";
import {EstadoReader, InfoReader} from "../../atoms";
import CodeReader from "../../components/CodeReader";
import ViewWeekSharpIcon from "@material-ui/icons/ViewWeekSharp";
import {Alert} from "@material-ui/lab";
import {detectWebcam} from "../../services/functions";



const useStyles = makeStyles((theme) => ({
    root:{
        minWidth: "75vw",
    },
    paper: {
        marginTop: theme.spacing(8),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    avatar: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.secondary.main,
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(3),
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    },
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: '#fff',
    },
    table: {
        //minWidth: "35vw",
    },
    table2: {
        maxWidth: "35vw",
    },
    select: {
        minWidth: "35vw",
    }
}));

export default function WarehouseEditar(props){
    const classes = useStyles();
    const [artigos, setArtigos] = useState([]);
    const [infoArtigo, setInfoArtigo] = useState({});
    const [artigo, setArtigo] = useState([]);
    const [pagamento, setPagamento] = useState(false);
    const [edit, setEdit] = useState(false);
    const history = useHistory();
    const [documento, setDocumento] = useState({});
    const [webCam, setWebCam]=useState(false)
    const [artigoReader, setArtigoReader] = useRecoilState(InfoReader);
    const [openCodeBarReader,setOpenCodeBarReader]=useRecoilState(EstadoReader)
    const [find, setFind]=useState(null);


    useEffect(()=>{
        detectWebcam((hasWebCam)=>setWebCam(hasWebCam))
        api.get('/warehouse/'+props.match.params.id).then(res=>{
            if(res.data.code===0){
                setDocumento(res.data.data);
            }
        })
        api.get('/item/list').then(res=>{
            if(res.data.code===0){
                setArtigos(res.data.data)
            }
        })
    },[props.match.params.id]);

    useEffect(()=>{
        if(artigoReader!==null){
            let found=false
            artigos.map(row => {
                if (row.BarCode === artigoReader) {
                    defineArtigo(row)
                    setFind(null)
                    found=true
                    setArtigoReader(null)
                    return 0
                }
            });
            if(!found){
                setFind("Nenhum artigo encontrado")
            }
        }
    }, [artigoReader])

    function defenirLinhas() {
        const temp = documento;
        let total=0.00;
        temp.items.push({
            info_artigos:infoArtigo,
            preco:infoArtigo.preco,
            quantidade: Number(infoArtigo.quantidade).toFixed(parseInt(infoArtigo.MaximumDecimals))
        })

        for (let row of temp.items) {
            if(!isNaN(parseFloat(row.preco))) {
                total += (parseFloat(row.quantidade) * parseFloat(row.preco))
            }else{
                total+=0
            }
        }

        setInfoArtigo({preco: Number().toFixed(2),precos:[0,0,0],quantidade:Number().toFixed(parseInt('0'))})
        setArtigo({})

        setDocumento(temp)
    }


    function defineArtigo(data) {
        setArtigo(data)
        data.quantidade = 0;
        setInfoArtigo(data);
    }

    function defineQuantidade(e) {
        if(e.target.value>=0){
            setInfoArtigo({
                ...infoArtigo,
                quantidade: e.target.value
            })
        }
    }

    function guardarDoc() {
        api.put('/warehouse/'+props.match.params.id, documento).then(async res=>{
            setPagamento(false)
            await Swal.fire({
                title: "Informação",
                text: res.data.message,
                icon: res.data.code === 0 ? "success" : "error",
                timer: 3000
            })
            if(res.data.code === 0) {
                history.push("/armazem")
            }
        })
    }

    function editarLinha(idx) {
        // eslint-disable-next-line array-callback-return
        const newTmp=documento.items.filter((value, index) => {
            if(index===idx){
                setInfoArtigo({
                    ...value.info_artigos,
                    quantidade: value.quantidade
                })
            }else{
                return value;
            }
        })
        let total=0.00;

        let temp = documento
        temp.items = newTmp
        setDocumento(temp);
    }

    function removerLinha(idx) {
        // eslint-disable-next-line array-callback-return
        const newTmp=documento.items.filter((value, index) => {
            if (index !== idx) {
                return value;
            }
        })

        setDocumento({
            ...documento,
            items:newTmp
        });

    }

    return(
        <Container component="main" maxWidth="xs">
            <CssBaseline />
            <CodeReader/>
            <div className={classes.paper}>
                <Typography component="h1" variant="h5">
                    Editar encomenda <br/>
                </Typography>
                <Card className={classes.root} variant="outlined">
                    <CardContent>
                        <Grid container spacing={2}>
                            <Grid item xs={webCam?8:12} sm={webCam?4:6}>
                                <Select
                                    onChange={defineArtigo}
                                    name="artigos"
                                    option={artigo}
                                    placeholder="Artigos"
                                    options={artigos}
                                    getOptionLabel={(option)=> {return option.ItemID + "  /  " + option.Description}}
                                    getOptionValue ={(option)=>option.id}
                                />
                            </Grid>
                            {webCam?(
                                <Grid item xs={4} sm={2}>
                                    <center>
                                        <Button style={{minWidth: '90%'}} type="button" color="primary" variant="contained" onClick={()=>setOpenCodeBarReader(true)}><ViewWeekSharpIcon/> </Button>
                                    </center>
                                </Grid>):null
                            }
                            <Grid item xs={12} sm={6}>
                                <TableContainer component={Paper} style={{marginBottom: 10, marginTop: 20}}>
                                    <Table className={classes.table} aria-label="Descricao do artigo">
                                        <TableHead>
                                            <TableRow>
                                                <TableCell>Codigo artigo</TableCell>
                                                <TableCell>Descrição</TableCell>
                                                <TableCell>Quantidade</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            <TableRow >
                                                <TableCell>{infoArtigo.ItemID}</TableCell>
                                                <TableCell>{infoArtigo.Description}</TableCell>
                                                <TableCell><TextField name="quantidade" onChange={defineQuantidade} type="number"  value={infoArtigo.quantidade}
                                                                      onKeyPress={(e) => {
                                                                          if(e.key==='Enter'){
                                                                              if(!(infoArtigo.ItemID === undefined || parseFloat(infoArtigo.quantidade) <= 0 || infoArtigo.quantidade===undefined)){
                                                                                  defenirLinhas()
                                                                              }
                                                                          }
                                                                      }}
                                                /></TableCell>
                                            </TableRow>
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                                <Button onClick={defenirLinhas} disabled={infoArtigo.artigo === "" || parseFloat(infoArtigo.quantidade) <= 0} fullWidth style={{marginTop: 20}} variant="contained" color="primary">Adicionar artigo</Button>
                            </Grid>
                            {find!==null?(
                                <Grid item xs={12} sm={12}>
                                    <Alert severity="error">{find}</Alert>
                                </Grid>
                            ):null}
                            <Grid item xs={12}>
                                <TableContainer component={Paper}>
                                    <Table className={classes.table} aria-label="Artigos do documento">
                                            <TableHead>
                                                <TableRow>
                                                    {edit===false?(<TableCell>Codigo artigo</TableCell>):(null)}
                                                    <TableCell>Descrição</TableCell>
                                                    {edit===false?(<TableCell>Quantidade</TableCell>):(<TableCell colSpan={2}><center>Ações</center></TableCell>)}
                                                </TableRow>
                                            </TableHead>
                                        <TableBody>{documento.items!==undefined?
                                            documento.items.map((row, index) =>
                                                <TableRow key={index}>
                                                    {edit===false?(<TableCell>{row.info_artigos.ItemID}</TableCell>):null}
                                                    <TableCell>{row.info_artigos.Description}</TableCell>
                                                    {edit===false?(<TableCell>{Number(row.quantidade).toFixed(row.info_artigos.unidade.MaximumDecimals)}</TableCell>):(<TableCell><Button onClick={()=>editarLinha(index)} variant="outlined" color="primary">Editar linha</Button></TableCell>)}
                                                    {edit===false?(<TableCell></TableCell>):(<TableCell><Button variant="contained" className={classes.button} startIcon={<DeleteIcon />} onClick={() =>removerLinha(index)} color={'secondary'}>Remover linha</Button></TableCell>)}
                                                </TableRow>
                                            ):null
                                        }

                                        </TableBody>
                                    </Table>
                                </TableContainer>
                                <Button onClick={()=>setPagamento(true)} disabled={documento.linhas !==undefined  } fullWidth style={{marginTop: 20}} variant="contained" color="primary">Guardar registo</Button>
                                <FormControlLabel
                                    control={
                                        <Switch
                                            checked={edit}
                                            onChange={()=>setEdit(!edit)}
                                            name="checkedB"
                                            color="primary"
                                        />
                                    }
                                    label="Editar registo"
                                />
                            </Grid>
                        </Grid>
                    </CardContent>
                </Card>
            </div>
            <Dialog
                open={pagamento}
                onClose={()=>setPagamento(false)}
                aria-labelledby="responsive-dialog-title"
            >
                <DialogTitle id="responsive-dialog-title">{"Guardar documento?"}</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Deseja guardar o registo?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button autoFocus onClick={()=>setPagamento(false)} color="primary">
                        Cancelar
                    </Button>
                    <Button onClick={()=>guardarDoc(true)} color="primary" autoFocus>
                        Guardar
                    </Button>
                </DialogActions>
            </Dialog>
        </Container>
    )
}
