import React, {useState, useEffect} from 'react';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import api from "../services/api";
import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";
import {Link} from "react-router-dom"
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Grid from "@material-ui/core/Grid";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { useTheme } from '@material-ui/core/styles';
import ArtigosCriar from "./artigos/Criar";
import ClienteCriar from "./clientes/Criar";
import TextField from "@material-ui/core/TextField"
import Collapse from "@material-ui/core/Collapse";
import Alert from "@material-ui/lab/Alert";
import Select from "react-select";
import DevolverTaras from "./taras/DevolverTara";
import {Paper} from "@material-ui/core";
import {isAuthenticatedAndSalesman, isAuthenticatedAndWarehouse} from "../services/auth";

const useStyles = makeStyles((theme) => ({
    root: {
        minWidth: '80vw',
    },
    paper: {
        marginTop: theme.spacing(8),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    height:{
        backgroundColor:"transparent",
        alignContent: "center",
        color:"white",
        height: 50,
    },
    avatar: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.secondary.main,
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(3),
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    },
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: '#fff',
    },
}));

export default function Dashboard(props) {
    const classes = useStyles();
    const [open, ] = useState(false);
    const [criarArtigo, setCriarArtigo] = useState(false);
    const [criaCliente, setCriarCliente] = useState(false);
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

    return (
        <Container component="main" maxWidth="xs">
            <CssBaseline />
            <div className={classes.paper}>
                <Card className={classes.root}>
                    <CardContent>
                        <Typography component="h1" variant="h5">
                            Dashboard
                        </Typography>
                        <Grid container spacing={1}>
                            {
                                isAuthenticatedAndSalesman()?(
                                    <>
                                        <Grid item xs={12} sm={6}>
                                            <Link to={"/documentos"}>
                                                <Button fullWidth={true} variant={"contained"} color={"primary"}>
                                                    <Paper elevation={0} className={classes.height} />
                                                    <Typography variant={"h5"} component="h2">Realizar encomenda</Typography>
                                                </Button>
                                            </Link>
                                        </Grid>
                                        <Grid item xs={12} sm={6}>
                                            <Link to={"/documentos/criarlista"}>
                                                <Button fullWidth={true} variant={"contained"} color={"primary"}>
                                                    <Paper elevation={0} className={classes.height} />
                                                    <Typography variant={"h5"} component="h2">Realizar encomenda (Modo Lista)</Typography>
                                                </Button>
                                            </Link>
                                        </Grid>
                                        <Grid item xs={12} sm={6}>
                                            <Link to={"/documentos/criarHistorico"}>
                                                <Button fullWidth={true} variant={"contained"} color={"primary"}>
                                                    <Paper elevation={0} className={classes.height} />
                                                    <Typography variant={"h5"} component="h2">Realizar encomenda (Modo Histórico)</Typography>
                                                </Button>
                                            </Link>
                                        </Grid>
                                    </>
                                ):null
                            }

                            {
                                isAuthenticatedAndSalesman()?(
                                    <Grid item xs={12} sm={6}>
                                        <Link to={"/clientes"}>
                                            <Button fullWidth={true} variant={"contained"} color={"primary"}>
                                                <Paper elevation={0} className={classes.height} />
                                                <Typography variant={"h5"} component="h2">Listar clientes</Typography>
                                            </Button>
                                        </Link>
                                    </Grid>
                                ):null
                            }
                            <Grid item xs={12} sm={12}>
                                <a href={"https://www.cordeiroegenebra.pt/catalogo/"} target={"_blank"}>
                                    <Button fullWidth={true} variant={"contained"} color={"primary"}>
                                        <Paper elevation={0} className={classes.height} />
                                        <Typography variant={"h5"} component="h2">Catalogo</Typography>
                                    </Button>
                                </a>
                            </Grid>


                        </Grid>
                    </CardContent>
                </Card>
            </div>
            <Backdrop className={classes.backdrop} open={open} onClick={()=>{}}>
                <CircularProgress color="inherit" />
            </Backdrop>
        </Container>
    );
}
