import React, {useState, useEffect} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import CircularProgress from "@material-ui/core/CircularProgress";
import api from "../../services/api";
import DataTable from "react-data-table-component";
import SortIcon from "@material-ui/icons/ArrowDownward";
import Checkbox from "@material-ui/core/Checkbox";
import Button from "@material-ui/core/Button";
import {Link} from "react-router-dom";
import TextField from "@material-ui/core/TextField";


const useStyles = makeStyles((theme) => ({
    paper: {
        marginTop: theme.spacing(8),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    avatar: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.primary.main,
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(1),
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    },
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: '#fff',
    },
}));




export default function ArtigoListar() {
    const classes = useStyles();

    const [artigos, setArtigos] = useState([]);
    const [loading, setLoading] = React.useState(false);
    const [totalRows, setTotalRows] = useState(0);
    const [perPage, setPerPage] = useState(10);
    const [currentPage, setCurrentPage] = useState(1);
    const [q, setQ] = useState("");


    useEffect(()=>{
        setLoading(true)
        obter()
/*        api.get('/item').then(res=>{
            if(res.data.code===0){
                setArtigos(res.data.data)
                setLista(res.data.data)
            }
            setLoading(false)
        })*/

    }, [q])

    const columns = [
        {
            name: "Codigo",
            selector: "ItemID",
            sortable: true
        },
        {
            name: "Descricão",
            selector: "Description",
            sortable: true
        },  {
            name: "Familia",
            selector: "family.Description",
            sortable: true
        },
        {
            name:"",
            cell:row=><Link to={"/artigos/" + row.id}><Button variant={"contained"} color={"primary"}>Ficha</Button></Link>
        }
    ];

    function obter(page=currentPage, size=perPage, query=q){
        setLoading(true)
        api.get(`/item?page=${page}&per_page=${size}&q=${query}`).then(res=>{
            if(res.data.code===0){
                setArtigos(res.data.data.list)
                setTotalRows(res.data.data.total)
            }
            setLoading(false)
        })
    }

    /*function pesquisa(e) {
        // eslint-disable-next-line array-callback-return
        let temp =lista.filter(value => {
            if((value.itemCode.toLowerCase().includes(e.target.value.toLowerCase()))||(value.descricao.toLowerCase().includes(e.target.value.toLowerCase())) || (value.familia.toLowerCase().includes(e.target.value.toLowerCase()))){
                return value;
            }
        })
        setArtigos(temp)
    }*/

    const handlePageChange = page => {
        console.log(page)
        obter(page);
        setCurrentPage(page);
    };

    function handleTextChange(e){
        setQ(e.target.value)
    }

    const handlePerRowsChange = async (newPerPage, page) => {
        obter(page, newPerPage);
        setPerPage(newPerPage);
    };

    return (
        <Container component="main" maxWidth="md">

            <div className={classes.paper}>

                <DataTable
                    actions={
                        <TextField onChange={handleTextChange} value={q} label="Pesquisa" />
                    }
                    title="Artigos"
                    columns={columns}
                    data={artigos}
                    sortIcon={<SortIcon />}
                    pagination
                    onChangeRowsPerPage={handlePerRowsChange}
                    onChangePage={handlePageChange}
                    paginationTotalRows={totalRows}
                    paginationDefaultPage={currentPage}
                    paginationServer

                    //selectableRows
                    selectableRowsComponent={Checkbox}
                    contextMessage={{
                        singular: 'artigo',
                        plural: 'artigos',
                        message: 'selecionada\\s' }}
                    noDataComponent={"Não foi encontradas artigos"}
                    progressPending={loading}
                    progressComponent={<CircularProgress />}
                    paginationComponentOptions={{
                        rowsPerPageText: 'Linhas por pagina:',
                        rangeSeparatorText: 'de',
                        noRowsPerPage: false,
                        selectAllRowsItem: false,
                        selectAllRowsItemText: 'Todas'
                    }}
                />
            </div>
        </Container>
    );
}
