import * as React from 'react';
import Grid from '@material-ui/core/Grid';
import List from '@material-ui/core/List';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import Checkbox from '@material-ui/core/Checkbox';
import Button from '@material-ui/core/Button';
import Divider from '@material-ui/core/Divider';
import {useEffect, useState} from "react";
import FlatList from "flatlist-react";
import {CircularProgress, ListSubheader, TextField, Typography} from "@material-ui/core";
import api from "../services/api";
import Backdrop from "@material-ui/core/Backdrop";
import Container from "@material-ui/core/Container";
import {makeStyles} from "@material-ui/core/styles";
import Swal from "sweetalert2";

function not(a, b) {
    return a.filter((value) => b.indexOf(value) === -1);
}

function intersection(a, b) {
    return a.filter((value) => b.indexOf(value) !== -1);
}

function union(a, b) {
    return [...a, ...not(b, a)];
}

const useStyles = makeStyles((theme) => ({
    paper: {
        marginTop: theme.spacing(8),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    avatar: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.secondary.main,
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(3),
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    },
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: '#fff',
    },
}));

export default function TransferList({artigosCliente,customerID}) {


    const [checked, setChecked] = React.useState([]);
    const [left, setLeft] = React.useState([]);
    const [right, setRight] = React.useState([]);
    const [qRight, setQRight] = React.useState("")
    const [qLeft, setQLeft] = React.useState("")
    const leftChecked = intersection(checked, left);
    const rightChecked = intersection(checked, right);
    const classes = useStyles();
    const [open, setOpen] = useState(false);

    useEffect(()=>{
        /*  setLeft(artigos)*/
        if(artigosCliente!==undefined) {
            setRight(artigosCliente)
            loadMore(artigosCliente)

        }
    }, [artigosCliente])

    function save(){
        Swal.fire({
            title: 'Deseja guardar as alterações?',
            showDenyButton: true,
            confirmButtonText: `Sim`,
            denyButtonText: `Não`,
        }).then((result) => {
            if (result.isConfirmed) {
                let itemsList=[]
                for(let row of right){
                    itemsList.push(row.id)
                }
                api.post('/cliente/setItems/'+customerID,{
                    items:JSON.stringify(itemsList)
                }).then(async r=>{
                    if(r.data.code===0){
                        await Swal.fire({
                            icon: 'success',
                            title: r.data.message,
                            timer: 1000
                        })
                    }else{
                        await Swal.fire({
                            icon: 'error',
                            title: 'Oops...',
                            text: r.data.message,
                            timer: 2500
                        })
                    }
                })

            }
        })
    }

    const handleToggle = (value) => () => {
        const currentIndex = checked.indexOf(value);
        const newChecked = [...checked];

        if (currentIndex === -1) {
            newChecked.push(value);
        } else {
            newChecked.splice(currentIndex, 1);
        }

        setChecked(newChecked);
    };

    const numberOfChecked = (items) => intersection(checked, items).length;

    const handleToggleAll = (items) => () => {
        if (numberOfChecked(items) === items.length) {
            setChecked(not(checked, items));
        } else {
            setChecked(union(checked, items));
        }
    };

    const handleCheckedRight = () => {
        setRight(right.concat(leftChecked));
        setLeft(not(left, leftChecked));
        setChecked(not(checked, leftChecked));
    };

    const handleCheckedLeft = () => {
        setLeft(left.concat(rightChecked));
        setRight(not(right, rightChecked));
        setChecked(not(checked, rightChecked));
    };

    function loadMore(artigosCliente) {
        if(left.length===0){
            setOpen(true)
            api.get('/item/all').then(res => {
                setListItems(res.data.data, artigosCliente)
                setOpen(false)
            })
        }

    }

    function setListItems(items=[],artigosCliente){
        setLeft(items.filter((row)=>{
            let verify=false
            artigosCliente.map(tmpRow=>{
                if(tmpRow.id===row.id){
                    verify=true
                }
            })
            if(verify===false){
                return row
            }
        }))
    }


    const customListInfinity = (title, items, q, setQ) => (
        <Card style={{width:'20vw'}}>
            <CardHeader
                sx={{ px: 2, py: 1 }}
                avatar={
                    <Checkbox
                        onClick={handleToggleAll(items)}
                        checked={numberOfChecked(items) === items.length && items.length !== 0}
                        indeterminate={
                            numberOfChecked(items) !== items.length && numberOfChecked(items) !== 0
                        }
                        disabled={items.length === 0}
                        inputProps={{
                            'aria-label': 'Todos os artigos selecionados',
                        }}
                    />
                }
                title={title}
                subheader={`${numberOfChecked(items)}/${items.length} selecionados`}
            />
            <Divider />
            <List
                sx={{
                    width: 200,
                    height: 210,
                    bgcolor: 'background.paper',
                    overflow: 'auto',
                }}
                style={{height:'60vh', overflowX:'auto'}}
                dense
                component="div"
                role="list"
            >
                <ListSubheader style={{backgroundColor:"#fff"}}>
                    <ListItem
                    >
                        <TextField value={q} onChange={e=>setQ(e.target.value)} label={"Pesquisar"}  fullWidth={true}/>
                    </ListItem>
                </ListSubheader>
                <FlatList
                    paginationLoadingIndicator={<div style={{width:'100%', textAlign:'center'}}><CircularProgress /></div>}
                    loadingIndicatorPosition='center'
                    /*loadMoreItems={loadMore}
                    hasMoreItems={hasMore}*/
                    searchCaseInsensitive
                    minCharactersCount={2}
                    onEveryWord={true}
                    renderOnScroll
                    search={{
                        term: q,
                        by: "Description",
                    }}
                    renderWhenEmpty={() =>
                        <ListItem
                        >
                            <ListItemText primary={"Não foi encontrado artigos"}/>
                        </ListItem>}
                    list={items}
                    renderItem={value=>{
                        const labelId = `transfer-list-all-item-${value}-label`;
                        return (
                            <ListItem
                                key={value.id}
                                role="listitem"
                                button
                                onClick={handleToggle(value)}
                            >
                                <ListItemIcon>
                                    <Checkbox
                                        checked={checked.indexOf(value) !== -1}
                                        tabIndex={-1}
                                        disableRipple
                                        inputProps={{
                                            'aria-labelledby': labelId,
                                        }}
                                    />
                                </ListItemIcon>
                                <ListItemText id={labelId} primary={`${value.Description}`} />
                            </ListItem>
                        );
                    }}
                />
                <ListItem />
            </List>
        </Card>
    );


    const customList = (title, items) => (
        <Card>
            <CardHeader
                sx={{ px: 2, py: 1 }}
                avatar={
                    <>
                        <Checkbox
                            onClick={handleToggleAll(items)}
                            checked={numberOfChecked(items) === items.length && items.length !== 0}
                            indeterminate={
                                numberOfChecked(items) !== items.length && numberOfChecked(items) !== 0
                            }
                            disabled={items.length === 0}
                            inputProps={{
                                'aria-label': 'Todos os artigos selecionados',
                            }}
                        />
                    </>
                }
                title={title}
                subheader={`${numberOfChecked(items)}/${items.length} selecionados`}
            />
            <Divider />
            <List
                sx={{
                    width: 200,
                    height: 210,
                    bgcolor: 'background.paper',
                    overflow: 'auto',
                }}
                style={{height:'60vh', overflowX:'auto'}}

                dense
                component="div"
                role="list"
            >
                {items.map((value) => {
                    const labelId = `transfer-list-all-item-${value}-label`;

                    return (
                        <ListItem
                            key={value.id}
                            role="listitem"
                            button
                            onClick={handleToggle(value)}
                        >
                            <ListItemIcon>
                                <Checkbox
                                    checked={checked.indexOf(value) !== -1}
                                    tabIndex={-1}
                                    disableRipple
                                    inputProps={{
                                        'aria-labelledby': labelId,
                                    }}
                                />
                            </ListItemIcon>
                            <ListItemText id={labelId} primary={value.Description} />
                        </ListItem>
                    );
                })}
                <ListItem />
            </List>
        </Card>
    );

    return (
        <>
            <Grid container spacing={2} justifyContent="center" alignItems="center">
                <Grid item xs={5}>{customListInfinity('Artigos Disponíveis', left,qLeft, setQLeft)}</Grid>
                <Grid item  xs={2}>
                    <Grid container direction="column" alignItems="center">
                        <Button
                            sx={{ my: 0.5 }}
                            variant="contained"
                            size="small"
                            color={"primary"}
                            onClick={handleCheckedRight}
                            disabled={leftChecked.length === 0}
                        >
                            &gt;
                        </Button>
                        <Button
                            sx={{ my: 0.5 }}
                            variant="outlined"
                            size="small"
                            color={"primary"}
                            onClick={handleCheckedLeft}
                            disabled={rightChecked.length === 0}
                        >
                            &lt;
                        </Button>
                    </Grid>
                </Grid>
                <Grid item  xs={5}>{customListInfinity('Artigos Na lista do Cliente', right, qRight, setQRight)}</Grid>
            </Grid>
            <Button onClick={()=>save()} style={{marginTop:'10px'}} variant={"contained"} color={"Primary"}>Guardar Alterações</Button>

            <Backdrop className={classes.backdrop} open={open} onClick={()=>{}}>
                <CircularProgress color="primary" />
                <Typography>A carregar informação</Typography>
            </Backdrop>
        </>
    );
}