import React, {useEffect, useState} from 'react';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import Typography from '@material-ui/core/Typography';
import {makeStyles, useTheme} from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import api from "../../services/api";

import {Link} from "react-router-dom"
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import TableContainer from "@material-ui/core/TableContainer";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TableBody from "@material-ui/core/TableBody";
import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    List,
    ListItem,
    ListItemIcon, ListItemText,
    ListSubheader,
    Paper
} from "@material-ui/core";
import CardHeader from "@material-ui/core/CardHeader";
import Grid from "@material-ui/core/Grid";
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Divider from "@material-ui/core/Divider";

const useStyles = makeStyles((theme) => ({
    root: {
        minWidth: '75hw',
    },
    paper: {
        marginTop: theme.spacing(8),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    avatar: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.secondary.main,
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(3),
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    },
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: '#fff',
    },
}));

export default function FornecedorFicha(props) {
    const classes = useStyles();
    const [data, setData] = useState({encomendas:[], person:[], registos:[]});
    const [compras, setCompras] = useState([]);
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
    const [listaRegistos, setListaRegistos] = React.useState(false);
    const [contactos, setContactos] = React.useState(false);

    useEffect(() => {
        api.get('/provider/' + props.match.params.id).then(res => {
            if(res.data.data===null){
                window.history.back();
            }
            setData(res.data.data)
        })
    }, [props.match.params.id])

    return (
        <Container component="main" maxWidth="xs">
            <CssBaseline/>
            <div className={classes.paper}>
                <Card className={classes.root}>
                    <CardHeader
                        title={"Fornecedor: " + data.OrganizationName}
                        titleTypographyProps={{align: 'center'}}
                        subheaderTypographyProps={{align: 'center'}}
                        subheader={"Codigo Fornecedor: " + data.SupplierID}
                    />
                    <CardContent>
                        <Grid container spacing={2}>
                            <Grid item xs={12} sm={12}>
                                <Typography component="h4" variant="h5">
                                    <center>Informações:</center>
                                </Typography>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <Typography component="span" variant="subtitle1">
                                    <b>Nome</b> <br/>{data.OrganizationName}
                                </Typography>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <Typography component="span" variant="subtitle1">
                                    <b>NIF</b> <br/>{data.KeyFederalTaxID}
                                </Typography>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <Typography component="span" variant="subtitle1">
                                    <b>Email</b> <br/>{data.EmailAddress}
                                </Typography>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <Typography component="span" variant="subtitle1">
                                    <b>Telefone</b> <br/>{data.Telephone1}
                                </Typography>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <Typography component="span" variant="subtitle1">
                                    <b>Telemóvel</b> <br/>{data.MobileTelephone1}
                                </Typography>
                            </Grid>
                            <Grid item xs={12} sm={12}>
                                <Typography component="h4" variant="h6">
                                    <center>Ações:</center>
                                </Typography>
                            </Grid>
                            <Grid item xs={12} sm={12}>
                                <Button variant="outlined" fullWidth={true} onClick={() => setListaRegistos(true)} type="button"
                                        color="primary">Ver registos de armazém</Button>
                            </Grid>
                        </Grid>
                    </CardContent>
                </Card>
            </div>
            <Dialog
                fullScreen={fullScreen}
                open={listaRegistos}
                onClose={() => setListaRegistos(false)}
                aria-labelledby="responsive-dialog-title"
            >
                <DialogTitle id="responsive-dialog-title">{"Lista de registos"}</DialogTitle>
                <DialogContent>
                    <TableContainer component={Paper}>
                        <Table className={classes.table} aria-label="simple table">
                            <TableHead>
                                <TableRow>
                                    <TableCell>ID</TableCell>
                                    <TableCell>Responsável</TableCell>
                                    <TableCell>Ver</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {data.registos.map((registo, idx) => {
                                    return (
                                        <TableRow key={idx}>
                                            <TableCell>{registo.id}</TableCell>
                                            <TableCell>{registo.user.username}</TableCell>
                                            <TableCell><Link to={'/armazem/ver/' + registo.id}><Button color="primary" variant="contained">Ver</Button></Link></TableCell>
                                        </TableRow>
                                    )
                                })}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setListaRegistos(false)} color="primary">
                        Fechar
                    </Button>
                </DialogActions>
            </Dialog>
        </Container>
    );
}
