import React, {useEffect, useState} from 'react';
import CssBaseline from "@material-ui/core/CssBaseline";
import Container from "@material-ui/core/Container";
import {makeStyles} from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import Paper from "@material-ui/core/Paper";
import CardContent from "@material-ui/core/CardContent";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import api, {auth, url} from '../../services/api'
import Select from "react-select";
import Typography from "@material-ui/core/Typography";
import TableContainer from "@material-ui/core/TableContainer";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogActions from "@material-ui/core/DialogActions";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Switch from "@material-ui/core/Switch";
import DeleteIcon from '@material-ui/icons/Delete';
import TableBody from "@material-ui/core/TableBody";
import {useHistory} from "react-router-dom"
import Swal from "sweetalert2";
import ViewWeekSharpIcon from "@material-ui/icons/ViewWeekSharp";
import {useRecoilState} from "recoil";
import {EstadoReader, InfoReader} from "../../atoms";
import {detectWebcam} from "../../services/functions";
import CodeReader from "../../components/CodeReader";
import {Alert} from "@material-ui/lab";
import TextareaAutosize from "@material-ui/core/TextareaAutosize";
import NoteIcon from '@material-ui/icons/Note';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import {
    Badge,
    CircularProgress,
    IconButton,
    ListItem,
    ListItemText,
    ListSubheader, MenuItem,
    Select as MuiSelect,
    useTheme
} from "@material-ui/core";
import {History} from "@material-ui/icons";
import List from "@material-ui/core/List";
import FlatList from "flatlist-react";
import moment from "moment";
import CloseIcon from '@material-ui/icons/Close';
import NewCustomerMask from "../../components/NewCustomerMask";
import PersonAddIcon from "@material-ui/icons/PersonAdd";


const useStyles = makeStyles((theme) => ({
    root:{
      minWidth: "75vw",
    },
    paper: {
        marginTop: theme.spacing(8),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    avatar: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.secondary.main,
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(3),
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    },
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: '#fff',
    },
    table: {
        //minWidth: "35vw",
    },
    table2: {
        maxWidth: "35vw",
    },
    select: {
        minWidth: "35vw",

    }
}));

export default function DocumentosCriarTeclado(props){
    const classes = useStyles();
    const [clientes, setClientes] = useState([]);
    const [artigos, setArtigos] = useState([]);
    const [tara, setTara] = useState({value:1, label:"Vendida"});
    const [contaCorrente, setContaCorrente] = useState(0);
    const [linhasArtigo, setLinhasArtigo] = useState([]);
    const [artigo, setArtigo] = useState([]);
    const [cliente, setCliente] = useState({});
    const [totalDoc, setTotalDoc] = useState(0.00);
    const [pagamento, setPagamento] = useState(false);
    const [clienteDefenido, defineCliente] = useState(false);
    const [edit, setEdit] = useState(false);
    const history = useHistory();
    const [infoArtigo, setInfoArtigo] = useState({});
    const [webCam, setWebCam]=useState(false)
    const [artigoReader, setArtigoReader] = useRecoilState(InfoReader);
    const [openCodeBarReader,setOpenCodeBarReader]=useRecoilState(EstadoReader)
    const [find, setFind]=useState(null);
    const [observacoes, setObs]=useState(false);
    const [obsData,setObsData]=useState("");
    const [showLastOrders,setShowLastOrder]=useState(false);
    const [lastOrders,setLastOrders]=useState([]);
    const [newCustomer,setNewCustomer]=useState(false);
    const [local, setLocal] = useState("presencial");

    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
    var send = false;

    useEffect(()=>{
        detectWebcam((hasWebCam)=>setWebCam(hasWebCam))

        api.get('/cliente/list').then(res=>{
            if(res.data.code===0){
                setClientes(res.data.data)
            }
        })
        api.get('/item/list').then(res=>{
            if(res.data.code===0){
                setArtigos(res.data.data)
            }
        })
    },[props]);

    useEffect(()=>{
        if(artigoReader!==null){
            let found=false
            artigos.map(row => {
                if (row.BarCode === artigoReader) {
                    defineArtigo(row)
                    setFind(null)
                    found=true
                    setArtigoReader(null)
                    return 0
                }
            });
            if(!found){
                setFind("Nenhum artigo encontrado")
            }
        }
    }, [artigoReader])

    useEffect(()=>{
        api.get('/documentos/historico/'+cliente.id).then(res=>{
            setLastOrders(res.data)
        })
    }, [cliente])

    function loadMore(page){
        api.get('/documentos/historico/'+cliente.id+'?page='+(parseInt(lastOrders.current_page)+1)).then(res=>{
            console.log(res.data)
            res.data.data = lastOrders.data.concat(res.data.data);
            setLastOrders(res.data)
        })
    }

    function calculatePrice(quantidade, preco, desconto=0){
        const precoFinal = parseFloat(quantidade)*parseFloat(preco);
        return precoFinal - (precoFinal*(parseFloat(desconto)/100))
    }
/*    useEffect(()=>{
        if(cliente.cliente.id!==0){
            console.log(infoArtigo)
            const temp = infoArtigo;
            temp.preco=infoArtigo.precos[cliente.cliente.linhaPreco-1]
            setInfoArtigo(temp);
        }
    }, [cliente, infoArtigo])*/

    function defenirLinhas() {
        const temp = linhasArtigo;
        let total=0.00;
        infoArtigo.tipo=tara.value;
        let tempArtigo = infoArtigo;
        tempArtigo.quantidade = Number(infoArtigo.quantidade).toFixed(parseInt(infoArtigo.unidade.MaximumDecimals));
        //temp.push(tempArtigo);


        for (let row of temp) {
            if(!isNaN(parseFloat(row.preco))) {
                total += calculatePrice(row.quantidade, row.preco, row.desconto)
            }else{
                total+=0
            }
        }

        setTotalDoc(total);
        setInfoArtigo({quantidade:"", obs:""})
        setArtigo({})
        setLinhasArtigo([...temp,{
            BarCode:tempArtigo.BarCode,
            Description:tempArtigo.Description,
            Discontinued:tempArtigo.Discontinued,
            DtAlt:tempArtigo.DtAlt,
            FamilyID:tempArtigo.FamilyID,
            HrAlt:tempArtigo.HrAlt,
            ItemID:tempArtigo.ItemID,
            ShortDescription:tempArtigo.ShortDescription,
            UnitID:tempArtigo.UnitID,
            created_at:tempArtigo.created_at,
            defaultPrice:tempArtigo.defaultPrice,
            family:tempArtigo.family,
            id:tempArtigo.id,
            preco:tempArtigo.preco,
            prices:tempArtigo.prices,
            quantidade:tempArtigo.quantidade,
            tipo:tempArtigo.tipo,
            unidade:tempArtigo.unidade,
            updated_at:tempArtigo.updated_at,
            desconto:tempArtigo.desconto,
        }])

    }

    function defineContaCorrente(data) {
        setContaCorrente(data.cc)
        setCliente(data)
        defineCliente(true)
        if(infoArtigo!==undefined) {
            const tmp = infoArtigo;
            tmp.preco = getCurrentPrice(data, infoArtigo)
            setInfoArtigo(tmp)

        }
    }

    function defineArtigo(data) {
        if(cliente.OrganizationName===""){
            data.preco=0;
            if(data.defaultPrice===null){
                data.preco="";
                data.desconto=0
            }else{
                //data.preco=data.defaultPrice.UnitPrice.toString();
            }
        }else{
            //data.preco=getCurrentPrice(cliente, data)
        }

        const calc = ()=>{
            if(cliente.OrganizationName===""){
                // return 0;

                if(data.defaultPrice===null){
                    return "";
                }else{
                    return data.defaultPrice.UnitPrice.toString();
                }
            }else{
                return getCurrentPrice(cliente, data)
            }
        }

        setInfoArtigo({...data, preco:calc()})
        //setInfoArtigo(data);
        setTara({value:1, label:"Vendida"});
    }

    function getCurrentPrice(customer, item){
        if(item.prices !== undefined){
            if(typeof item!=="undefined"){
                if(item.Description!==""){
                    if(customer.OrganizationName!=="" && customer.OrganizationName!==undefined){
                        let price = item.prices.filter(pr=>customer.PriceLineID==pr.price_line.id)
                        if(item.defaultPrice!==null){
                            return price.length!==0?price[0].UnitPrice:item.defaultPrice.UnitPrice;
                        }else{
                            return  0
                        }
                    }else{
                        if(item.defaultPrice!==null){
                            return item.defaultPrice.UnitPrice;
                        }else{
                            return  0
                        }
                    }
                }else{
                    return 0;
                }
            }
        }else{
            return 0
        }

    }

    function definePreco(e) {
        if(!isNaN(Number(e.target.value))){
            if(Number(e.target.value)>=0) {
                setInfoArtigo({
                    ...infoArtigo,
                        preco:e.target.value
                })
            }
        }
    }

    function defineDesconto(e) {
        if(!isNaN(Number(e.target.value))){
            if(Number(e.target.value)>=0 && Number(e.target.value)<=100) {
                setInfoArtigo({
                    ...infoArtigo,
                    desconto:e.target.value
                })
            }
        }
    }

    function defineQuantidade(e) {
        if(!isNaN(Number(e.target.value))){
            if(Number(e.target.value)>=0) {
                setInfoArtigo({
                    ...infoArtigo,
                    quantidade:e.target.value
                })
            }
        }
    }

    function alteraTara(data) {
        if(data.value!==1){
            setInfoArtigo({
                preco: 0.00,
                id: infoArtigo.id,
                itemCode: infoArtigo.itemCode,
                descricao: infoArtigo.descricao,
                unidade: infoArtigo.unidade,
                estado: infoArtigo.estado,
                idFamilia: infoArtigo.idFamilia,
                familia:infoArtigo.familia,
                precos: infoArtigo.precos,
                quantidade:infoArtigo.quantidade,
                tara:infoArtigo.tara,
                Description:infoArtigo.Description,
                MaximumDecimals: infoArtigo.MaximumDecimals,
                UnitOfMeasureID: infoArtigo.UnitOfMeasureID,
            })
        }
        setTara(data)
    }

    async function guardarDoc() {
        if(!send) {
            send=true;
            const data = {};
            data.cliente = cliente.id;
            data.linhas = linhasArtigo;
            data.Obs = obsData
            data.local=local

            const res = await api.post('/documentos', data);
            setPagamento(false)
            if (res.data.code === 0) {
                await Swal.fire({
                    title: "Informação",
                    text: res.data.message,
                    icon: "success",
                    showDenyButton: true,
                    confirmButtonText: "Obter documento",
                    denyButtonText: "Fechar",
                }).then(r => {
                    if (r.isConfirmed) {
                        window.open(url + "pdf/docVenda/" + res.data.data + "/" + auth(), '_blank')
                        history.push("/documentos/ver/" + res.data.data)

                    } else {
                        history.push("/documentos/ver/" + res.data.data)
                    }
                })
            } else {
                await Swal.fire({
                    title: "Informação",
                    text: res.data.message,
                    icon: "error",
                    timer: 3000
                })
            }
        }
    }

    function editarLinha(idx) {
        // eslint-disable-next-line array-callback-return
        console.log(idx)
        const newTmp=linhasArtigo.filter((value, index) => {
            if(index===idx){
                setInfoArtigo(value);
/*                const listaTara=[{value:1, label:"Vendida"},{value:2, label:"Emprestada"},{value:3, label:"Oferta"} ];
                if(value.tipo!==undefined){
                    setTara(listaTara[value.tipo-1])
                }else{
                    setTara(listaTara[0])
                }*/
            }else{
                return value;
            }
        })
        console.log(newTmp);

        let total=0.00;
        for(let row of newTmp){
            total+=calculatePrice(row.quantidade, row.preco, row.desconto)
        }
        setTotalDoc(total)
        setLinhasArtigo(newTmp);
    }

    function removerLinha(idx) {
        // eslint-disable-next-line array-callback-return
        const newTmp=linhasArtigo.filter((value, index) => {
            if (index !== idx) {
                return value;
            }
        })
        let total=0.00;
        for(let row of newTmp){
            total+=calculatePrice(row.quantidade, row.preco, row.desconto)
        }
        setTotalDoc(total)
        setLinhasArtigo(newTmp);
    }

    function removeZerosArtigo() {
        if(infoArtigo.preco===0 || infoArtigo.preco==="0.00"){
            setInfoArtigo(prevState => {prevState.preco=""; return prevState})
        }
    }

    return(
        <Container component="main" maxWidth="xs">
            <CssBaseline />
            <CodeReader/>
            <div className={classes.paper}>
                <Typography component="h1" variant="h5">
                    Criar encomenda <br/>
                </Typography>
                <Card className={classes.root} variant="outlined">
                    <CardContent>
                        <Grid container spacing={2}>
                            <Grid item xs={clienteDefenido?8:12} sm={clienteDefenido?4:6}>
                                <Select
                                    onChange={defineContaCorrente}
                                    name="clientes"
                                    placeholder="Clientes"
                                    options={clientes}
                                    option={cliente}
                                    getOptionLabel={(option)=> option.OrganizationName}
                                    getOptionValue ={(option)=>option.id}
                                />
                            </Grid>
                            {clienteDefenido ?
                                <Grid item xs={4} sm={2}>
                                    <Button onClick={() => setShowLastOrder(true)}
                                            type="button" color="primary" variant="outlined"><History/>
                                    </Button>
                                </Grid>: null}
                            <Grid item xs={12} sm={6}>
                                <Grid container spacing={2}>
                                    <Grid item xs={12} sm={6}>
                                        Linha de precos em uso: {cliente.price_line_details!=undefined?cliente.price_line_details.Description:null}
                                    </Grid>
                                        {/*<Grid item xs={12} sm={6}>
                                        Conta corrente: <span style={contaCorrente>0?({color: "red"}):null}>{contaCorrente.toFixed(2)}€</span>
                                    </Grid>*/}
                                </Grid>
                            </Grid>
                            <Grid item xs={webCam?8:12} sm={webCam?4:6}>
                                <Select
                                    onChange={defineArtigo}
                                    name="artigos"
                                    option={artigo}
                                    placeholder="Artigos"
                                    options={artigos}
                                    getOptionLabel={(option)=> {return option.ItemID + "  /  " + option.Description}}
                                    getOptionValue ={(option)=>option.id}
                                />
                            </Grid>
                            {webCam?(
                                <Grid item xs={4} sm={2}>
                                    <center>
                                        <Button style={{minWidth: '90%'}} type="button" color="primary" variant="contained" onClick={()=>setOpenCodeBarReader(true)}><ViewWeekSharpIcon/> </Button>
                                    </center>
                                </Grid>):null
                            }
                            {find!==null?(
                                <Grid item xs={12} sm={12}>
                                    <Alert severity="error">{find}</Alert>
                                </Grid>
                            ):null}
                            <Grid item xs={12} sm={6}>
                                <TableContainer component={Paper} style={{marginBottom: 10}}>
                                    <Table className={classes.table} aria-label="Descricao do artigo">
                                        <TableHead>
                                            <TableRow>
                                                <TableCell>Codigo artigo</TableCell>
                                                <TableCell>Descrição</TableCell>
                                                <TableCell>Quantidade</TableCell>
                                                <TableCell>Preço</TableCell>
                                                <TableCell>Desconto</TableCell>
                                                <TableCell>Unidade</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            <TableRow >
                                                <TableCell>{infoArtigo.ItemID}</TableCell>
                                                <TableCell>{infoArtigo.Description}</TableCell>
                                                <TableCell>
                                                    <TextField name="quantidade" onChange={defineQuantidade} type="number"  value={infoArtigo.quantidade} onKeyPress={(e) => {
                                                        if(e.key==='Enter'){
                                                            if(!(infoArtigo.ItemID === undefined || parseFloat(infoArtigo.quantidade) <= 0 || infoArtigo.quantidade===undefined)){
                                                                defenirLinhas()
                                                            }
                                                        }
                                                    }}
                                                    /></TableCell>
                                                <TableCell><TextField name="preco" disabled={tara.value!==1} onChange={definePreco} type="number" value={infoArtigo.preco || ""}/></TableCell>
                                                <TableCell><TextField name="desconto" onChange={defineDesconto} type="number" value={infoArtigo.desconto}/></TableCell>
                                                <TableCell>{infoArtigo.unidade!==undefined?infoArtigo.unidade.UnitOfMeasureID:""}</TableCell>

                                            </TableRow>
                                        </TableBody>
                                    </Table>
                                </TableContainer>

                                <TextField
                                    label={"Observações..."}
                                    style={{minHeight: '50px',width:'100%'}}
                                    multiline
                                    variant={"outlined"}
                                    maxRows={4}
                                    value={infoArtigo.obs}
                                    onChange={(e)=>{
                                        setInfoArtigo({
                                            ...infoArtigo,
                                            obs:e.target.value
                                        })
                                }}
                                />
                                {infoArtigo.tara==1?(
                                    <Select style={{marginTop: 20, marginLeft: 20}} value={tara} option={{value:1, label:"Vendida"}} onChange={alteraTara} options={[{value:1, label:"Vendida"},{value:2, label:"Emprestada"},{value:3, label:"Oferta"} ]} variant="outlined" name="tara" placeholder="Tara"/>):null}
                                    <Button onClick={defenirLinhas} disabled={infoArtigo.ItemID === undefined || parseFloat(infoArtigo.quantidade) <= 0 || infoArtigo.quantidade===undefined} fullWidth style={{marginTop: 20}} variant="contained" color="primary">Adicionar artigo</Button>
                            </Grid>
                            <Grid item xs={12}>
                                <TableContainer component={Paper}>
                                    <Table className={classes.table} aria-label="Artigos do documento">
                                        <TableHead>
                                        <TableRow>
                                            <TableCell/>
                                            <TableCell>Codigo artigo</TableCell>
                                            <TableCell>Descrição</TableCell>
                                            {edit===false?(<TableCell>Quantidade</TableCell>):(<TableCell colSpan={3}><center>Ações</center></TableCell>)}
                                            {edit===false?(<TableCell>Preço</TableCell>):(null)}
                                            {edit===false?<TableCell >Desconto</TableCell>:(null)}
                                        </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {linhasArtigo.map((row, index) =>
                                                <>
                                                    <TableRow key={index}>
                                                        <TableCell>{index+1}</TableCell>
                                                        <TableCell>{row.ItemID}</TableCell>
                                                        <TableCell>{row.Description}</TableCell>
                                                        {edit===false?<TableCell>{row.quantidade}</TableCell>:null}
                                                        {edit===false?<TableCell>{isNaN(parseFloat(row.preco))?"0.00":parseFloat(row.preco).toFixed(2)}€/{row.unidade.UnitOfMeasureID}</TableCell>:null}
                                                        {edit===false?(<TableCell>{row.desconto}%</TableCell>):(<TableCell><Button onClick={()=>editarLinha(index)} variant="outlined" color="primary">Editar linha</Button></TableCell>)}
                                                        {edit===false?null:(<TableCell><Button variant="contained" color="secondary" className={classes.button} startIcon={<DeleteIcon />} onClick={() =>removerLinha(index)}>Remover linha</Button></TableCell>)}
                                                    </TableRow>
                                                    {
                                                        row.obs!==""?(
                                                            <TableRow>
                                                                <TableCell/>
                                                                <TableCell>{row.obs}</TableCell>
                                                            </TableRow>
                                                        ):null
                                                    }
                                                </>
                                            )}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                                <Button onClick={()=>setPagamento(true)} disabled={linhasArtigo.length<= 0 || !clienteDefenido===true} fullWidth style={{marginTop: 20}} variant="contained" color="primary">Guardar encomenda</Button>
                                <FormControlLabel
                                    control={
                                        <Switch
                                            checked={edit}
                                            onChange={()=>setEdit(!edit)}
                                            name="checkedB"
                                            color="primary"
                                        />
                                    }
                                    label="Editar documento"
                                />
                                <Button onClick={()=>setObs(true)} type="button" color="primary" variant="outlined"><NoteIcon/> </Button>
                                <Button onClick={()=>setNewCustomer(true)} type="button" color="primary" variant="outlined"><PersonAddIcon/> </Button>
                            </Grid>
                        </Grid>
                        </CardContent>
                </Card>
            </div>
            <Dialog
                open={pagamento}
                onClose={()=>setPagamento(false)}
                aria-labelledby="responsive-dialog-title"
            >
                <DialogTitle id="responsive-dialog-title">{"Guardar documento?"}</DialogTitle>
                <DialogContent >
                    <MuiSelect fullWidth={true} onChange={e=>setLocal(e.target.value)} value={local} variant={"outlined"} name="localEncomenda" label="Local" required>
                        <MenuItem value="telefone">Telefone</MenuItem>
                        <MenuItem selected={true} value="presencial">Presencial</MenuItem>
                        <MenuItem value="email">Email</MenuItem>
                    </MuiSelect>
                </DialogContent>
                <DialogActions>
                    <Button onClick={()=>setPagamento(false)} color="primary">
                        Cancelar
                    </Button>
                    <Button onClick={()=>guardarDoc()} /*disabled={totalDoc<=0}*/ color="primary" autoFocus>
                        Guardar
                    </Button>
                </DialogActions>
            </Dialog>
            <Dialog
                open={observacoes}
                onClose={()=>setObs(false)}
                aria-labelledby="responsive-dialog-title"
            >
                <DialogTitle id="responsive-dialog-title">{"Observações"}</DialogTitle>
                <DialogContent>
                    <Grid container spacing={2} style={{height:'400px',minWidth:'500px'}}>
                        <Grid item xs={12} sm={12}>
                            <TextareaAutosize style={{minHeight: '100px',width:'100%'}} aria-label="minimum height" rowsMin={3} placeholder="Observações..." name="observacoes" value={obsData} onChange={(e)=>setObsData(e.target.value)} />
                        </Grid>
                        <Grid item xs={12}>
                            <Button variant={"contained"} color={"primary"} onClick={()=>setObs(false)}>Fechar</Button>
                        </Grid>
                    </Grid>
                </DialogContent>
            </Dialog>


            <Dialog
                open={newCustomer}
                onClose={()=>setNewCustomer(false)}

                fullScreen={fullScreen}
            >
                <DialogTitle >
                    {"Cliente novo"}
                    <IconButton style={{float: 'right'}} aria-label="close" onClick={()=>newCustomer(false)}>
                        <CloseIcon />
                    </IconButton>
                </DialogTitle>
                <DialogContent style={{minWidth: '25vw'}}>
                    <NewCustomerMask newCustomer={setNewCustomer} setFromMask={(value)=>true} setTmp={null} setValue={setObsData}/>
                </DialogContent>
            </Dialog>

            <Dialog
                open={showLastOrders}
                onClose={()=>setShowLastOrder(false)}
                aria-labelledby="responsive-dialog-title"
                fullScreen={fullScreen}
            >
                <DialogTitle id="responsive-dialog-title">
                    {"Ultimas encomendas"}
                    <IconButton style={{float: 'right'}} aria-label="close" onClick={()=>setShowLastOrder(false)}>
                        <CloseIcon />
                    </IconButton>
                </DialogTitle>
                <DialogContent>
                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={12}>
                            <List component="nav">
                                <FlatList
                                    //renderOnScroll
                                    paginationLoadingIndicator={<div style={{width:'100%', textAlign:'center'}}><CircularProgress /></div>}
                                    loadingIndicatorPosition='center'

                                    hasMoreItems={lastOrders.next_page_url!==null}
                                    renderWhenEmpty={() =>
                                        <ListItem
                                        >
                                            <ListItemText primary={"Este cliente não efetuou encomendas previamente"}/>
                                        </ListItem>}
                                    loadMoreItems={loadMore}
                                    list={lastOrders.data}
                                    renderItem={item=>(
                                        <ListItem>
                                            <List
                                                style={{width: '100%'}}
                                                subheader={
                                                    <ListSubheader style={{backgroundColor: "#fff"}} component="div" id="nested-list-subheader">
                                                        Encomenda dia {moment(item.created_at).format('DD/MM/YYYY')}
                                                    </ListSubheader>
                                                }
                                            >
                                                {item.detalhes.map(row => (
                                                    <ListItem
                                                        button
                                                        onClick={()=> {
                                                            setShowLastOrder(false)
                                                            defineArtigo(row.item_info)
                                                        }}
                                                    >
                                                        <ListItemText primary={row.item_info.ShortDescription}/>
                                                        <Badge badgeContent={row.quantidade} color="primary">
                                                        </Badge>
                                                    </ListItem>
                                                ))}
                                            </List>
                                        </ListItem>
                                    )}
                                />
                            </List>
                        </Grid>
                        <Grid item xs={12}>
                            <Button variant={"contained"} color={"primary"} onClick={()=>setShowLastOrder(false)}>Fechar</Button>
                        </Grid>
                    </Grid>
                </DialogContent>
            </Dialog>
        </Container>
    )
}
