import React, {useState} from 'react';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';

import { useHistory } from "react-router-dom";

import {TextField} from 'unform-material-ui';
import {Form} from '@unform/web';
import bk from "../images/bk.png"
import Link from '@material-ui/core/Link';
import Box from '@material-ui/core/Box';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import CircularProgress from "@material-ui/core/CircularProgress";
import Backdrop from "@material-ui/core/Backdrop";
import Alert from "@material-ui/lab/Alert";
import Collapse from "@material-ui/core/Collapse";
import api from "../services/api";
import {login} from "../services/auth";

function Copyright() {
    return (
        <Typography variant="body2" color="textSecondary" align="center">
            {'Copyright © '}
            <Link color="inherit" href="https://capensis.pt/">
                Capensis LDA
            </Link>{' '}
            {new Date().getFullYear()}
            {'.'}
        </Typography>
    );
}

const useStyles = makeStyles((theme) => ({
    paper: {
        marginTop: theme.spacing(8),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    avatar: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.primary.main,
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(1),
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    },
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: '#fff',
    },
}));


export default function Login() {
    const classes = useStyles();
    const [open, setOpen] = useState(false);
    const [alert, setAlert] = useState({open:false, message:"", type:"info"});

    const history = useHistory();

    async function session(data) {
        if(data.username.match(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/)){
            setOpen(true);
            if(data.username!=="" && data.password!==""){
                const res = await api.post('/login', {
                    username:data.username,
                    password:data.password,
                })
                if(res.data.code===0){
                    setAlert({
                        open: true,
                        message: res.data.message,
                        type: 'success'
                    })
                    login(data.username, res.data.data, res.data.nome, res.data.level);

                    history.push("/dashboard")
                }else{
                    setAlert({
                        open: true,
                        message: res.data.message,
                        type: 'error'
                    })
                }
            }else{
                setAlert({
                    open: true,
                    message: "Falta preencher campos",
                    type: 'error'
                })
            }
            setOpen(false);
        }else{
            setAlert({
                open: true,
                message: "O campo email não foi preenchido corretamente",
                type: 'error'
            })
        }

    }

    return (
        <Container component="main" maxWidth="xs">
            <div className={classes.paper}>
                <img src={bk}/>
                <Form onSubmit={data=>session(data)} className={classes.form} noValidate>
                    <TextField
                        variant="outlined"
                        margin="normal"
                        required
                        fullWidth
                        inputProps={{type: "email"}}
                        id="username"
                        label="Email"
                        name="username"
                        autoComplete="username"
                    />
                    <TextField
                        variant="outlined"
                        margin="normal"
                        required
                        fullWidth
                        name="password"
                        label="Password"
                        type="password"
                        id="password"
                        autoComplete="current-password"
                    />

                    <Button
                        type="submit"
                        fullWidth
                        variant="contained"
                        color="primary"
                        className={classes.submit}
                    >
                        Iniciar sessão
                    </Button>
                </Form>
                <Collapse in={alert.open} >
                    <Alert severity={alert.type}>
                        {alert.message}
                    </Alert>
                </Collapse>
            </div>
            <Box mt={8}>
                <Copyright />
            </Box>
            <Backdrop className={classes.backdrop} open={open} onClick={()=>{}}>
                <CircularProgress color="inherit" />
            </Backdrop>
        </Container>
    );
}
