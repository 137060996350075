import React, {useState, useEffect} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import CircularProgress from "@material-ui/core/CircularProgress";
import api from "../../services/api";
import DataTable from "react-data-table-component";
import SortIcon from "@material-ui/icons/ArrowDownward";
import Checkbox from "@material-ui/core/Checkbox";
import Button from "@material-ui/core/Button";
import {Link} from "react-router-dom";
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';
import Snackbar from "@material-ui/core/Snackbar";
import Alert from "@material-ui/lab/Alert";



const useStyles = makeStyles((theme) => ({
    paper: {
        marginTop: theme.spacing(8),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    avatar: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.primary.main,
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(1),
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    },
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: '#fff',
    },
}));




export default function FamiliaListar() {
    const classes = useStyles();
    const theme = useTheme();

    const [familias, setFamilias] = useState([]);
    const [loading, setLoading] = React.useState(false);
    const [openDialog, setOpenDialog] = React.useState(false);
    const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
    const [dados, setDados] = useState({descricao:"", id:""});
    const [message, setMessage] = useState({message:"", type:"", show:false});
    const [totalRows, setTotalRows] = useState(0);
    const [perPage, setPerPage] = useState(10);
    const [currentPage, setCurrentPage] = useState(1);


    function obter(page=currentPage, size=perPage){
        setLoading(true)
        api.get(`/familia?page=${page}&per_page=${size}`).then(res=>{
            if(res.data.code===0){
                setFamilias(res.data.data.list)
                setTotalRows(res.data.data.total)
            }
            setLoading(false)
        })
    }

    useEffect(()=>{
        obter()
    }, [])

    const columns = [
        {
            name: "ID",
            selector: "id",
            sortable: true
        },
        {
            name: "Descricão",
            selector: "Description",
            sortable: true
        },
        {
            name:"",
            cell:row=><Link to={"/familias/" + row.id}><Button variant={"contained"} color={"primary"}>Ficha</Button></Link>
        }
    ];



    const handlePageChange = page => {
        console.log(page)
        obter(page);
        setCurrentPage(page);
    };

    const handlePerRowsChange = async (newPerPage, page) => {
        obter(page, newPerPage);
        setPerPage(newPerPage);
    };


    return (
        <Container component="main" maxWidth="md">
            <div className={classes.paper}>
                <DataTable
                    title="Familias"
                    columns={columns}
                    data={familias}
                    sortIcon={<SortIcon />}
                    onChangeRowsPerPage={handlePerRowsChange}
                    onChangePage={handlePageChange}
                    paginationTotalRows={totalRows}
                    paginationDefaultPage={currentPage}
                    paginationServer
                    pagination
                    //selectableRows
                    selectableRowsComponent={Checkbox}
                    contextMessage={{
                        singular: 'familia',
                        plural: 'familias',
                        message: 'selecionada\\s' }}
                    noDataComponent={"Não foi encontradas familias"}
                    progressPending={loading}
                    progressComponent={<CircularProgress />}
                    paginationComponentOptions={{
                        rowsPerPageText: 'Linhas por pagina:',
                        rangeSeparatorText: 'de',
                        noRowsPerPage: false,
                        selectAllRowsItem: false,
                        selectAllRowsItemText: 'Todas'
                    }}
                />
            </div>

            <Snackbar anchorOrigin={{vertical:'bottom', horizontal:'left'}} open={message.show} autoHideDuration={6000} onClose={()=>setMessage({message:"", type:"success", show:false})}>
                <Alert onClose={()=>setMessage({message:"", type:"success", show:false})} severity={message.type}>
                    {message.message}
                </Alert>
            </Snackbar>
        </Container>
    );
}
