import React, {useEffect, useState} from 'react';
import {makeStyles} from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import CircularProgress from "@material-ui/core/CircularProgress";
import api from "../../services/api";
import DataTable from "react-data-table-component";
import SortIcon from "@material-ui/icons/ArrowDownward";
import Checkbox from "@material-ui/core/Checkbox";
import Button from "@material-ui/core/Button";
import {Link} from "react-router-dom";
import TextField from "@material-ui/core/TextField";
import Switch from "@material-ui/core/Switch";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import moment from "moment";
import 'moment/locale/pt';



const useStyles = makeStyles((theme) => ({
    paper: {
        marginTop: theme.spacing(8),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    avatar: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.primary.main,
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(1),
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    },
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: '#fff',
    },
}));


export default function ClientesListar() {
    const classes = useStyles();

    const [clientes, setClientes] = useState([{}]);
    const [lista, setLista] = useState([]);
    const [loading, setLoading] = React.useState(false);
    const [lastSync, setSync] = useState(moment().format('YYYY/MM/DD HH:mm:ss'));
    const [totalRows, setTotalRows] = useState(0);
    const [perPage, setPerPage] = useState(10);
    const [currentPage, setCurrentPage] = useState(1);
    const [q, setQ] = useState("");
    const [sort, setSort] = useState({column:'CustomerID', order:'asc'});

    useEffect(() => {
        get()
    }, [q, sort])

    function get(page=currentPage, size=perPage, query=q){
        setLoading(true)
        api.get(`/cliente?page=${page}&per_page=${size}&q=${query}&order=${sort.order}&collum=${sort.column}`).then(res => {
            if (res.data.code === 0) {
                setClientes(res.data.data.list)
                setSync(res.data.lastSync)
                setTotalRows(res.data.data.total)

            }
            setLoading(false)

        })
    }

    const handlePageChange = page => {
        console.log(page)
        get(page);
        setCurrentPage(page);
    };

    function handleTextChange(e){
        setQ(e.target.value)
    }

    const handlePerRowsChange = async (newPerPage, page) => {
        get(page, newPerPage);
        setPerPage(newPerPage);
    };

    const handleSort = async (column, order)=>{
        setSort({column:column.selector, order})
    }

    const columns = [
        {
            name: "Codigo",
            selector: "CustomerID",
            sortable: true,
            width:'10%'
        },
        {
            name: "Nome",
            selector: "OrganizationName",
            sortable: true,
            width:'60%'
        },
        {
            name: "",
            cell: row => <Link to={"/registos/" + row.id}><Button variant={"contained"}
                                                                                color={"primary"}>Visita</Button></Link>
        },{
            name: "",
            cell: row => <Link to={"/clientes/" + row.id}><Button variant={"contained"}
                                                                  color={"primary"}>Ficha</Button></Link>
        }
    ];


    return (
        <Container component="main" maxWidth="md">
            <TextField label="Pesquisa" onChange={handleTextChange}/>
            <div className={classes.paper}>
                <DataTable
                    title="Clientes"
                    columns={columns}
                    data={clientes}
                    sortIcon={<SortIcon/>}
                    pagination
                    onSort={handleSort}
                    selectableRowsComponent={Checkbox}
                    contextMessage={{
                        singular: 'artigo',
                        plural: 'artigos',
                        message: 'selecionada\\s'
                    }}
                    onChangeRowsPerPage={handlePerRowsChange}
                    onChangePage={handlePageChange}
                    paginationTotalRows={totalRows}
                    paginationDefaultPage={currentPage}
                    paginationServer
                    noDataComponent={"Não foi encontrados clientes"}
                    progressPending={loading}
                    progressComponent={<CircularProgress/>}
                    paginationComponentOptions={{
                        rowsPerPageText: 'Linhas por pagina:',
                        rangeSeparatorText: 'de',
                        noRowsPerPage: false,
                        selectAllRowsItem: false,
                        selectAllRowsItemText: 'Todas'
                    }}
                />
                <p>Ultima sincronização: {moment(lastSync).locale("pt").fromNow()}</p>
            </div>
        </Container>
    );
}
