import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";
import * as serviceWorkerRegistration from "./serviceWorkerRegistration"


Sentry.init({
    dsn: "https://16195dad4e654cc796862ecf93adb862@o995184.ingest.sentry.io/5984383",
    integrations: [new Integrations.BrowserTracing()],

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 1.0,
});

ReactDOM.render(<App />, document.getElementById('root'));

serviceWorkerRegistration.register({
    onUpdate: registration => {
        alert('Acabou de sair uma nova atualização, por favor pressione o botão abaixo para atualizar');
        if (registration && registration.waiting) {
            registration.waiting.postMessage({ type: 'SKIP_WAITING' });
        }
        window.location.reload();
    }
});
